/* eslint-disable react/prop-types */
// TODO: add item.props to validator, leaving here for demo
import TabControl from '@bit/modus-moodys.mapulseui.tab-control';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddEntitiesToWatchlist from './AddEntitiesToWatchlist';
import CreateNewWatchlist from './CreateNewWatchlist';
import DeleteWatchlist from './DeleteWatchlist';
import EditRemoveWatchlist from './EditRemoveWatchlist';
import EditWatchlistLabel from './EditWatchlistLabel';
import './styles.scss';
import logger from '@bit/modus-moodys.mapulseui.logger';

const panelsWatchlist = {
    CreateOrEdit: ({ addPanel, hideFromIndex }) => {
        const { t } = useTranslation();
        return (
            <div className="watchlist first-panel">
                <h1
                    className="title heading-2"
                    style={{ paddingBottom: '30px' }}
                >
                    {`${t('lbl_manage_item', { item: t('lbl_my_portfolio') })}`}
                </h1>
                <TabControl
                    labels={[
                        t('lbl_create_new', { defaultValue: 'Create new' }),
                        t('lbl_edit_remove', { defaultValue: 'Edit / Remove' }),
                    ]}
                >
                    <CreateNewWatchlist
                        addPanel={addPanel}
                        hideFromIndex={hideFromIndex}
                    />
                    <EditRemoveWatchlist
                        addPanel={addPanel}
                        hideFromIndex={hideFromIndex}
                    />
                </TabControl>
            </div>
        );
    },
    AddEntitiesToWatchlist: ({ addPanel, removePanel, item }) => {
        return (
            <AddEntitiesToWatchlist
                item={item}
                addPanel={addPanel}
                removePanel={removePanel}
            />
        );
    },
    DeleteWatchlist: ({ addPanel, removePanel, hideFromIndex, item }) => {
        return (
            <DeleteWatchlist
                item={item}
                addPanel={addPanel}
                hideFromIndex={hideFromIndex}
                removePanel={removePanel}
            />
        );
    },
    EditWatchlistLabel: ({ addPanel, removePanel, item }) => {
        return (
            <EditWatchlistLabel
                item={item}
                addPanel={addPanel}
                removePanel={removePanel}
            />
        );
    },
};

panelsWatchlist.CreateOrEdit.defaultProps = {
    addPanel: () => {
        logger.log('add panel not declared in props');
    },
    hideFromIndex: () => {
        logger.log('hide from index not declared in props');
    },
};

panelsWatchlist.CreateOrEdit.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
};

panelsWatchlist.AddEntitiesToWatchlist.defaultProps = {
    addPanel: () => {
        logger.log('add panel not declared in props');
    },
    removePanel: () => {
        logger.log('removePanel not declared in props');
    },
    item: {},
};

panelsWatchlist.AddEntitiesToWatchlist.propTypes = {
    addPanel: PropTypes.func,
    removePanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};

panelsWatchlist.DeleteWatchlist.defaultProps = {
    addPanel: () => {
        logger.log('add panel not declared in props');
    },
    hideFromIndex: () => {
        logger.log('hide from index not declared in props');
    },
    removePanel: () => {
        logger.log('hide from index not declared in props');
    },
    item: {},
};

panelsWatchlist.DeleteWatchlist.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
    removePanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};

panelsWatchlist.EditWatchlistLabel.defaultProps = {
    addPanel: () => {
        logger.log('add panel not declared in props');
    },
    removePanel: () => {
        logger.log('hide from index not declared in props');
    },
    item: {},
};

panelsWatchlist.EditWatchlistLabel.propTypes = {
    addPanel: PropTypes.func,
    removePanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};

export default panelsWatchlist;
