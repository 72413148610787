import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { motion } from 'framer-motion';
import './styles.scss';

// NB: this is an example, we should have multiple types of routes that serve different types of animation types.
// Alternatively just put this to wrap the switchcase with an inital=true
const AnimatedRoute = ({
    children,
    // exact = false,
    path,
    slide = 0,
    slideUp = 0,
    ...rest
}) => (
    <Route exact path={path} {...rest}>
        <motion.div
            className="ma-pulse-ui-animated-route"
            exit={{ opacity: 0, x: slide, y: slideUp }}
            initial={{ opacity: 0, x: slide, y: slideUp }}
            animate={{ opacity: 1, x: 0, y: 0 }}
        >
            {children}
        </motion.div>
    </Route>
);

AnimatedRoute.propTypes = {
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    slide: PropTypes.number,
    slideUp: PropTypes.number,
};

export default AnimatedRoute;
