import React from 'react';
import FilteredPaginatedList from '@bit/modus-moodys.mapulseui.filtered-paginated-list';
import GovEntityRow from '@bit/modus-moodys.mapulseui.entity-row-government-pulse';
import LabelWithButton from '@bit/modus-moodys.mapulseui.label-with-button';
import DashErrorState from '@bit/modus-moodys.mapulseui.dash-error-state';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { requests } from '../../requests';
import {
    DEFAULT_INCLUDES,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT,
    DEFAULT_MAX_SHORTCUTS,
} from '@pages/PagePortfolio/defaults';

import './styles.scss';

const panelAddShortcuts = {
    AddShortcuts: ({ item }) => {
        const { getPortfolio, shortcutEntity, createShortcut, deleteShortcut } =
            requests;
        const { t } = useTranslation();
        const addEntitiesLabel = t('lbl_no_entity_in_portfolio', {
            defaultValue: "You haven't added any entities yet",
        });
        const buttonLabel = t('lbl_add_entity_to_portfolio', {
            defaultValue: 'Add entities to your portfolio',
        });
        const maxShortcuts = DEFAULT_MAX_SHORTCUTS;
        const subtitle = t('lbl_add_shortcuts_subtitle', {
            maxValue: maxShortcuts,
            defaultValue: `Please select the organizations or people you want to add (${maxShortcuts} max.)`,
        });
        const maxTitle = t('lbl_maximum_entities', {
            defaultValue: 'Maximum number of entities have been added.',
        });
        const clear = t('lbl_clear', {
            defaultValue: 'Clear',
        });
        const hasOnAddEntityCB = (item) =>
            item.props?.onAddEntity &&
            typeof item.props?.onAddEntity === 'function';
        const headers = [
            {
                label: 'Name',
                col: 'name',
                sortBy: 'ASC',
                width: '21%',
                selected: true,
            },
            { label: '', col: 'location', sortBy: '', width: '11.5%' },
            {
                label: 'High-risk',
                col: 'num_high',
                sortBy: 'DESC',
                width: '5.5%',
            },
            {
                label: 'Med-risk',
                col: 'num_medium',
                sortBy: 'DESC',
                width: '5.5%',
            },
            {
                label: 'Low-risk',
                col: 'num_low',
                sortBy: 'DESC',
                width: '5.5%',
            },
            {
                label: 'Last Viewed',
                col: 'last_viewed',
                sortBy: 'DESC',
                width: '7.5%',
            },
            {
                label: 'Last News Found',
                col: 'date_news_last_created',
                sortBy: 'DESC',
                width: '10%',
            },
            {
                label: 'Date Added',
                col: 'date_added',
                sortBy: 'DESC',
                width: '7.5%',
            },
        ];
        const handleAddEntityClick = () => {
            if (hasOnAddEntityCB) {
                item.props.onAddEntity();
            }
        };

        const emptyEntity = () => (
            <div className="entity-empty">
                <LabelWithButton
                    cls="empty-shortcuts"
                    label={addEntitiesLabel}
                    btnLabel={buttonLabel}
                    onClick={handleAddEntityClick}
                />
            </div>
        );

        const cmpError = ({ doReload }) => (
            <div className="entity-error">
                <DashErrorState
                    message={
                        'Oops! Something went wrong. Please try again later'
                    }
                    btnLabel={'Reload'}
                    onClick={doReload}
                />
            </div>
        );

        const actionSelect = async (item) => {
            if (!item?.id) {
                return;
            }
            const id = parseInt(item.id, 10);
            if (!id) {
                return;
            }
            if (item?.isChecked) {
                return await createShortcut([id]);
            } else {
                return await deleteShortcut([id]);
            }
        };
        const actionView = async (searchTerm, page, sortBy) => {
            const sortColumn = sortBy
                ? sortBy.find((column) => column.selected == true)
                : null;
            let sort = null;
            if (sortColumn?.id && sortColumn?.type) {
                sort = {};
                sort[sortColumn.id] = sortColumn.type;
            }
            const shortcuts = await shortcutEntity({
                page: 1,
                size: DEFAULT_PAGE_SIZE,
            });
            const ids = shortcuts?.entities
                ? shortcuts.entities.map((item) => item.entity_id)
                : [];
            const returnedData = await getPortfolio({
                page: page || 1,
                size: DEFAULT_PAGE_SIZE,
                sort: sort ? sort : DEFAULT_SORT,
                search: searchTerm || '',
                include: DEFAULT_INCLUDES,
            });
            const items = returnedData.data.map((entity) => {
                const checked = ids?.includes(parseInt(entity.id, 10));
                return { ...entity, isChecked: checked };
            });
            return {
                data: items,
                total: returnedData.total,
                page: returnedData.page,
                pages: returnedData.pages,
                totalItemsChecked: ids.length,
            };
        };
        return (
            <div className="panel add-shortcuts-panel">
                <h1 className="title heading-2">
                    {t('lbl_add_shortcuts_title', {
                        defaultValue: `Add to My shortcuts`,
                    })}
                </h1>
                <p className="subheading">{subtitle}</p>
                <FilteredPaginatedList
                    headers={headers}
                    cmpChild={GovEntityRow}
                    cmpEmpty={emptyEntity}
                    cmpError={cmpError}
                    actionView={(searchTerm, page, sortBy) =>
                        actionView(searchTerm, page, sortBy)
                    }
                    actionSelect={(item) => actionSelect(item)}
                    maxChecked={DEFAULT_MAX_SHORTCUTS}
                    maxTitle={maxTitle}
                    totalItemsCheckedKey={'totalItemsChecked'}
                    showAllNone={true}
                    showAll={false}
                    noneLabel={clear}
                ></FilteredPaginatedList>
            </div>
        );
    },
};

panelAddShortcuts.AddShortcuts.defaultProps = {
    item: {},
};

panelAddShortcuts.AddShortcuts.propTypes = {
    item: PropTypes.objectOf(PropTypes.object),
};

export default panelAddShortcuts;
