import React, { useState, useEffect, useRef } from 'react';
import { requests } from '@requests';
import { useMemo } from 'react';
import ActionWithIcon from '@bit/modus-moodys.mapulseui.action-with-icon';
import logger from '@bit/modus-moodys.mapulseui.logger';
import {
    AvailableTiles,
    TilesEditPanelMapping,
    AllTileslabel,
    AllTilesDefaultlabel,
} from '../Tiles';
import { useTranslation } from 'react-i18next';
import {
    RISK_SCOPES,
    UNIQUE_KEY_LEN,
    DEFAULT_RANGE,
    DEFAULT_TILE_WIDTH,
} from './constants';
import { ResizableBox } from 'react-resizable';
import { useRefresh } from 'muuri-react';
import { debounceFn, getDeferredPromise } from '@helpers/utils';
import { useAppContext } from '@store';
import panelProps from 'flows';
import { nanoid } from 'nanoid';
import GAeventsMap from '@pages/PageDashboard/ga-events';

export const getComponentLabel = (tileState = {}, labels = {}) => {
    const { currentScope } = tileState;

    if (currentScope === RISK_SCOPES.ALL) {
        return labels.entireCatalog ?? 'Entire Catalog';
    } else if (currentScope === RISK_SCOPES.WATCHLIST) {
        return labels.myWatchlist ?? 'My Watchlist';
    } else if (currentScope === RISK_SCOPES.PORTFOLIO) {
        return labels.myPortfolio ?? 'My Portfolio';
    }
    return 'Unknown Component Label';
};

export const updateTileConfig =
    (tileUniqueId, userAvailableTiles, setUserAvailableTiles) =>
    async (toUpdateData = {}) => {
        try {
            const tileInstanceIndex = userAvailableTiles.findIndex(
                (tile) => tile.uniqueId === tileUniqueId
            );
            if (tileInstanceIndex === -1) {
                throw new Error(`Invalid tileUniqueId: ${tileUniqueId}`);
            }
            userAvailableTiles[tileInstanceIndex] = {
                ...userAvailableTiles[tileInstanceIndex],
                ...toUpdateData,
            };
            const response = await requests.updateDashboard(userAvailableTiles);
            setUserAvailableTiles(userAvailableTiles);
            return response;
        } catch (error) {
            logger.error(error);
        }
    };

export const openPanel = async (panelId, addPanel, tileData) => {
    const { promise, resolve } = getDeferredPromise();
    addPanel(panelId, {
        ...tileData,
        onRemove: (data = {}) => {
            // data can be undefined
            resolve(data);
        },
    });
    return promise;
};

export const editTile =
    (panelId, addPanel, meta = {}) =>
    async (tileData = {}) => {
        const editedData = await openPanel(panelId, addPanel, tileData);
        if (meta.tileId) {
            GAeventsMap.editCard(meta.tileId, {
                scope: editedData.scope,
            });
        }
        return editedData;
    };

export const removeTile =
    (tileUniqueId, userAvailableTiles, setUserAvailableTiles) => async () => {
        try {
            const newUserAvailableTiles = userAvailableTiles.filter(
                (tile) => tile.uniqueId !== tileUniqueId
            );
            const response = await requests.updateDashboard(
                newUserAvailableTiles
            );
            setUserAvailableTiles(newUserAvailableTiles);
            return response;
        } catch (error) {
            logger.error(error);
        }
    };

const anyUndefined = (...args) => args.some((arg) => arg === undefined);

export const useUserTiles = (
    { userAvailableTiles, setUserAvailableTiles, gridWidth },
    deps
) => {
    if (anyUndefined(userAvailableTiles, setUserAvailableTiles)) {
        throw new Error(
            `useUserTiles requires TileEntityCreator, userAvailableTiles, and setUserAvailableTiles`
        );
    }

    const { stPanels, actSetPanels } = useAppContext();

    const { addPanel } = panelProps(stPanels, actSetPanels);

    const { t } = useTranslation();

    const labelMyPortfolio = t('lbl_my_portfolio', {
        defaultValue: 'My Portfolio',
    });
    const labelEntireCatalog = t('lbl_entire_catalog', {
        defaultValue: 'Entire Catalog',
    });
    const labelMyWatchlist = t('lbl_my_watchlist', {
        defaultValue: 'My Watchlist',
    });

    return useMemo(() => {
        return userAvailableTiles.map((data, _index) => {
            const tileId = data.tileId;
            const tileUniqueId = data.uniqueId;
            const TileEntityCreator = AvailableTiles[tileId];
            const panelId = TilesEditPanelMapping[tileId];

            if (!TileEntityCreator) {
                logger.error(`Tile with id ${tileId} not found`);
                return null;
            }

            const onRemove = () => {
                return requests.removeDashboardTile(
                    tileUniqueId,
                    userAvailableTiles
                );
            };

            const tileConfigUpdater = updateTileConfig(
                tileUniqueId,
                userAvailableTiles,
                setUserAvailableTiles
            );

            const debouncedUpdateTileConfig = debounceFn(
                tileConfigUpdater,
                500
            );
            const onLengthChange = async (length) => {
                try {
                    await debouncedUpdateTileConfig({
                        dimens: {
                            length,
                        },
                    });
                } catch (error) {
                    logger.error(error);
                }
            };

            let sharedProps = {
                id: `dash-tile-trending-${_index}`,
                onRemove,
                labels: {
                    entireCatalog: labelEntireCatalog,
                    myPortfolio: labelMyPortfolio,
                    myWatchlist: labelMyWatchlist,
                },
            };

            const tileData = { ...data, index: _index };

            return (
                <ResizableWrapper
                    key={tileUniqueId}
                    onLengthChange={onLengthChange}
                    length={data?.dimens?.length}
                    gridWidth={gridWidth}
                    tileData={tileData}
                >
                    <TileEntityCreator
                        {...sharedProps}
                        tileData={tileData}
                        t={t}
                        updateTileConfig={tileConfigUpdater}
                        removeTile={removeTile(
                            tileUniqueId,
                            userAvailableTiles,
                            setUserAvailableTiles
                        )}
                        editTile={editTile(panelId, addPanel, {
                            tileId: tileData.tileId,
                        })}
                    />
                </ResizableWrapper>
            );
        });
    }, [...deps, gridWidth, stPanels]);
};

export const useAddTile = (existingDashboard = [], reloadView) => {
    const { stPanels, actSetPanels } = useAppContext();

    const { addPanel } = panelProps(stPanels, actSetPanels);
    const [isAddTileHidden, setIsAddTileHidden] = useState(true);

    const { t } = useTranslation();

    const onAddTile = async (tileId) => {
        GAeventsMap.addCardStart(tileId);
        setIsAddTileHidden(true);
        const panelId = TilesEditPanelMapping[tileId];
        const data = await openPanel(panelId, addPanel);
        const { scope, userWatchlistId, selectedLocations } = data;
        if (scope) {
            const updatedTile = [
                ...existingDashboard,
                {
                    tileId: tileId,
                    dimens: { length: DEFAULT_TILE_WIDTH },
                    uniqueId: nanoid(UNIQUE_KEY_LEN),
                    selectedInterval: DEFAULT_RANGE,
                    currentScope: scope,
                    userWatchlistId: userWatchlistId,
                    locations: selectedLocations,
                },
            ];
            await requests.updateDashboard(updatedTile);
            const gaPayload = {
                tileId,
                scope,
            };
            if (selectedLocations) {
                gaPayload.locations = selectedLocations.map(
                    (location) => location.id
                );
            }
            GAeventsMap.addCardEnd({ ...gaPayload });
            GAeventsMap.dashboardInitiate('add_tile', { tileId });
            reloadView();
        } else {
            GAeventsMap.addCardBack({ tile_id: tileId });
        }
    };

    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the Add Tile is open and the clicked target is not within the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setIsAddTileHidden(true);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, []);

    const AddTileSelector = useMemo(() => {
        return (
            <div className="add-tile-popper" ref={ref}>
                {Object.keys(AllTileslabel).map((tileId) => {
                    const tileName = t(AllTileslabel[tileId], {
                        defaultValue: AllTilesDefaultlabel[tileId],
                    });
                    return (
                        <span className="tile-icon" key={tileId}>
                            <ActionWithIcon
                                cls="add-cta"
                                icon="nav-add"
                                color="#095FC3"
                                label={tileName}
                                onClick={() => onAddTile(tileId)}
                            />
                        </span>
                    );
                })}
            </div>
        );
    }, [existingDashboard, stPanels]);

    return {
        AddTileSelector,
        setIsAddTileHidden,
        isAddTileHidden,
    };
};

const pct = (num, den) => {
    return (num * den) / 100;
};

export const ResizableWrapper = ({
    children,
    onLengthChange,
    length,
    gridWidth,
    ...rest
}) => {
    gridWidth = gridWidth - 20;

    const CARD_MAX_WIDTH = gridWidth;
    const CARD_MIN_WIDTH = Math.floor(CARD_MAX_WIDTH / 2 - 10);
    const CARD_RESIZE_THRESHOLD = CARD_MIN_WIDTH + pct(50, CARD_MIN_WIDTH);

    const [dimens, setDimens] = useState({
        width: length === 'full' ? CARD_MAX_WIDTH : CARD_MIN_WIDTH,
        length,
    });

    useEffect(() => {
        setDimens({
            width: length === 'full' ? CARD_MAX_WIDTH : CARD_MIN_WIDTH,
            length,
        });
    }, [gridWidth]);

    const [isResizing, setIsResizing] = useState(false);

    const refresh = useRefresh([
        dimens,
        gridWidth,
        CARD_MAX_WIDTH,
        CARD_MIN_WIDTH,
    ]);
    const refreshWithdebounce = debounceFn(
        () => requestAnimationFrame(refresh),
        50
    );

    const handleResize = (e, { size }) => {
        refreshWithdebounce();
        setDimens(size);
    };

    const handleResizeStop = (e, { size }) => {
        setIsResizing(false);
        let { width } = size;
        let length;
        if (width > CARD_RESIZE_THRESHOLD) {
            width = CARD_MAX_WIDTH;
            length = 'full';
        } else {
            width = CARD_MIN_WIDTH;
            length = 'half';
        }
        if (onLengthChange && length !== dimens?.length) {
            onLengthChange(length);
        }
        setDimens({ width, length });
    };

    return (
        <ResizableBox
            {...rest}
            onResize={handleResize}
            width={dimens.width}
            height={'100%'}
            minConstraints={[CARD_MIN_WIDTH]}
            maxConstraints={[CARD_MAX_WIDTH]}
            resizeHandles={['e']}
            onResizeStop={handleResizeStop}
            onResizeStart={() => setIsResizing(true)}
        >
            <div className={'resizable-container'}>
                <div
                    style={{
                        width: dimens.width,
                    }}
                >
                    {children}
                </div>
                {isResizing && (
                    <div
                        className="resizable-placeholder"
                        style={{
                            width:
                                dimens.width > CARD_RESIZE_THRESHOLD
                                    ? CARD_MAX_WIDTH
                                    : CARD_MIN_WIDTH,
                        }}
                    />
                )}
            </div>
        </ResizableBox>
    );
};
