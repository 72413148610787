import ArticleHighlightTip from '@bit/modus-moodys.mapulseui.article-highlight-tip';
import ArticleView from '@bit/modus-moodys.mapulseui.article-view';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { requests } from '../../requests';
import './styles.scss';
import { useAppContext } from '../../store';
import { useTranslation } from 'react-i18next';
import { RiskSentimentLabelMapping } from '@pages/PageDetails/defaults';
import logger from '@bit/modus-moodys.mapulseui.logger';
import highlight_tooltip_img from '../../images/highlight_tooltip_img.png';

const {
    getEventArticleById,
    getArticle,
    updateArticle,
    updateUserPreferences,
    updateArticleToFolder,
    moveArticleToNotRelavant,
} = requests;

const ARTICLE_PANE_NAME = `ArticleViewPanel`;

const ArticleViewPanels = {
    // todo: can add removePanel to props
    ArticleViewPanel: ({ item, removePanel }) => {
        const { stfolders: folders, stActiveFolder: activeFolder } =
            useAppContext();
        const { articleId, stUserInfo, handleManageFolder, isEventArticle } =
            item.props;
        const [articleData, setArticleData] = useState(null);
        const [tipConfirmed, setTipConfirmed] = useState(
            stUserInfo?.preferences.is_snippet_helper ||
                JSON.parse(localStorage.getItem('user_pref_is_snippet_helper'))
        );
        const { t } = useTranslation();
        const moveArticleToFolder = async (params) => {
            if (params?.folderId) {
                try {
                    await updateArticleToFolder({
                        articleId,
                        userFolderId:
                            params.folderId === 'news-feed'
                                ? null
                                : params.folderId,
                    });
                } catch (err) {
                    logger.log(err);
                }
            }
        };
        const markArticleNotRelavant = async (params) => {
            const notRelevantReasons = (params.reasons || [])
                .filter((i) => i.checked)
                .map((i) => i.name);
            try {
                // NXTGN-422: Close the panel immediately after article is marked as not relevant
                await moveArticleToNotRelavant({
                    articleId,
                    reasons: notRelevantReasons?.length
                        ? notRelevantReasons
                        : ['not-relevant'],
                });
                removePanel(ARTICLE_PANE_NAME);
            } catch (err) {
                logger.log(err);
            }
        };
        const handleSave = async (artId, snippets) => {
            try {
                await updateArticle({ articleId: artId, snippets });
            } catch (error) {
                return new Error(error);
            }
        };

        const handleTipClose = async (confirmed) => {
            updateUserPreferences({ isSnippetHelper: confirmed });
            localStorage.setItem('user_pref_is_snippet_helper', `${confirmed}`);
            setTipConfirmed(true);
        };

        const _getArticle = isEventArticle ? getEventArticleById : getArticle;

        const getArticleById = async () => {
            try {
                const { data } = await _getArticle({ articleId });
                setArticleData(data);
            } catch (error) {
                logger.log(error);
            }
        };
        useEffect(() => {
            getArticleById();
        }, []);

        const onHighlightAction = (action) => {
            if (isEventArticle) return;
            // actions are save, copy, remove
            if (action === 'remove') return;
            // GTM
            window.Analytics?.sendEvent?.('highlight_action', {
                articleId: articleData.id,
                entityId: articleData.entityId,
            });
        };
        const getArticleActionProps = () => {
            return {
                disableNotRelevant: activeFolder.id === 'not_relevant',
                labelTexts: {
                    labelSave: t(`lbl_save`),
                    labelNotRelevant: t('lbl_not_relevant'),
                },
                articleNotRelevantFormProps: {
                    labelTexts: {
                        submitLabel: t('lbl_done'),
                        skipLabel: t('lbl_skip'),
                        formTitle: t('lbl_not_relevant_title'),
                    },
                    onSubmit: !isEventArticle
                        ? markArticleNotRelavant
                        : () => {},
                },
                folderSelectProps: {
                    onCreateFolderClick: handleManageFolder,
                    onSubmit: !isEventArticle ? moveArticleToFolder : () => {},
                    folders: [
                        {
                            label: t('lbl_news_item', { item: t('lbl_feed') }),
                            id: 'news-feed',
                            icon: 'list',
                        },
                        ...folders,
                    ].filter((folder) => folder.id !== activeFolder.id),
                    labelTexts: {
                        title: t('title_folder_popper'),
                        noFolderTitle: t('lbl_no_folder_title'),
                        createFolderButtonText: `${t('lbl_create')} ${t(
                            'lbl_folder'
                        )}`,
                        lblDone: t('lbl_done'),
                        noFoldersFound: t('lbl_no_folder'),
                    },
                },
            };
        };
        return (
            <div className="article-view-panel panel">
                {articleData && (
                    <ArticleView
                        {...articleData}
                        riskSentiment={
                            articleData?.riskSentiment
                                ? RiskSentimentLabelMapping[
                                      articleData?.riskSentiment?.toLowerCase()
                                  ]
                                : ''
                        }
                        onSave={!isEventArticle ? handleSave : () => {}}
                        onHighlightAction={
                            !isEventArticle ? onHighlightAction : () => {}
                        }
                        containerClass="article-view-panel"
                        articleActionProps={{
                            ...getArticleActionProps(),
                        }}
                        labelTexts={{
                            source: t('lbl_source', { defaultValue: 'Source' }),
                            copied: t('lbl_copied', { defaultValue: 'Copied' }),
                            copy: t('lbl_copy', { defaultValue: 'Copy' }),
                            save: t('lbl_save', { defaultValue: 'Save' }),
                            remove: t('lbl_remove', { defaultValue: 'Remove' }),
                        }}
                    />
                )}
                {!tipConfirmed && articleData && (
                    <ArticleHighlightTip
                        imageSrc={highlight_tooltip_img}
                        rightTextContent={
                            <>
                                <h3 className="heading-3">
                                    Tip: Save your highlights
                                </h3>
                                <p className="p-1 heading-14">
                                    You can add highlights to an article by
                                    dragging over the text and clicking “Save.”
                                    You can also copy the text to your clipboard
                                    by clicking “Copy.”
                                </p>
                                <p className="p-2 heading-14">
                                    <b>NOTE:</b> If the text contains{' '}
                                    <span className="text-link">
                                        text links
                                    </span>{' '}
                                    or <b>bolded text</b>, make sure that the
                                    entire link or bolded text is included in
                                    the selection for the highlighting feature
                                    to work properly.
                                </p>
                            </>
                        }
                        onClose={handleTipClose}
                    />
                )}
            </div>
        );
    },
};

ArticleViewPanels.ArticleViewPanel.propTypes = {
    item: PropTypes.instanceOf(PropTypes.object).isRequired,
    removePanel: PropTypes.string.isRequired,
};

export default ArticleViewPanels;
