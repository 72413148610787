export const tabNameToIdMap = {
    news: 0,
    related: 1,
};
export const getDefaultTabId = (query) => {
    const tabName = new URLSearchParams(query).get('tab');
    return tabName && tabNameToIdMap[tabName] ? tabNameToIdMap[tabName] : 0;
};

export const tabsControllers = (t) => [
    {
        label: t('lbl_news_item', {
            defaultValue: `News Article`,
            item: t('lbl_article'),
        }),
        tabId: tabNameToIdMap.news,
        enabled: true,
    },
    {
        label: t('lbl_related_entity_plural', {
            defaultValue: 'Related Entities',
        }),
        tabId: tabNameToIdMap.related,
        enabled: true,
    },
];
