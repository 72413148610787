import React, { useContext } from 'react';
import PropTypes from 'prop-types';

export const Context = React.createContext();
export const useTestingPane = () => useContext(Context);

export const TestingPaneProvider = ({ children, testMethods = {} }) => {
    const [availableMethods, setAvailableMethods] = React.useState(testMethods);
    const addMethod = (method) => {
        setAvailableMethods((availableMethods) => ({
            ...availableMethods,
            ...method,
        }));
    };

    const removeMethod = (methodNames = []) => {
        const newAvailableMethods = {};
        for (const methodName in availableMethods) {
            if (methodNames.includes(methodName)) {
                continue;
            }
            newAvailableMethods[methodName] = availableMethods[methodName];
        }
        setAvailableMethods(newAvailableMethods);
    };

    return (
        <Context.Provider value={{ addMethod, availableMethods, removeMethod }}>
            {children}
        </Context.Provider>
    );
};

TestingPaneProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    testMethods: PropTypes.object,
};
