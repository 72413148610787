import React, { useMemo } from 'react';
import DashTileTrendingRiskCategories from '@bit/modus-moodys.mapulseui.dash-tile-trending-risk-categories';
import { requests } from '@requests';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { getComponentLabel } from '../Helpers/utils';
import { useAppContext } from '@store';
import { useTileState } from './utils';
import { RISK_SCOPES } from '../Helpers/constants';
import GAeventsMap from '@pages/PageDashboard/ga-events';

const TrendingRiskCategoriesTile = ({
    tileData = {},
    t,
    labels = {},
    updateTileConfig,
    removeTile,
    editTile,
    ...props
}) => {
    const { stUserInfo } = useAppContext();

    const titleLabel = t('lbl_trending_risk_cat', {
        defaultValue: 'Trending Risk Categories',
    });

    const articlesFromLabel = t('lbl_articles_from', {
        defaultValue: 'Articles from:',
    });

    const handleAsyncAction = async () => {
        const selectedInterval = tileState()?.selectedInterval;

        const currentScope = tileState()?.currentScope;

        const requestQuery = {};

        if (currentScope !== RISK_SCOPES.ALL) {
            requestQuery.current_user_id = stUserInfo.user_id;
        }

        if (tileState()?.userWatchlistId) {
            requestQuery.user_watchlist_id = tileState()?.userWatchlistId;
        }

        const response = await requests.trendingCategories({
            interval: selectedInterval,
            size: 10,
            ...requestQuery,
        });

        return {
            labelTexts: {
                noDataProvided: t('lbl_no_new_articles', {
                    defaultValue: `There are no new articles from the last ${selectedInterval} days`,
                    interval: `${selectedInterval} days`,
                }),
            },
            items: (response ?? []).map((item) => ({
                ...item,
                value: item.num_total,
            })),
        };
    };

    const labelTexts = {
        title: titleLabel,
        configText: articlesFromLabel,
    };

    const onStateUpdate = async (state) => {
        try {
            await updateTileConfig(state);
        } catch (error) {
            logger.error(error);
        }
    };

    const { setTileState, tileState } = useTileState(tileData, onStateUpdate);

    const onUpdateMeta = (_, selectedInterval) => {
        GAeventsMap.editCard(tileData.tileId, {
            scope: tileState().currentScope,
            selectedInterval,
        });
        setTileState({
            selectedInterval,
        });
    };

    const handleEdit = async () => {
        const updatedMeta = await editTile({
            scope: tileState()?.currentScope,
            userWatchlistId: tileState()?.userWatchlistId,
        });
        const { scope, userWatchlistId } = updatedMeta;
        if (scope) {
            setTileState({
                currentScope: scope,
                userWatchlistId,
            });
            return true;
        }
        return false;
    };

    /*
        Added memo because appContext stPanels causes re-rendering of the component.
        During addPanel it causes unnecessary rerender
    */
    const memoizedRiskCategories = useMemo(() => {
        return (
            <DashTileTrendingRiskCategories
                {...props}
                labelTexts={labelTexts}
                onUpdateMeta={onUpdateMeta}
                action={handleAsyncAction}
                onEdit={handleEdit}
                meta={{
                    source: {
                        scope: tileState().currentScope,
                        data: {
                            label: getComponentLabel(tileState(), labels),
                        },
                    },
                    intervalInDays: tileState().selectedInterval,
                }}
                index={tileData?.index}
                onRemove={removeTile}
                key={tileData?.uniqueId}
            />
        );
    }, [tileState(), tileData]);

    return memoizedRiskCategories;
};

export default TrendingRiskCategoriesTile;
