import { useRef } from 'react';

export const validNameRegex = /[^a-zA-Z0-9\s]/;
export const debounceFn = (fn, ms) => {
    return (...args) => {
        if (fn.timeoutId) {
            clearTimeout(fn.timeoutId);
        }
        fn.timeoutId = setTimeout(() => fn(...args), ms);
    };
};

export const isNameValid = (name) => {
    return validNameRegex.test(name);
};

export const isEnvDev = () => {
    return (
        process.env.REACT_APP_NODE_ENV === 'local' ||
        process.env.REACT_APP_NODE_ENV === 'development'
    );
};

export const capitalize = (lower) =>
    lower.charAt(0).toUpperCase() + lower.substring(1);
export const transformIdToMsg = (id) => {
    if (typeof id !== typeof '' || id.length === 0) return '';
    return id
        .split('_')
        .map((i, index) => (index === 0 ? capitalize(i) : i))
        .join(' ');
};

export const getDeferredPromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        promise,
        resolve,
        reject,
    };
};

export const sumArray = (dataArr = []) =>
    dataArr.reduce((ac, cur) => ac + cur, 0);

export const filterUndefinedFromObject = (obj = {}) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

export const safeParse = (stringToParse, defaultValue) => {
    try {
        const response = JSON.parse(stringToParse);
        return [response, undefined];
    } catch (e) {
        return [defaultValue, e];
    }
};

/* 
    This function uses react ref to maintain timeout ids
    In some cases if you need to fire the function immediately
    you can pass `{ fireImmediately: true }` as the last argument
*/
export const useDebouncedCallback = (callback, delay) => {
    const debounceRef = useRef({});
    return (...args) => {
        const lastArgument = args[args.length - 1] ?? {};
        if (debounceRef.current.timeoutId) {
            clearTimeout(debounceRef.current.timeoutId);
        }
        debounceRef.current.timeoutId = setTimeout(
            () => {
                callback(...args);
            },
            lastArgument?.fireImmediate ? 0 : delay
        );
    };
};

const withSuffix = (n, rangeSuffix, precision) => {
    const roundedOffValue = Math.floor(n);
    const appendPlus = roundedOffValue !== n;
    return `${roundedOffValue.toFixed(precision || 0)}${rangeSuffix}${
        appendPlus ? '+' : ''
    }`;
};

export const getFormattedText = (n) => {
    if (typeof n !== typeof 0) return 0;
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return withSuffix(n / 1e3, 'K');
    if (n >= 1e6 && n < 1e9) return withSuffix(n / 1e6, 'M');
    if (n >= 1e9 && n < 1e12) return withSuffix(n / 1e6, 'B');
    if (n >= 1e12) return withSuffix(n, 1e12, 'T');
};

export const parseRegionCountry = (data, LOCATION_TYPE) => {
    const setLocation = (type, loc) => {
        return {
            type,
            id: loc.label,
            label: loc.label,
            count: loc.count,
        };
    };

    const regions = (data?.region ?? []).map((region) =>
        setLocation(LOCATION_TYPE.REGION, region)
    );
    const countries = (data?.country ?? []).map((country) =>
        setLocation(LOCATION_TYPE.COUNTRY, country)
    );

    return [regions, countries];
};

export const asyncTimeout = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};
