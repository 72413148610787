import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TabPanel from '@bit/modus-moodys.mapulseui.tab-panel';
import InputText from '@bit/modus-moodys.mapulseui.input-field';
import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import { useAppContext } from '../../store';
import { transformIdToMsg } from '../../helpers/utils';
import { requests } from '../../requests';
import logger from '@bit/modus-moodys.mapulseui.logger';

const schemaValidationErrorText =
    'Watchlist name cannot contain special characters';
// eslint-disable-next-line react/prop-types
const CreateNewWatchlist = ({ addPanel, hideFromIndex }) => {
    const [watchlistName, setWatchlistName] = useState('');
    const { actSetWatchlistEditing } = useAppContext();
    const [error, setError] = useState(null);
    const [watchlistCreateSuccess, setWatchlistCreateSuccess] = useState(false);
    const [watchlistInputDisabled, setWatchlistInputDisabled] = useState(false);
    const { t } = useTranslation();
    const createWatchlist = async () => {
        setError(null);
        const resp = await requests.createWatchlist({ label: watchlistName });
        return resp;
    };
    const handleChange = (value) => {
        setWatchlistName(value);
        setWatchlistInputDisabled(false);
        setWatchlistCreateSuccess(false);
        // if (isNameValid(value)) setError(schemaValidationErrorText);
        // else setError(null);
    };
    const handleResults = ({ data, error: err }) => {
        try {
            const watchlistCreated = data.data[0];
            actSetWatchlistEditing(watchlistCreated);
            setWatchlistInputDisabled(true);
            addPanel('AddEntitiesToWatchlist', { watchlist: data.data[0] });
            setWatchlistCreateSuccess(true);
        } catch {
            if (err.id === 'schema_validation_failed') {
                setError(schemaValidationErrorText);
            } else {
                setError(transformIdToMsg(err.id));
            }
            setWatchlistInputDisabled(false);
            logger.log('error response:', err);
        }
    };

    return (
        <TabPanel cls="manage-watchlists-create-new">
            <span className="secondary-text label">{`${t('lbl_watchlist', {
                defaultValue: 'Watchlist',
            })} ${t('row_header_name', { defaultValue: 'Name' })}`}</span>
            <InputText
                placeholder={`${t('lbl_enter', { defaultValue: 'Enter' })} ${t(
                    'lbl_watchlist',
                    {
                        defaultValue: 'watchlist',
                    }
                ).toLocaleLowerCase()} ${t('row_header_name', {
                    defaultValue: 'name',
                }).toLocaleLowerCase()}`}
                onValueChange={(val) => handleChange(val?.target?.value)}
            />
            {error && <p className="message error-message">{error} </p>}
            {!error && watchlistCreateSuccess && (
                <p className="message success-message heading-8">
                    {t('lbl_create_watchist_success', {
                        defaultValue: 'Watchlist successfully created',
                    })}
                </p>
            )}
            <div className="btn-container">
                <SubmitButton
                    label={`${t('lbl_create', { defaultValue: 'Create' })} ${t(
                        'lbl_watchlist',
                        {
                            defaultValue: 'Watchlist',
                        }
                    )}`}
                    action={createWatchlist}
                    cbResult={handleResults}
                    disabled={
                        !(watchlistName && watchlistName.length > 0) ||
                        watchlistInputDisabled
                    }
                />
                <button
                    type="button"
                    className="secondary-text-link"
                    onClick={() => hideFromIndex(-1)}
                >
                    {t('lbl_cancel', { defaultValue: 'Cancel' })}
                </button>
            </div>
        </TabPanel>
    );
};

CreateNewWatchlist.defaultProps = {
    addPanel: () => {},
    hideFromIndex: () => {},
};

CreateNewWatchlist.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
};

export default CreateNewWatchlist;
