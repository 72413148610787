module.exports = {
    SRC_PATH: './src',
    PUBLIC_PATH: './public',
    APP_NAME: 'government',
    REACT_APP_NODE_ENV: 'prod',
    PUBLIC_APP_URL: 'https://government.newsedge.com',
    POST_BUILD_MUTATION: [
        {
            head: `<!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WVGLQZ7');</script>
    <!-- End Google Tag Manager -->`,
            body: `<!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVGLQZ7"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->`,
        },
    ],
    GMAP_KEY: 'AIzaSyBRJzOZCxRVZ6YCKOR1SRYYXOeGu8f6lpE',
    axios: {
        CLIENT_APP_NAME: 'government',
        errorPage: '/error',
        loginPage: '/login',
        services: {
            support: 'https://shared.ip.newsedge.com/service-support',
            diagnostic: 'https://shared.ip.newsedge.com/service-diagnostic',
            auth: 'https://shared.ip.newsedge.com/service-auth',
            typeahead: 'https://shared.ip.newsedge.com/service-typeahead',
            onboard: 'https://shared.ip.newsedge.com/service-onboard',
            portfolio: 'https://shared.ip.newsedge.com/service-portfolio',
            entity: 'https://shared.ip.newsedge.com/service-entity',
            article: 'https://shared.ip.newsedge.com/service-article',
            statistic: 'https://shared.ip.newsedge.com/service-statistic',
            cam: 'https://government.ip.newsedge.com/service-cam',
            rdc: 'https://shared.ip.newsedge.com/service-rdc',
            dashboard: 'https://shared.ip.newsedge.com/service-dashboard',
            event: 'https://shared.ip.newsedge.com/service-event',
        },
    },
};
