import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EventsMapView from '@bit/modus-moodys.mapulseui.events-map-view';
import LoaderDots from '@bit/modus-moodys.mapulseui.loader-dots';
import PageIndicator from '@bit/modus-moodys.mapulseui.page-indicator';
import { useTranslation } from 'react-i18next';
import { requests } from '../../../../requests';
import { AsyncTaskStatesEnum } from '@bit/modus-moodys.mapulseui._hooks/dist/useAsyncTask';
import { useNotifications } from '@bit/modus-moodys.mapulseui.notifications';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { ActiveTabIndexEnum } from '@pages/PageEvent/PageEventWrapper';
import { EventsError, NoSavedEvents } from '@pages/PageEvent/Components/Error';
import { shortStateName } from '@pages/PageEvent';
import { GaEventsMap } from '@pages/PageEvent/ga-events';

const MapView = ({
    cls,
    eventsData,
    paginationResults,
    gotoPage,
    changeActiveTab,
    activeTab,
}) => {
    const _cls = classNames(cls, 'shared-content-view');
    const [events, setEvents] = useState([]);
    const { addNotification } = useNotifications();
    const { t } = useTranslation();
    const history = useHistory();
    const { hasError, isLoading } = shortStateName(
        paginationResults?.taskState
    );
    const savedEventsTab = activeTab === ActiveTabIndexEnum.MY_SAVED_EVENTS;
    const hasEvents = events && events.length;

    useEffect(() => {
        if (paginationResults?.taskState === AsyncTaskStatesEnum.SUCCESS) {
            setEvents(eventsData ?? []);
        }
    }, [eventsData, paginationResults?.taskState]);

    const onSave = async (eventId, saved) => {
        try {
            await requests.updateUserEvent(eventId, saved);
            return true;
        } catch (error) {
            addNotification.error(
                `${t('lbl_error', { defaultValue: 'Error' })}: ${t(
                    `lbl_${error.id}`,
                    { defaultValue: error.message }
                )}`
            );
            return false;
        }
    };

    const mapViewMemo = useMemo(() => {
        return (
            <EventsMapView
                events={events}
                onSave={onSave}
                onEventClick={(eventId) => {
                    GaEventsMap.eventClickThrough(eventId);
                    history.go(`/events/${eventId}`);
                }}
                apiKey={process.env.GMAP_KEY}
                defaultZoom={4}
                onMapInteraction={(action) => {
                    GaEventsMap.eventMapAction({ action });
                }}
            />
        );
    }, [events]);

    const MapWithLoader = (
        <>
            <div
                className={classNames('item loader background-traslucid', {
                    invisible: !isLoading,
                })}
            >
                <LoaderDots width={40} />
            </div>
            <div className="item map">{mapViewMemo}</div>
        </>
    );

    const MapWithErrorHandling = useMemo(() => {
        if (hasError) return <EventsError />;
        if (savedEventsTab && isLoading && !hasEvents)
            return (
                <div className="item loader">
                    <LoaderDots width={40} />
                </div>
            );
        if (savedEventsTab && hasEvents) return MapWithLoader;
        if (savedEventsTab && !hasEvents && !isLoading)
            return (
                <NoSavedEvents
                    onClick={() => {
                        changeActiveTab(ActiveTabIndexEnum.ALL_EVENTS);
                    }}
                />
            );
        if (!savedEventsTab) return MapWithLoader;
        return MapWithLoader; // Should never reach here
    }, [hasError, savedEventsTab, isLoading, hasEvents, events]);

    return (
        <div className={_cls}>
            <div className="overlaping-container">{MapWithErrorHandling}</div>
            {paginationResults.totalPages > 1 && (
                <PageIndicator
                    pages={paginationResults.totalPages}
                    currentPage={paginationResults.currentPage}
                    isDisabled={false}
                    onClick={(page) => {
                        gotoPage(page);
                    }}
                    MAX={5}
                />
            )}
        </div>
    );
};

MapView.propTypes = {
    id: PropTypes.string,
    cls: PropTypes.string,
    tag: PropTypes.string,
    gotoPage: PropTypes.func,
    paginationResults: PropTypes.shape({
        currentPage: PropTypes.number,
        totalPages: PropTypes.number,
        taskState: PropTypes.string,
    }),
    eventsData: PropTypes.arrayOf(
        PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
            data: PropTypes.shape({
                country: PropTypes.string,
                city: PropTypes.string,
                title: PropTypes.string,
                data: PropTypes.string,
                categories: PropTypes.arrayOf(PropTypes.string),
                enventId: PropTypes.string,
                isSaved: PropTypes.bool,
            }),
        })
    ),
    activeTab: PropTypes.string,
    changeActiveTab: PropTypes.func,
};

MapView.defaultProps = {
    cls: 'map-view',
    id: 'map-view-id',
    tag: 'map-view-tag',
    eventsData: [],
};

export default MapView;
