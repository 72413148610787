/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const { useEffect, useState } = require('react');

/**
 * Initialize all your desired paths for any path.
 * sourcePath : [...desired destination paths.]
 */

const DESEIRED_PATHS_MAP = {
    '/portfolio': ['/details'],
};
/**
 *
 * @param {*} callback callback that tells if route change is desired or not.
 * @abstract Handler to check if the path path change if to a desired route or not.
 */
const useRouteChange = (callback) => {
    const [currentPath, setCurrentPath] = useState('');

    const routeChangeHandler = (path) => {
        for (const source in DESEIRED_PATHS_MAP) {
            if (currentPath && currentPath.includes(source)) {
                let isDesired = false;
                for (
                    let index = 0;
                    index < DESEIRED_PATHS_MAP[source].length;
                    index += 1
                ) {
                    if (path.includes(DESEIRED_PATHS_MAP[source][index])) {
                        isDesired = true;
                        break;
                    }
                }

                if (typeof callback === 'function') {
                    callback(isDesired);
                    return;
                }
            }
        }

        setCurrentPath(path);
    };

    useEffect(() => {
        routeChangeHandler(window.location.pathname);
    }, [window.location.pathname]);
};
export default useRouteChange;
