import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
/* eslint-disable  */
import TabControl from '@bit/modus-moodys.mapulseui.tab-control';
import CreateNewFolder from './CreateNewFolder';
import EditRemoveFolder from './EditRemoveFolder';
import { Translation } from 'react-i18next';

const panelManageFolder = {
    CreateOrEditFolder: ({ hideFromIndex, updateFolderItemList, item }) => {
        return (
            <Translation>
                {(t, {}) => (
                    <div className="folders first-panel">
                        <h1 className="title heading-2" style={{ paddingBottom: '30px' }}>
                            {t('lbl_manage_item', { item: t('lbl_folder', { count: 12 }) })}
                        </h1>
                        <TabControl labels={[t('lbl_create_new'), t('lbl_edit_remove')]}>
                            <CreateNewFolder
                                hideFromIndex={hideFromIndex}
                                updateFolderItemList={updateFolderItemList}
                                item={item}
                            />
                            <EditRemoveFolder
                                updateFolderItemList={updateFolderItemList}
                                item={item}
                            />
                        </TabControl>
                    </div>
                )}
            </Translation>
        );
    },
};
panelManageFolder.CreateOrEditFolder.defaultProps = {
    hideFromIndex: () => {
        console.log('hide from index not declared in props');
    },
    updateFolderItemList: null,
};

panelManageFolder.CreateOrEditFolder.propTypes = {
    hideFromIndex: PropTypes.func,
    updateFolderItemList: PropTypes.func,
};
export default panelManageFolder;
