import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import SurveyPaneContainer from '@bit/modus-moodys.mapulseui.survey-pane-container';
import { useAppContext } from '../../../store';
import { requests } from '../../../requests';
import ThankYouPane from './ThankYouPane/index';
import { getSurveyProps } from './SurveyPaneProps';
import logger from '@bit/modus-moodys.mapulseui.logger';

const GASurveyMapping = {
    utility: 'happiness_q1',
    experience: 'happiness_q2',
    note: 'happiness_free_text',
};

const SurveyPane = () => {
    const { stUserInfo } = useAppContext();
    const surveyPreferences = stUserInfo?.surveyPreferences ?? {};
    const { updateUserPreferences } = requests;
    const { t } = useTranslation();
    useEffect(() => {}, [stUserInfo?.surveyPreferences?.surveyType]);
    const onSurveyComplete = async (surveyType, value) => {
        window.Analytics?.sendEvent?.(GASurveyMapping[surveyType], {
            feedback: value?.label ?? value?.note,
            surveyType: surveyPreferences.surveyType,
        });
        if (!stUserInfo?.surveyPreferences?.isTesting) {
            await updateUserPreferences({
                [`is_satisfaction_${surveyType}`]: 1,
            }).catch(logger.error);
        }
    };
    const [visible, setVisible] = useState(true);
    const onSurveyPaneClose = () => {
        setVisible(false);
    };
    return (
        <SurveyPaneContainer
            surveySteps={[surveyPreferences.surveyType]}
            visible={visible}
            onComplete={onSurveyComplete}
            {...getSurveyProps(t)}
            onClose={onSurveyPaneClose}
            finalContent={renderToString(<ThankYouPane />)}
        />
    );
};

export default SurveyPane;
