import DashTileMulticolumn from '@bit/modus-moodys.mapulseui.dash-tile-multicolumn';
import DashTileEntityInfo from '@bit/modus-moodys.mapulseui.dash-tile-entity-info';
import { useAppContext } from '@store';
import { isEnvDev } from '@helpers/utils';
import { requests } from '@requests';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Trending = () => {
    const { t } = useTranslation();
    const { enabledTestingMethods } = useAppContext();
    const history = useHistory();

    const TrendingView = useMemo(() => {
        return (
            <DashTileMulticolumn
                title={t('lbl_increase_news_volume', {
                    defaultValue: 'Increase in news volume',
                })}
                action={async () =>
                    (
                        await requests.trendingEntity({
                            ...(isEnvDev()
                                ? {
                                      interval_value:
                                          enabledTestingMethods.newsVolumeInterval,
                                  }
                                : {}),
                        })
                    ).map((entity) => ({
                        ...entity,
                        onClick: (entityId) => {
                            history.go(`/details/${entityId}`);
                        },
                    }))
                }
                cmpChild={DashTileEntityInfo}
                cmpEmpty={() => (
                    <p>
                        {t('lbl_no_trending_entities', {
                            defaultValue:
                                "You don't have any trending entities in your portfolio.",
                        })}
                    </p>
                )}
            />
        );
    }, []);

    return TrendingView;
};

Trending.propTypes = {};

export default Trending;
