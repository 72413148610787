import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { history } from '../helpers';
import {
    DEFAULT_PAGE_FILTER,
    getDefaultSortOptions,
} from '../pages/PagePortfolio/defaults';

export const Context = React.createContext();
export const { Consumer } = Context;
export const useAppContext = () => useContext(Context);
export const Provider = ({ children }) => {
    const { t } = useTranslation();
    const [stIsHeaderVisible, actSetHeaderVisibility] = useState(true);
    const [stIsFooterVisible, actSetFooterVisibility] = useState(true);
    const [stFlyout, actSetFlyout] = useState(false);
    const [stUserInfo, actSetUserInfo] = useState(null);
    const [stPanels, actSetPanels] = useState([]);
    const [stfolders, actSetFolders] = useState([]);
    const [stActiveFolder, actSetActiveFolder] = useState({ id: 'news-feed' });
    const [entityDetails, actSetEntityDetails] = useState({
        entityId: null,
        cssScore: null,
        cssDelta: null,
        name: null,
        counts: {
            negative: null,
            creditAdverse: null,
        },
    });
    // TODO: rename some of these, they are not specific enough.
    const [stEntities, actSetEntities] = useState([]);
    const [stByNameEntities, actSetByNameEntities] = useState([]);
    const [stTypeaheadError, actTypeaheadError] = useState(null);
    const [stSearchByNameError, actSetSearchByNameError] = useState(null);
    const [stWatchlistEditing, actSetWatchlistEditing] = useState(null);
    const [stDisclaimerConfirmed, actSetDisclaimerConfirmed] = useState(
        stUserInfo?.preferences?.is_disclaimer
    );
    const [enabledTestingMethods, setEnabledTestingMethods] = useState({});

    const [stDefaultPortfolioFilters, actSetDefaultPortfolioFilters] = useState(
        {
            activeWatchlist: [],
            pageData: DEFAULT_PAGE_FILTER,
            filters: [...getDefaultSortOptions(t)],
        }
    );

    const resetPortfolioFilters = () => {
        actSetDefaultPortfolioFilters({
            activeWatchlist: [],
            pageData: DEFAULT_PAGE_FILTER,
            filters: [...getDefaultSortOptions(t)],
        });
    };

    const DEFAULT_DATE_RANGE = {
        date_start: dayjs().subtract(30, 'day').format(),
        date_end: dayjs().format(),
    };

    const [stDefaultEventFilters, actSetDefaultEventFilters] = useState({
        date_range: DEFAULT_DATE_RANGE,
        category: [],
        theme: [],
        region: [],
        country: [],
        keyword: [],
    });

    // NB: this needs to be set in a react component so it has access to hooks.
    history.go = async (path) => {
        // TODO: Look if we can move this outside of store?
        // verify login logic
        // try {
        //     const { data } = await verify();
        //     actSetUserInfo(data);
        // } catch (error) {
        //     try {
        //         await login();
        //     } catch (loginErr) {
        //         console.log(loginErr);
        //     }
        // }
        history.push(path);
    };
    const [stRibbonMenu, actSetRibbonMenu] = useState([
        {
            label: t('lbl_dashboard', { defaultValue: 'Dashboard' }),
            icon: 'nav-dashboard',
            isActive: false,
            path: '/dashboard',
            clicked: () => {
                history.go('/dashboard');
            },
        },
        {
            label: t('ribbon_portfolio', { defaultValue: 'Portfolio' }),
            icon: 'nav-portfolio',
            isActive: false,
            path: '/portfolio',
            clicked: () => {
                history.go('/portfolio');
            },
        },
        {
            label: t('ribbon_entities', { defaultValue: 'Add Entities' }),
            icon: 'nav-add',
            isActive: false,
            path: '/entity',
            clicked: () => {
                history.go('/entity');
            },
        },
        {
            label: t('lbl_event_plural', { defaultValue: 'Events' }),
            icon: 'location-marker',
            isActive: false,
            path: '/events',
            clicked: () => {
                history.go('/events');
            },
        },
        {
            label: t('ribbon_support', { defaultValue: 'Support' }),
            icon: 'nav-support',
            isActive: false,
            path: '/support',
            clicked: () => {
                history.go('/support');
            },
        },
        // {
        //     label: t('lbl_Setting', { defaultValue: 'Settings' }),
        //     icon: 'nav-support',
        //     isActive: false,
        //     path: '/setting',
        //     clicked: () => {
        //         history.go('/setting');
        //     },
        // },
    ]);

    useEffect(() => {
        if (stUserInfo?.preferences?.is_disclaimer)
            actSetDisclaimerConfirmed(true);
    }, [stUserInfo]);

    const contextValues = {
        stIsHeaderVisible,
        actSetHeaderVisibility,
        stIsFooterVisible,
        actSetFooterVisibility,
        stFlyout,
        actSetFlyout,
        stUserInfo,
        actSetUserInfo,
        stPanels,
        actSetPanels,
        stRibbonMenu,
        actSetRibbonMenu,
        stEntities,
        actSetEntities,
        stByNameEntities,
        actSetByNameEntities,
        stTypeaheadError,
        actTypeaheadError,
        stSearchByNameError,
        actSetSearchByNameError,
        stWatchlistEditing,
        actSetWatchlistEditing,
        history,
        entityDetails,
        actSetEntityDetails,
        stDisclaimerConfirmed,
        actSetDisclaimerConfirmed,
        actSetDefaultPortfolioFilters,
        stDefaultPortfolioFilters,
        resetPortfolioFilters,
        stfolders,
        actSetFolders,
        stActiveFolder,
        actSetActiveFolder,
        enabledTestingMethods,
        setEnabledTestingMethods,
        stDefaultEventFilters,
        actSetDefaultEventFilters,
        DEFAULT_DATE_RANGE,
    };
    return (
        <Context.Provider value={contextValues}>{children}</Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
