/* eslint-disable indent */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import AnimatedRoute from './AnimatedRoute';
// import PageHome from '../PageHome';
import PageSupport from '../PageSupport';
import useHistoryChange from '../../hooks/useHistoryChange';
import './styles.scss';
import PageEntity from '@pages/PageEntity';
import PageCopyright from '@pages/PageCopyright';
import PagePortfolio from '@pages/PagePortfolio';
import PageDetails from '@pages/PageDetails';
import PageDashboard from '@pages/PageDashboard';
import { useTranslation } from 'react-i18next';
import PageEventWrapper from '@pages/PageEvent/PageEventWrapper';
import PageSingleEvent from '@pages/PageEvent/SingleEvent';

function PageRouter(history) {
    const location = history.location;
    useHistoryChange(location);

    const { t } = useTranslation();

    return (
        <section className="route-section">
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                    {/* <AnimatedRoute path="/" exact slide={30}>
                        <PageHome />
                    </AnimatedRoute> */}
                    <AnimatedRoute path="/dashboard" slide={30}>
                        <PageDashboard />
                    </AnimatedRoute>
                    <AnimatedRoute path="/portfolio" slide={30}>
                        <PagePortfolio />
                    </AnimatedRoute>
                    <AnimatedRoute path="/details/:id/:tab?" slide={30}>
                        <PageDetails
                            twoColPaths={[
                                {
                                    label: t('lbl_my_portfolio', {
                                        defaultValue: 'My Portfolio',
                                    }),
                                    id: 'val_portfolio',
                                    route: '/portfolio',
                                },
                            ]}
                        />
                    </AnimatedRoute>
                    <AnimatedRoute path="/events/:id" slide={30}>
                        <PageSingleEvent
                            twoColPaths={[
                                {
                                    label: t('lbl_event_plural', {
                                        defaultValue: 'Events',
                                    }),
                                    id: 'val_events',
                                    route: '/events',
                                },
                            ]}
                        />
                    </AnimatedRoute>
                    <AnimatedRoute path="/events" slide={30}>
                        {/* <Redirect to="/events/525333275" /> */}
                        <PageEventWrapper />
                    </AnimatedRoute>
                    <AnimatedRoute path="/support" slide={30}>
                        <PageSupport />
                    </AnimatedRoute>
                    <AnimatedRoute path="/entity" slide={30}>
                        <PageEntity />
                    </AnimatedRoute>
                    <AnimatedRoute path="/support" slide={30}>
                        <PageSupport />
                    </AnimatedRoute>
                    <AnimatedRoute path="/copyright" slide={30}>
                        <PageCopyright />
                    </AnimatedRoute>
                    <Redirect to="/dashboard" />
                </Switch>
            </AnimatePresence>
        </section>
    );
}

PageRouter.propTypes = {
    location: PropTypes.shape({
        href: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
        listen: PropTypes.func,
    }).isRequired,
};

export default withRouter(PageRouter);
