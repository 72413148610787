module.exports = {
    SRC_PATH: './src',
    PUBLIC_PATH: './public',
    APP_NAME: 'government',
    REACT_APP_NODE_ENV: 'local',
    PUBLIC_APP_URL: 'http://localhost:8000',
    GMAP_KEY: 'AIzaSyBRJzOZCxRVZ6YCKOR1SRYYXOeGu8f6lpE',
    axios: {
        CLIENT_APP_NAME: 'government',
        errorPage: '/error',
        loginPage: '/login',
        services: {
            article: 'http://localhost:4007/service-article',
            auth: 'http://localhost:4002/service-auth',
            cam: 'http://localhost:7000/service-cam',
            dashboard: 'http://localhost:4010/service-dashboard',
            diagnostic: 'http://localhost:4001/service-diagnostic',
            entity: 'http://localhost:4006/service-entity',
            event: 'http://localhost:4011/service-event',
            onboard: 'http://localhost:4004/service-onboard',
            portfolio: 'http://localhost:4005/service-portfolio',
            rdc: 'http://localhost:4009/service-rdc',
            statistic: 'http://localhost:4008/service-statistic',
            support: 'http://localhost:4000/service-support',
            typeahead: 'http://localhost:4003/service-typeahead',
        },
    },
};
