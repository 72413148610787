import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import { RiskSentimentLabelMapping } from '@pages/PageDetails/defaults';
import { GaEventsMap } from '@pages/PageEvent/ga-events';
import { useEffect, useRef, useState } from 'react';

export const useFilters = (facets = {}, onFiltersChange) => {
    const [filterRiskLevel, setFilterRiskLevel] = useState([]);
    const [filterKeywordSearch, setFilterKeywordSearch] = useState([]);
    const [filterRiskCategory, setFilterRiskCategory] = useState([]);
    const [selectedDates, setSelectedDates] = useState({});
    const [maxDateRange, setMaxDateRange] = useState();
    const [sort, setSort] = useState();
    const filtersInitialized = useRef(false);

    const resetAllFilters = () => {
        setFilterRiskLevel((oldFilterRiskLevels) => {
            return oldFilterRiskLevels.map((riskLevel) => {
                return { ...riskLevel, checked: true };
            });
        });
        setFilterKeywordSearch([]);
        setFilterRiskCategory((oldFilterRiskCategory) => {
            return oldFilterRiskCategory.map((riskCat) => {
                return { ...riskCat, checked: true };
            });
        });
        setSelectedDates({
            startDate: maxDateRange?.startDate,
            endDate: maxDateRange?.endDate,
        });
        onFiltersChange({});
    };

    useEffect(() => {
        if (filtersInitialized.current) return;
        if (!Object.keys(facets).length) return;
        setFilterRiskLevel(
            facets.level?.map(({ label: rowLabel }) => {
                const queryValue = rowLabel;
                rowLabel = rowLabel.toLowerCase();
                return {
                    label: RiskSentimentLabelMapping[rowLabel],
                    id: rowLabel,
                    checked: true,
                    cls: rowLabel,
                    queryValue,
                };
            }) || []
        );
        setFilterRiskCategory(
            facets?.category
                ?.filter((i) => i.label)
                .map(({ label, count }) => {
                    const queryValue = label;
                    return {
                        label: `${label} (${count})`,
                        checked: true,
                        disabled: false,
                        id: label,
                        queryValue,
                    };
                }) || []
        );
        const startDate = DateViewer.getDateText(
            facets?.date_range?.date_start
        );
        const endDate = DateViewer.getDateText(facets?.date_range?.date_end);
        setMaxDateRange({
            startDate,
            endDate,
        });
        if (!selectedDates.startDate || !selectedDates.endDate) {
            setSelectedDates({
                startDate,
                endDate,
            });
        }
        filtersInitialized.current = true;
    }, [JSON.stringify(facets ?? {})]);

    const queryState = useRef({});

    const updateQueryState = (key, value) => {
        if (key === 'sort') {
            queryState.current = {
                ...queryState.current,
                [key]: value,
            };
            GaEventsMap.eventListAction({
                action: 'sort',
                sortBy: queryState.current?.sort,
            });
        } else {
            queryState.current = {
                filters: {
                    ...(queryState.current.filters || {}),
                    [key]: value,
                },
            };
            if (Object.keys(queryState.current?.filters || {}).length) {
                GaEventsMap.filterEngagement({
                    filters: queryState.current.filters,
                });
            }
        }
        onFiltersChange(queryState.current);
    };

    return {
        filterRiskLevel,
        selectedDates,
        filterKeywordSearch,
        filterRiskCategory,
        maxDateRange,
        sort,
        setFilterRiskLevel: (newFilterRiskLevel) => {
            updateQueryState(
                'level',
                newFilterRiskLevel
                    .filter((i) => i.checked)
                    .map((riskLevel) => riskLevel.queryValue)
            );
            setFilterRiskLevel(newFilterRiskLevel);
        },
        setSelectedDates: (newSelectedDates) => {
            updateQueryState('date_range', {
                date_start: newSelectedDates.startDate,
                date_end: newSelectedDates.endDate,
            });
            setSelectedDates(newSelectedDates);
        },
        setFilterKeywordSearch: (newData) => {
            updateQueryState('keyword', newData);
            setFilterKeywordSearch(newData);
        },
        setFilterRiskCategory: (newData) => {
            updateQueryState(
                'category',
                newData
                    .filter((i) => i.checked)
                    .map((riskCat) => riskCat.queryValue)
            );
            setFilterRiskCategory(newData);
        },
        setMaxDateRange: (newData) => {
            setMaxDateRange(newData);
        },
        setSort: (newData) => {
            updateQueryState('sort', newData);
            setSort(newData);
        },
        resetAllFilters,
    };
};
