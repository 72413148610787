module.exports = {
    SRC_PATH: './src',
    PUBLIC_PATH: './public',
    APP_NAME: 'government',
    REACT_APP_NODE_ENV: 'uat',
    PUBLIC_APP_URL: 'https://government-ui.aws.newsedge-uat.com',
    GMAP_KEY: 'AIzaSyBRJzOZCxRVZ6YCKOR1SRYYXOeGu8f6lpE',
    axios: {
        CLIENT_APP_NAME: 'government',
        errorPage: '/error',
        loginPage: '/login',
        services: {
            support: 'https://shared-api.aws.newsedge-uat.com/service-support',
            diagnostic:
                'https://shared-api.aws.newsedge-uat.com/service-diagnostic',
            auth: 'https://shared-api.aws.newsedge-uat.com/service-auth',
            typeahead:
                'https://shared-api.aws.newsedge-uat.com/service-typeahead',
            onboard: 'https://shared-api.aws.newsedge-uat.com/service-onboard',
            portfolio:
                'https://shared-api.aws.newsedge-uat.com/service-portfolio',
            entity: 'https://shared-api.aws.newsedge-uat.com/service-entity',
            article: 'https://shared-api.aws.newsedge-uat.com/service-article',
            statistic:
                'https://shared-api.aws.newsedge-uat.com/service-statistic',
            cam: 'https://government-api.aws.newsedge-uat.com/service-cam',
            rdc: 'https://shared-api.aws.newsedge-uat.com/service-rdc',
            dashboard:
                'https://shared-api.aws.newsedge-uat.com/service-dashboard',
            event: 'https://shared-api.aws.newsedge-uat.com/service-event',
        },
    },
};
