export const GaEventsMap = {
    filterEngagement: (meta = {}) => {
        window.Analytics?.sendEvent?.('events_filter_engagement', {
            ...meta,
        });
    },
    eventListAction: (meta = {}) => {
        window.Analytics?.sendEvent?.('events_list_action', {
            ...meta,
        });
    },
    eventMapAction: (meta = {}) => {
        window.Analytics?.sendEvent?.('events_map_action', {
            ...meta,
        });
    },
    eventSave: (eventId) => {
        window.Analytics?.sendEvent?.('event_save', {
            eventId,
        });
    },
    eventClickThrough: (eventId) => {
        window.Analytics?.sendEvent?.('event_clickthrough', {
            eventId,
        });
    },
};
