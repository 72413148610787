import React, { useEffect, useRef, useState } from 'react';
import { requests } from '../../../requests';
import './styles.scss';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AsyncTaskStatesEnum } from '@bit/modus-moodys.mapulseui._hooks/dist/useAsyncTask';
import usePaginatedRequest from '@bit/modus-moodys.mapulseui._hooks/dist/usePaginatedRequest';
import { Tabs } from '@bit/modus-moodys.mapulseui.tabs-manager';
import { getDefaultTabId, tabNameToIdMap } from './constants';
import Header from './Sections/Header';
import TabNewsArticle from './Tabs/NewsArticle';
import { useTranslation } from 'react-i18next';
import RelGraphLayout from '@bit/modus-moodys.mapulseui.rel-graph-layout';
import {
    RelGraphLabelTexts,
    RelgraphPoleLabelGenerator,
} from '@pages/PageDetails/defaults';

import { DEFAULT_SORT } from './defaults';
import logo from '../../../logo.png';

const PageSingleEvent = ({ cls, id, tag, twoColPaths }) => {
    const _cls = `page page-single-event ${cls || ''}`;
    const { id: eventId } = useParams();
    const history = useHistory();
    const getEventAsync = async (query = {}) => {
        if (!query?.sort) query.sort = DEFAULT_SORT;
        return requests.getEventById({ eventId, ...query });
    };
    const onPageChange = (asyncTask, page) => asyncTask({ page });
    const {
        gotoPage,
        run,
        taskState,
        totalPages,
        taskResponse,
        resetPagination,
    } = usePaginatedRequest(getEventAsync, 'pages', 'page', onPageChange);

    useEffect(() => {
        run({ eventId });
    }, [eventId]); // Trigger first fetch call

    const [eventDetails, setEventDetails] = useState();
    const [entityDetails, setEntityDetails] = useState();

    useEffect(() => {
        if (taskState !== AsyncTaskStatesEnum.SUCCESS) return;
        setEventDetails(taskResponse.event);
        setEntityDetails(taskResponse.data);
    }, [taskState, JSON.stringify(taskResponse)]);

    const { search } = useLocation();
    const defaultTabId = getDefaultTabId(search);
    const [selectedTab, setSelectedTab] = useState(defaultTabId);

    const onTabChange = (newTabId) => {
        setSelectedTab(newTabId);
        const [tab] = Object.entries(tabNameToIdMap).find(
            ([, id]) => id === newTabId
        );
        history.go({
            search: `?tab=${tab}`,
        });
    };

    const onFiltersChange = (newFilters) => {
        run({
            ...newFilters,
        });
        resetPagination();
    };

    const { t } = useTranslation();

    const relgraphKeyLableMap = RelgraphPoleLabelGenerator(t);

    const relGrphApiSnapshot = useRef({});

    const relgraphAPI = async (args = {}) => {
        const data = await requests.entityPole(
            {
                entityId: eventId,
                ...args,
            },
            true
        );
        const oldPol = { ...data.pol };
        delete data.pol;
        const newPol = {};
        for (const key in oldPol) {
            newPol[relgraphKeyLableMap[key]] = oldPol[key];
        }
        data.pole = newPol;
        if (
            !Object.keys(relGrphApiSnapshot.current).includes('hasOverallData')
        ) {
            relGrphApiSnapshot.current.hasOverallData =
                data?.summary?.total > 0;
        }
        data.summary.hasOverallData = relGrphApiSnapshot.current.hasOverallData;
        return data;
    };

    return (
        <div id={id} className={_cls} data-tag={tag}>
            <Header
                defaultTabId={defaultTabId}
                entityDetails={entityDetails}
                twoColPaths={twoColPaths}
                eventDescription={eventDetails?.description}
                onTabChange={onTabChange}
                logoUrl={logo}
            />
            <Tabs selectedTabIndex={selectedTab}>
                <TabNewsArticle
                    gotoPage={gotoPage}
                    articlesResponse={taskResponse}
                    taskState={taskState}
                    totalPages={totalPages}
                    onFiltersChange={onFiltersChange}
                />
                <div className="page-content">
                    <RelGraphLayout
                        action={relgraphAPI}
                        poleEntityData={{
                            ...eventDetails,
                            name: eventDetails?.description,
                            type: 'event',
                        }}
                        labelTexts={RelGraphLabelTexts(
                            t,
                            eventDetails?.id,
                            true
                        )}
                    />
                </div>
            </Tabs>
        </div>
    );
};

export default PageSingleEvent;
