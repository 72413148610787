import { useEffect } from 'react';
import { useAppContext } from '../store';
import logger from '@bit/modus-moodys.mapulseui.logger';
/*
 * onLocationChange handler
 * Dependencies: location, useAppContext (store)
 * handles all logic during a route change.
 */
const useHistoryChange = (location) => {
    const { stRibbonMenu, actSetRibbonMenu } = useAppContext();
    useEffect(() => {
        const handleHistoryChange = async (newLocation) => {
            // set view to top of page.
            setTimeout(() => window.scrollTo(0, 0), 0);
            logger.log(location);
            // set navbar curent view (white highlight)
            const { pathname } = newLocation;
            const rootPath = `/${pathname.split('/')[1]}`;

            const newMenu = stRibbonMenu.map((tab) => {
                const isActive = tab.path === rootPath;
                return { ...tab, isActive };
            });

            actSetRibbonMenu(newMenu);
        };

        handleHistoryChange(location);
    }, [location]);
};

export default useHistoryChange;
