import React, { useMemo } from 'react';
import DashTileTrendingEvents from '@bit/modus-moodys.mapulseui.dash-tile-trending-events';
import { requests } from '@requests';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { useTileState } from './utils';
import {
    EVENT_SCOPES,
    LOCATION_TYPE,
    EVENTS_LABELS,
    OLD_EVENT_SCOPES,
} from '../Helpers/constants';
import GAeventsMap from '@pages/PageDashboard/ga-events';
import { useAppContext } from '@store';

const TrendingEventsTile = ({
    tileData = {},
    t,
    updateTileConfig,
    removeTile,
    editTile,
    ...props
}) => {
    const titleLabel = t('lbl_trending_events', {
        defaultValue: 'Trending Events',
    });
    const { history } = useAppContext();
    const themesFromLabel = t('lbl_events_from', {
        defaultValue: 'Events from:',
    });

    const handleAsyncAction = async () => {
        const selectedInterval = tileState()?.selectedInterval;

        const currentScope = getCurrentScope();
        const selectedLocationRegions = getSelectedLocations();
        const requestQuery = {};

        switch (currentScope) {
            case EVENT_SCOPES.ALL:
                break;
            case EVENT_SCOPES.SPECIFIC:
                requestQuery['filter[region]'] = selectedLocationRegions
                    .filter((x) => x.type === LOCATION_TYPE.REGION)
                    .map((x) => x.id);
                requestQuery['filter[country]'] = selectedLocationRegions
                    .filter((x) => x.type === LOCATION_TYPE.COUNTRY)
                    .map((x) => x.id);
                break;
            default:
                break;
        }

        const response = await requests.trendingEvents({
            interval: selectedInterval,
            size: 10,
            ...requestQuery,
        });

        return {
            labelTexts: {
                noDataProvided: t('lbl_no_new_events', {
                    defaultValue: `There are no new events from the last ${selectedInterval} days`,
                    interval: `${selectedInterval} days`,
                }),
            },
            items: (response ?? []).map((item) => ({
                ...item,
                value: item.num_total,
            })),
            onSelect: (eventId) => {
                if (eventId) {
                    GAeventsMap.eventClickThrough(eventId);
                    history.go(`/events/${eventId}`);
                }
            },
        };
    };

    const labelTexts = {
        title: titleLabel,
        configText: themesFromLabel,
    };

    const onStateUpdate = async (state) => {
        try {
            await updateTileConfig(state);
        } catch (error) {
            logger.error(error);
        }
    };

    const { setTileState, tileState } = useTileState(tileData, onStateUpdate);

    const onUpdateMeta = (_, selectedInterval) => {
        GAeventsMap.editCard(tileData.tileId, {
            scope: tileState().currentScope,
            selectedInterval,
        });
        setTileState({
            selectedInterval,
        });
    };

    const handleEdit = async () => {
        const updatedMeta = await editTile({
            scope: getCurrentScope(),
            selectedLocations: getSelectedLocations(),
        });
        const { scope, selectedLocations } = updatedMeta;
        if (scope) {
            setTileState({
                currentScope: scope,
                locations: selectedLocations,
                label: null,
                id: null,
            });
            return true;
        }
        return false;
    };

    function requiresBackwardCompatibility() {
        return OLD_EVENT_SCOPES.includes(tileState().currentScope);
    }

    //Handle backward compatibilty for event - scope
    // OLD version scope - ['country', 'region']
    // New scope = ['all', 'specific']
    function getCurrentScope() {
        return requiresBackwardCompatibility()
            ? 'specific'
            : tileState().currentScope;
    }

    //Handle backward compatibility for event - data
    // OLD version data - {id: 'United States'} or { id: 'Americas'}
    // New data - [{ type: 'region', id: 'Americas', name: 'Americas' }, { type: 'country', id: 'United States', name: 'United States' }]
    function getSelectedLocations() {
        return requiresBackwardCompatibility()
            ? [
                  {
                      id: tileState().id,
                      label: tileState().id,
                      type: tileState().currentScope,
                  },
              ]
            : tileState().locations;
    }

    function getSelectedLocationLabel() {
        if (getCurrentScope() === EVENT_SCOPES.ALL) return EVENTS_LABELS.ALL;
        const locations = getSelectedLocations();
        return (locations ?? []).map((loc) => loc.id).join(', ');
    }

    /*
        Added memo because appContext stPanels causes re-rendering of the component.
        During addPanel it causes unnecessary rerender
    */
    const memoizedEvents = useMemo(() => {
        return (
            <DashTileTrendingEvents
                {...props}
                labelTexts={labelTexts}
                onUpdateMeta={onUpdateMeta}
                action={handleAsyncAction}
                meta={{
                    source: {
                        scope: getCurrentScope(),
                        data: {
                            label: getSelectedLocationLabel(),
                        },
                    },
                    intervalInDays: tileState().selectedInterval,
                }}
                index={tileData?.index}
                onRemove={removeTile}
                onEdit={handleEdit}
                key={tileData?.uniqueId}
            />
        );
    }, [tileState(), tileData]);

    return memoizedEvents;
};

export default TrendingEventsTile;
