const getSurveyProps = (t) => {
    return {
        utilityProps: {
            title: t(`lbl_app_useful`, {
                app_name: t('app_name_government', {
                    defaultValue: 'NewsEdge Events',
                }),
            }),
            btnLabel: t('lbl_rate'),
            values: {
                minLabel: t('lbl_not_useful'),
                maxLabel: t('lbl_ext_usefull'),
                levels: [
                    { id: 0, label: 0 },
                    { id: 1, label: 1 },
                    { id: 2, label: 2 },
                    { id: 3, label: 3 },
                    { id: 4, label: 4 },
                    { id: 5, label: 5, isInit: true },
                    { id: 6, label: 6 },
                    { id: 7, label: 7 },
                    { id: 8, label: 8 },
                    { id: 9, label: 9 },
                    { id: 10, label: 10 },
                ],
            },
        },
        experienceProps: {
            title: t(`lbl_app_delighted_text`, {
                app_name: t('app_name_government', {
                    defaultValue: 'NewsEdge Events',
                }),
            }),
            btnLabel: t('lbl_rate'),
            values: {
                minLabel: '',
                maxLabel: '',
                levels: [
                    {
                        id: 'extremely-frustrated',
                        label: t('lbl_ext_frustrated'),
                    },
                    { id: 'mildly-frustrated', label: t('lbl_mild_frustated') },
                    { id: 'not-sure', label: t('lbl_not_sure'), isInit: true },
                    { id: 'mildly-delighted', label: t('lbl_mild_delight') },
                    { id: 'extremely-delighted', label: t('lbl_ext_delight') },
                ],
            },
        },
        notesProps: {
            title: t(`lbl_note_title`, {
                app_name: t('app_name_government', {
                    defaultValue: 'NewsEdge Events',
                }),
            }),
            btnLabel: t('lbl_submit'),
        },
    };
};
module.exports = {
    getSurveyProps,
};
