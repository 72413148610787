export const DEFAULT_RANGE = 15;
export const UNIQUE_KEY_LEN = 5;
export const DEFAULT_TILE_WIDTH = 'half';

export const RISK_SCOPES = {
    ALL: '*',
    PORTFOLIO: 'portfolio',
    WATCHLIST: 'watchlist',
};

export const RISK_LABELS = {
    ALL: 'Entire Catalog',
    PORTFOLIO: 'My Portfolio',
    WATCHLIST: 'My Watchlist',
};

export const EVENT_SCOPES = {
    ALL: 'all',
    SPECIFIC: 'specific',
};

export const LOCATION_TYPE = {
    REGION: 'region',
    COUNTRY: 'country',
};

export const EVENTS_LABELS = {
    ALL: 'All regions',
};

export const OLD_EVENT_SCOPES = ['region', 'country'];
