// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react';

// Use Effect that will not run on the first render.
const useDidMount = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, [...deps]);
};

export default useDidMount;
