const getMessageByOnboardResponseId = (id, t) => {
    switch (id) {
        case 'already_in_portfolio':
            return {
                message: t('rsp_duplicate_entity'),
                status: 'error',
            };
        case 'error':
            return {
                message: t('rsp_portfolio_update_error'),
                status: 'error',
            };
        case 'unsupported_entity':
            return {
                message: t('rsp_invalid_entity'),
                status: 'error',
            };
        case 'added_to_portfolio':
            return {
                message: t('rsp_added_to_portfolio'),
                status: 'success',
            };
        case 'queued':
            return {
                message: t('rsp_entity_queued'),
                status: 'success',
            };
        case 'entity_added': {
            return {
                message: t('rsp_entity_onboard_success'),
                status: 'success',
            };
        }
        default:
            return {
                message: t('rsp_default_error'),
                status: 'error',
            };
    }
};

module.exports = {
    getMessageByOnboardResponseId,
};
