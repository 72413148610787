import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TwoColTitle from '@bit/modus-moodys.mapulseui.two-col-title';
import './styles.scss';

const TabSwitcher = ({ cls, id, tag, children, headerLeft, headerRight }) => {
    const _cls = classNames('event-tab-switcher', {
        [cls]: cls,
    });

    return (
        <div className={_cls} id={id} data-tag={tag}>
            <TwoColTitle
                cls="event-tab-switcher__header"
                leftComponent={headerLeft}
                rightComponent={headerRight}
            />
            {children}
        </div>
    );
};

TabSwitcher.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    headerLeft: PropTypes.node,
    headerRight: PropTypes.node,
};

TabSwitcher.defaultProps = {
    cls: 'event-tab-switcher',
    id: 'event-tab-switcher-id',
    tag: 'event-tab-switcher-tag',
};

export default TabSwitcher;
