import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import { requests } from '../../requests';

const DeleteWatchlist = ({ item, hideFromIndex, removePanel }) => {
    const { watchlist } = item.props;
    const { t } = useTranslation();

    const deleteUserWatchlist = async () => {
        requests.deleteUserWatchlist({ id: watchlist.id }).then(() => {
            removePanel('DeleteWatchlist');
        });
    };

    return (
        <div className="manage-watchlists-delete">
            <h1 className="title heading-2" style={{ paddingBottom: '30px' }}>
                {t('lbl_delete', { defaultValue: 'Delete' })}&nbsp;
                {watchlist.label}&nbsp;
                {t('lbl_watchlist', { defaultValue: 'Watchlist' })}?
            </h1>
            <p className="subtext secondary-text">
                {t('lbl_watchlist_delete_confirmation', {
                    name: watchlist.label,
                    defaultValue: `This will erase all entitites saved against ${watchlist.label} watchlist`,
                })}
            </p>
            <SubmitButton
                label={t('lbl_delete', {
                    defaultValue: `Delete`,
                })}
                action={deleteUserWatchlist}
            />
            <button
                type="button"
                className="text-link"
                onClick={() => hideFromIndex(-1)}
            >
                {t('lbl_cancel', {
                    defaultValue: `Cancel`,
                })}
            </button>
        </div>
    );
};

DeleteWatchlist.defaultProps = {
    hideFromIndex: () => {},
    removePanel: () => {},
    item: {},
};

DeleteWatchlist.propTypes = {
    hideFromIndex: PropTypes.func,
    removePanel: PropTypes.func,
    item: PropTypes.instanceOf(Object),
};

export default DeleteWatchlist;
