import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EventRow from '@bit/modus-moodys.mapulseui.event-row';
import './styles.scss';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import { AsyncTaskStatesEnum } from '@bit/modus-moodys.mapulseui._hooks/dist/useAsyncTask';
import { requests } from '@requests';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { useHistory } from 'react-router-dom';
import { ActiveTabIndexEnum } from '@pages/PageEvent/PageEventWrapper';
import { EventsError, NoSavedEvents } from '@pages/PageEvent/Components/Error';
import { shortStateName } from '@pages/PageEvent';
import { GaEventsMap } from '@pages/PageEvent/ga-events';

const ListView = ({
    cls,
    gotoPage,
    paginationResults,
    eventsData,
    activeTab,
    changeActiveTab,
    onPaginationSortUpdate,
}) => {
    const _cls = classNames(cls, 'shared-content-view');
    const history = useHistory();
    const [availableEvents, setAvailableEvents] = useState([]);
    const [eventsIdIndexMap, setEventsIdIndexMap] = useState();
    const { hasError, isLoading, succeded, isIdle } = shortStateName(
        paginationResults?.taskState
    );

    /* 
        Making mapping because it is easy to fetch event by id, 
        getting index of event in availableEvents, changing it's saved state
        Instead of iterating and finding the valid event everytime in EventRow.onAction
    */
    const mapEventsToIdIndexMap = (events) => {
        const freshIdIndexMap = new Map();
        events?.map((event, index) => {
            freshIdIndexMap.set(event.id, { index, isSaved: event.isSaved });
        });
        setEventsIdIndexMap(freshIdIndexMap);
    };

    useEffect(() => {
        if (!Array.isArray(eventsData?.data)) return;
        mapEventsToIdIndexMap(eventsData?.data ?? []);
        setAvailableEvents(eventsData?.data ?? []);
    }, [eventsData?.data]);

    const isPageLoading =
        paginationResults?.taskState === AsyncTaskStatesEnum.PROCESSING;

    const onEventRowSaveAction = async (eventData) => {
        /* 
            Save/Unsave Action
        */

        // Get event data from index
        const { isSaved, index: eventIndex } =
            eventsIdIndexMap?.get(eventData.id) || {};
        const newEventData = {
            ...eventData,
            isSaved: !isSaved,
        };
        // Update event data in availableEvents
        const newEventsData = [...availableEvents];
        newEventsData[eventIndex] = newEventData;

        try {
            setAvailableEvents(newEventsData);
            // Make API Call to update event saved status
            await requests.updateUserEvent(eventData.id, !isSaved);
            // Update event data in eventsIdIndexMap
            eventsIdIndexMap.set(eventData.id, {
                index: eventIndex,
                isSaved: !isSaved,
            });
        } catch (error) {
            // If there is an error in the API, reset the state
            logger.log(error);
            setAvailableEvents(availableEvents);
        }
    };

    const [paginationSort, setPaginationSort] = useState([
        {
            cls: 'label-country',
            id: 'country',
            label: 'Country',
            type: 'ASC',
            selected: false,
        },
        {
            cls: 'label-event-title',
            label: 'Event Title',
            id: null,
            type: 'ASC',
            selected: false,
            disabled: true,
        },
        {
            cls: 'label-date',
            id: 'date',
            label: 'Date',
            type: 'DESC',
            selected: true,
        },
    ]);

    const onPageListFilterChange = (sorts = []) => {
        setPaginationSort(sorts);
        onPaginationSortUpdate(
            sorts.reduce((acc, filter) => {
                if (filter.disabled || !filter.selected) return acc;
                acc[filter.id] = filter?.type?.toLowerCase();
                return acc;
            }, {})
        );
    };

    const EventsPaginatedList = () => {
        return (
            <div className={_cls}>
                <PaginatedList
                    cls="events-list-view"
                    tag="events-list-view-tag"
                    filters={paginationSort}
                    setFilters={onPageListFilterChange}
                    Row={(eventData) => {
                        return (
                            <EventRow
                                key={eventData.id}
                                {...eventData}
                                actionIconType={
                                    eventData.isSaved
                                        ? 'bookmark-saved'
                                        : 'bookmark'
                                }
                                onAction={() => onEventRowSaveAction(eventData)}
                                onClick={() => {
                                    if (eventData.id) {
                                        GaEventsMap.eventClickThrough(
                                            eventData.id
                                        );
                                        history.go(`/events/${eventData.id}`);
                                    }
                                }}
                            />
                        );
                    }}
                    rowData={availableEvents ?? []}
                    onAction={() => {}}
                    pageData={{
                        page: paginationResults?.currentPage,
                        pages: paginationResults?.totalPages,
                        disabled: false,
                    }}
                    setPageData={({ page }) => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        gotoPage(page);
                    }}
                    loading={isPageLoading}
                />
            </div>
        );
    };

    const ComponentToRender = () => {
        const hasEvents = Boolean(availableEvents?.length);

        if (hasError) return <EventsError />;
        if (isLoading || isIdle) return <EventsPaginatedList />;

        if (succeded && hasEvents) return <EventsPaginatedList />;
        else if (activeTab === ActiveTabIndexEnum.MY_SAVED_EVENTS) {
            return (
                <NoSavedEvents
                    onClick={() => {
                        changeActiveTab(ActiveTabIndexEnum.ALL_EVENTS);
                    }}
                />
            );
        } else return <EventsError />;
    };

    return (
        <div className={_cls}>
            <ComponentToRender />
        </div>
    );
};

ListView.propTypes = {
    id: PropTypes.string,
    cls: PropTypes.string,
    tag: PropTypes.string,
    gotoPage: PropTypes.func,
    paginationResults: PropTypes.shape({
        currentPage: PropTypes.number,
        totalPages: PropTypes.number,
        taskState: PropTypes.string,
    }),
    eventsData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
    }),
    activeTab: PropTypes.string,
    changeActiveTab: PropTypes.func,
};

ListView.defaultProps = {
    cls: 'list-view',
    id: 'list-view-id',
    tag: 'list-view-tag',
    eventsData: {
        data: [],
    },
};

export default ListView;
