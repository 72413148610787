import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import EventsView from './Views/EventsView';
import usePaginatedRequest from '@bit/modus-moodys.mapulseui._hooks/dist/usePaginatedRequest';
import { ViewEnum } from './Views/EventsView/constants';
import { requests } from '@requests';
import { useDebouncedCallback } from '@helpers/utils';
import { ActiveTabIndexEnum } from './PageEventWrapper';
import { GaEventsMap } from './ga-events';
import { AsyncTaskStatesEnum } from '@bit/modus-moodys.mapulseui._hooks/dist/useAsyncTask';
import { useEventsFilter } from '@hooks/useEventsFilter';

const INITIAL_STATE = ViewEnum.MAP;

const PageEvent = ({ cls, id, tag, activeTab, changeActiveTab }) => {
    const _cls = `page page-event ${cls || ''}`;

    const { eventFilterParsed, addFilterUpdateListener, eventSort } =
        useEventsFilter();

    // Keeping same filter state for both views
    // const [filterState, setFilterState] = useState(stDefaultEventFilters);

    /* 
        Async Call generator based on view type MAP, LIST
        This has been added because map & list view can have different API hits
    */
    const eventsAsyncCall =
        (view) =>
        async (query = {}) => {
            const requestParams = {
                filter: {
                    ...eventFilterParsed,
                },
                order_by: {
                    ...eventSort,
                },
                ...query,
            };
            if (activeTab === ActiveTabIndexEnum.MY_SAVED_EVENTS) {
                return requests.savedEvents(view, {
                    ...requestParams,
                });
            }
            return requests.allEvents(view, {
                ...requestParams,
            });
        };

    /* 
        Request a new page data from the API
        runner holds the reference for the eventsAsyncCall(...)
    */
    const onPageChange = async (runner, page) => {
        return await runner({ page });
    };

    /* 
        Create a paginated request for MAP view
    */
    const {
        currentPage: mapViewCurrentPage,
        gotoPage: mapViewGotoPage,
        run: mapViewRun,
        taskState: mapViewTaskState,
        totalPages: mapViewTotalPages,
        taskResponse: mapViewTaskResponse,
        resetPaginationState: mapViewResetPagination,
    } = usePaginatedRequest(
        eventsAsyncCall(ViewEnum.MAP),
        'pages',
        'page',
        onPageChange
    );

    /*
        Create a paginated request for LIST view
    */
    let {
        currentPage: listViewCurrentPage,
        gotoPage: listViewGotoPage,
        run: listViewRun,
        taskState: listViewTaskState,
        totalPages: listViewTotalPages,
        resetPaginationState: listViewResetPagination,
        taskResponse: listViewTaskResponse,
    } = usePaginatedRequest(
        eventsAsyncCall(ViewEnum.LIST),
        'pages',
        'page',
        onPageChange
    );

    /*
        Debounce the API call
    */
    const debouncedRun = useDebouncedCallback(async (view, ...args) => {
        if (view === ViewEnum.MAP) {
            await mapViewRun(...args);
        } else if (view === ViewEnum.LIST) {
            await listViewRun(...args);
        }
    }, 500);

    const [eventSubview, setEventSubview] = useState(INITIAL_STATE);

    const onSubViewChange = (viewName) => {
        const viewNameAction = {
            [ViewEnum.MAP]: () => {
                GaEventsMap.eventListAction({
                    action: 'switch_to_map',
                    activeTab,
                });
            },
            [ViewEnum.LIST]: () => {
                GaEventsMap.eventMapAction({
                    action: 'switch_to_list',
                    activeTab,
                });
            },
        };
        viewNameAction[viewName]?.();
        setEventSubview(viewName);
    };

    useEffect(() => {
        setEventSubview(INITIAL_STATE);
        debouncedRun(INITIAL_STATE, {}, { fireImmediate: true });
    }, [activeTab]);

    useEffect(() => {
        listViewResetPagination();
        mapViewResetPagination();
        debouncedRun(eventSubview, {}, { fireImmediate: true });
    }, [eventSubview]);

    useEffect(() => {
        function onFilterChangeListener(newFilters = {}) {
            debouncedRun(eventSubview, {
                filter: {
                    ...newFilters,
                },
            });
        }
        addFilterUpdateListener(onFilterChangeListener);
    }, [eventSubview]);

    useEffect(() => {
        debouncedRun(eventSubview, {});
    }, [eventSort]);

    const getEventViewProps = (eventSubview) => {
        const commonProps = {
            onSubViewChange: onSubViewChange,
            initialView: eventSubview,
            activeTab,
            changeActiveTab,
        };
        if (eventSubview === ViewEnum.MAP) {
            return {
                ...commonProps,
                eventsData: mapViewTaskResponse || {},
                gotoPage: mapViewGotoPage,
                doReload: mapViewRun,
                paginationResults: {
                    currentPage: mapViewCurrentPage,
                    taskState: mapViewTaskState,
                    totalPages: mapViewTotalPages,
                },
            };
        }

        return {
            ...commonProps,
            eventsData: listViewTaskResponse ?? {},
            gotoPage: listViewGotoPage,
            doReload: listViewRun,
            paginationResults: {
                currentPage: listViewCurrentPage,
                taskState: listViewTaskState,
                totalPages: listViewTotalPages,
            },
        };
    };

    return (
        <div className={_cls} id={id} data-tag={tag}>
            <div className="page-content">
                <EventsView {...getEventViewProps(eventSubview)} />
            </div>
        </div>
    );
};

PageEvent.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
    activeTab: PropTypes.string,
    changeActiveTab: PropTypes.func,
};

PageEvent.defaultProps = {
    cls: 'page-event',
    id: 'page-event-id',
    tag: 'page-event-tag',
    activeTab: null,
};

export default PageEvent;

export const SwitchButton = ({ label, isActive, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={classNames('switch-button', {
                isActive: isActive,
            })}
        >
            {label}
        </button>
    );
};

SwitchButton.propTypes = {
    label: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

export const shortStateName = (state) => {
    return {
        isLoading: state === AsyncTaskStatesEnum.PROCESSING,
        isIdle: state === AsyncTaskStatesEnum.IDLE,
        hasError: state === AsyncTaskStatesEnum.ERROR,
        succeded: state === AsyncTaskStatesEnum.SUCCESS,
    };
};
