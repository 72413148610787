import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@bit/modus-moodys.mapulseui.button';
import { getQueryParams } from '../../helpers';
import './styles.scss';
import logger from '@bit/modus-moodys.mapulseui.logger';

// Acts an a faux-html page
const PageAuthError = ({ className }) => {
    const _cls = `page page-auth-error ${className || ''}`;
    const [error, setError] = useState(null);
    const publicUrl = process.env.PUBLIC_APP_URL;

    useEffect(() => {
        const handleQueryCodes = (queryObj) => {
            switch (queryObj.code) {
                case 'auth_error':
                    setError(
                        'Please contact your administrator if clearing your cookies and reloading does not resolve this issue.'
                    );
                    break;
                default:
                    break;
            }
            return null;
        };
        logger.log(getQueryParams);
        const queryObj = getQueryParams(window.location);
        handleQueryCodes(queryObj);
    }, []);

    const handleBack = () => {
        logger.log(publicUrl);
        window.location.href = `${publicUrl}`;
    };

    return (
        <>
            <div className="title-ribbon">Authentication Error</div>
            <div className={_cls}>
                <p>{error}</p>
                <Button label="try again" onClick={handleBack} />
            </div>
        </>
    );
};

PageAuthError.propTypes = {
    className: PropTypes.string,
};

PageAuthError.defaultProps = {
    className: '',
};

export default PageAuthError;
