import React from 'react';
import PropTypes from 'prop-types';
import ProgressLoader from '@bit/modus-moodys.mapulseui.progress-loader';
import './styles.scss';

// Acts an a faux-html page
const PageSplash = ({ className }) => {
    const _cls = `page page-splash ${className || ''}`;

    return (
        <div className={_cls}>
            <div className="content">
                <ProgressLoader cls="loader" />
            </div>
        </div>
    );
};

PageSplash.propTypes = {
    className: PropTypes.string,
};

PageSplash.defaultProps = {
    className: '',
};

export default PageSplash;
