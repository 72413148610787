import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { requests } from '@requests';
import FilterRegionCountrySearch from '@bit/modus-moodys.mapulseui.filter-region-country-search';
import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import SkeletonGradient from '@bit/modus-moodys.mapulseui.skeleton-gradient';
import { Translation } from 'react-i18next';
import './styles.scss';

const SelectLocation = ({ item, hideFromIndex }) => {
    const { scope, selectedLocations } = item.props || {};
    const [locations, setLocations] = useState([]);
    const [selectedLocs, setSelectedLocs] = useState(selectedLocations);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [loading, setLoading] = useState(true);

    function updateSelectedLocations(selectedLocs) {
        setSelectedLocs(selectedLocs);
        setSubmitDisabled(!(selectedLocs ?? []).length > 0);
    }

    function onSubmit() {
        setTimeout(() => {
            hideFromIndex(-1, {
                scope,
                selectedLocations: selectedLocs,
            });
        }, 500);
    }

    const fetchLocations = async () => {
        try {
            setLoading(true);
            const response = await requests.regionsAndCountries();
            setLocations(response);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <Translation>
            {(t) => (
                <div className="manage-widget">
                    <h1 className="title heading-1 header">
                        {t('lbl_select_item', {
                            defaultValue: 'Select {{lbl_region}}',
                            item: t('lbl_region', { defaultValue: 'Region' }),
                        })}
                    </h1>
                    <span className="sub-title heading-9">
                        {t('lbl_select_region_or_country', {
                            defaultValue:
                                'Select regions or countries for this tile',
                        })}
                    </span>
                    <hr className="seperator" />
                    {loading ? (
                        <>
                            <div className="location-filter-gradient">
                                <SkeletonGradient isGradient cls="line" />
                                <SkeletonGradient isGradient cls="line" />
                                <SkeletonGradient isGradient cls="line" />
                            </div>
                        </>
                    ) : locations ? (
                        <div className="location-filter-container">
                            <span className="location-filter-title sub-title heading-9">
                                {t('lbl_enter_region_or_country', {
                                    defaultValue: 'Enter Region/Country',
                                })}
                            </span>
                            <FilterRegionCountrySearch
                                items={locations}
                                selectedItems={selectedLocations}
                                onChange={updateSelectedLocations}
                            />
                            <div className="buttons-wrapper">
                                <SubmitButton
                                    label={t('lbl_save', {
                                        defaultValue: 'Save',
                                    })}
                                    action={onSubmit}
                                    disabled={submitDisabled}
                                />
                            </div>
                        </div>
                    ) : (
                        t('lbl_we_do_not_have_any_for', {
                            defaultValue:
                                'We do not have any data for {{ entityName }} at this time',
                            entityName: 'regions or countries',
                        })
                    )}
                </div>
            )}
        </Translation>
    );
};

SelectLocation.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
};

SelectLocation.defaultProps = {
    addPanel: () => {},
    hideFromIndex: () => {},
    item: {},
};

export default SelectLocation;
