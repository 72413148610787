import Button from '@bit/modus-moodys.mapulseui.button';
import DropdownList from '@bit/modus-moodys.mapulseui.dropdown-list';
import FilterRiskLevel from '@bit/modus-moodys.mapulseui.filter-risk-level';
import FilterTitleAndTooltip from '@bit/modus-moodys.mapulseui.filter-title-and-tooltip';
import FiltersContainer from '@bit/modus-moodys.mapulseui.filters-container';
import { sendFilterTagEvent, sendOpenArticleEvent } from '@helpers/ga-events';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import { useAppContext } from '@store';
import panelProps from 'flows';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import FilterKeywordSearch from '@bit/modus-moodys.mapulseui.filter-keyword-search';

import { AsyncTaskStatesEnum } from '@bit/modus-moodys.mapulseui._hooks/dist/useAsyncTask';
import ArticleRow from '@bit/modus-moodys.mapulseui.article-row';
import {
    getArticlesWithProps,
    handleManageFolder,
    markArticleNotRelevant,
    moveArticleToFolder,
} from '@pages/PageDetails/utils';
import { useParams } from 'react-router-dom';

import ArticleFolderRibbon from '@bit/modus-moodys.mapulseui.article-folder-ribbon';
import { useFilters } from './Hooks/useFilters';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import { DEFAULT_SORT_OPTIONS } from '../../defaults';

const updateArticleToFolder = () => {};
const moveArticleToNotRelavant = () => {};

const TabNewsArticle = ({
    articlesResponse,
    taskState,
    totalPages,
    gotoPage,
    onFiltersChange,
}) => {
    const { id: eventId } = useParams();

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setArticles(articlesResponse?.data);
    }, [articlesResponse?.data]);

    const {
        filterRiskLevel,
        selectedDates,
        filterKeywordSearch,
        filterRiskCategory,
        maxDateRange,
        setFilterRiskLevel,
        setSelectedDates,
        setFilterKeywordSearch,
        setFilterRiskCategory,
        setSort,
        resetAllFilters,
    } = useFilters(articlesResponse?.facets, onFiltersChange);

    const { t } = useTranslation();
    const sortOption = DEFAULT_SORT_OPTIONS(t);

    const onDateChange = (startDate, endDate) => {
        setSelectedDates({ startDate, endDate });
    };

    const {
        stPanels,
        actSetPanels,
        stfolders: folders,
        actSetFolders: setFolders,
        stActiveFolder: activeFolder,
        actSetActiveFolder: setActiveFolder,
        stUserInfo,
    } = useAppContext();
    const { addPanel } = panelProps(stPanels, actSetPanels);
    const openDatePickerPanel = () => {
        addPanel('DatePickerPanel', {
            onDateChange,
            selectedDates,
            maxDateRange,
            initialVisibleMonth: () => {
                if (!selectedDates.endDate) {
                    return DateViewer.getDateMomentObj(new Date());
                }
                const endDate = DateViewer.getDateMomentObj(
                    selectedDates.endDate
                );
                if (!endDate) return DateViewer.getDateMomentObj(new Date());
                return endDate;
            },
        });
    };

    const handleActiveFolderChange = (folder) => {
        setActiveFolder(folder);
        resetAllFilters();
    };

    const openArticleView = (articleId) => {
        const article = articles.find((i) => i.articleId === articleId);
        if (article.url) {
            window.open(article.url, '_blank');
        } else {
            addPanel('ArticleViewPanel', {
                articleId,
                stUserInfo,
                handleManageFolder: () =>
                    handleManageFolder(
                        addPanel,
                        setFolders,
                        handleActiveFolderChange
                    ),
                isEventArticle: true,
            });
        }
        setArticles(
            articles.map((i) =>
                i.articleId === articleId ? { ...i, unread: false } : i
            )
        );
    };

    const getFolders = () => {
        return [
            ...folders,
            { id: 'not_relevant', label: t('lbl_not_relevant'), icon: 'x' },
        ];
    };

    return (
        <>
            <ArticleFolderRibbon
                folders={[...getFolders()]}
                activeFolder={activeFolder}
                onFolderSelect={handleActiveFolderChange}
                onManageFolderClick={() =>
                    handleManageFolder(
                        addPanel,
                        setFolders,
                        handleActiveFolderChange
                    )
                }
                labelTexts={{
                    newsFeed: t('lbl_news_item', { item: t('lbl_feed') }),
                    selectFolder: t('lbl_select_item', {
                        item: t('lbl_folder'),
                    }),
                    manageFolder: t('lbl_manage_item', {
                        item: t('lbl_folder'),
                    }),
                    noFoldersFound: t('lbl_no_folder'),
                    folderSearchPlaceholder: t('lbl_search'),
                }}
            />
            <div className="page-content">
                <div className="tab-news-feed">
                    <div className="top-container w-100">
                        <div className="header-row">
                            <div className="articles-info">
                                {articles && articlesResponse?.total > 0 && (
                                    <React.Fragment>
                                        <span className="heading-14">
                                            {t('lbl_show_article', {
                                                count: articlesResponse?.total,
                                            })}
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="sort-by">
                                <span className="article-sort-list-title secondary-text">
                                    {t('lbl_sort_by')}:
                                </span>
                                <span>
                                    <DropdownList
                                        cls="article-sort-list"
                                        items={sortOption}
                                        onSelect={(sortId) => {
                                            const {
                                                type,
                                                sortCol,
                                                id: optionId,
                                            } = sortOption.find(
                                                (item) => item.id === sortId
                                            );
                                            // TODO: find more elegant way to append this type
                                            if (optionId !== 'sort_new_old') {
                                                setSort({
                                                    [sortCol]: type,
                                                    date_published: 'DESC',
                                                });
                                            } else {
                                                setSort({
                                                    [sortCol]: type,
                                                });
                                            }
                                        }}
                                        initialOption={sortOption.find(
                                            (item) =>
                                                item.id === sortOption[0].id
                                        )}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="page-table-row">
                            <FiltersContainer>
                                <div className="filters-header">
                                    <h4>{t('lbl_filter', { count: 2 })}</h4>
                                    <Button
                                        cls="reset"
                                        isPrimary={false}
                                        label={t('lbl_reset_filter')}
                                        onClick={() => resetAllFilters()}
                                    />
                                </div>
                                <FilterRiskLevel
                                    isResetStateChecked
                                    alignment="horizontal"
                                    title={t('lbl_risk_level')}
                                    items={filterRiskLevel}
                                    onChange={(filters) => {
                                        sendFilterTagEvent();
                                        setFilterRiskLevel(filters);
                                    }}
                                    onClick={(filters) => {
                                        sendFilterTagEvent();
                                        setFilterRiskLevel(filters);
                                    }}
                                    lastOneStanding
                                    lblReset={t('lbl_reset')}
                                    cls="risk-level-filter"
                                />

                                <div className="date-picker-filter">
                                    <FilterTitleAndTooltip
                                        id="entity-details-date-range-filter"
                                        cls={
                                            !selectedDates?.startDate &&
                                            !selectedDates?.endDate
                                                ? 'disabled'
                                                : ''
                                        }
                                        title={t('lbl_date_range')}
                                        lblReset={t('lbl_reset')}
                                        onClick={() =>
                                            onDateChange(
                                                maxDateRange?.startDate,
                                                maxDateRange?.endDate
                                            )
                                        }
                                    />
                                    <div
                                        className={classNames('dates-button', {
                                            default: !(
                                                onDateChange?.start !== null &&
                                                onDateChange?.end !== null
                                            ),
                                        })}
                                        role="button"
                                        tabIndex="0"
                                        onClick={openDatePickerPanel}
                                        onKeyPress={openDatePickerPanel}
                                    >
                                        <SvgLib type="calendar" />
                                        <div>{selectedDates?.startDate}</div>
                                        <div>-</div>
                                        <div>{selectedDates?.endDate}</div>
                                    </div>
                                </div>
                                <div className="filter-keyword-search">
                                    <FilterKeywordSearch
                                        placeholder={t('lbl_enter_keyword')}
                                        keywords={filterKeywordSearch}
                                        title={t('lbl_keyword_search')}
                                        labelTexts={{
                                            keywordsExceeded:
                                                t('lbl_max_len_error'),
                                        }}
                                        lblCount={`${
                                            filterKeywordSearch.length
                                        } ${t('lbl_selected')}`}
                                        onChange={(newData) => {
                                            sendFilterTagEvent();
                                            setFilterKeywordSearch([
                                                ...newData,
                                            ]);
                                        }}
                                        lblReset={t('lbl_reset')}
                                    />
                                </div>
                                <FilterRiskLevel
                                    isResetStateChecked
                                    alignment="vertical"
                                    title={t('lbl_risk_category')}
                                    items={filterRiskCategory}
                                    onChange={(cat) => {
                                        sendFilterTagEvent();
                                        setFilterRiskCategory(cat);
                                    }}
                                    lastOneStanding
                                    lblReset={t('lbl_reset')}
                                    cls="risk-category-filter"
                                    seeAllOptions={{
                                        enabled: true,
                                        minShown: 8,
                                    }}
                                    enableOnly={true}
                                />
                            </FiltersContainer>

                            <div className="articles-container">
                                {totalPages === 0 &&
                                taskState === AsyncTaskStatesEnum.SUCCESS ? (
                                    <div className="details-skeleton-screen">
                                        <div className="cont">
                                            <p className="bold heading-4">
                                                {t('lbl_no_item_found', {
                                                    item: t(
                                                        'lbl_article_plural'
                                                    ),
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <PaginatedList
                                            cls="article-row-table"
                                            Row={ArticleRow}
                                            rowData={[
                                                ...getArticlesWithProps({
                                                    activeFolder,
                                                    articles,
                                                    folders,
                                                    handleManageFolder: () =>
                                                        handleManageFolder(
                                                            addPanel,
                                                            setFolders,
                                                            handleActiveFolderChange
                                                        ),
                                                    t,
                                                }),
                                            ]}
                                            onAction={async (
                                                action,
                                                params
                                            ) => {
                                                switch (action) {
                                                    case 'select':
                                                        sendOpenArticleEvent(
                                                            eventId,
                                                            params.articleId
                                                        );
                                                        openArticleView(
                                                            params.articleId
                                                        );
                                                        break;
                                                    case 'save':
                                                        await moveArticleToFolder(
                                                            params,
                                                            setArticles,
                                                            updateArticleToFolder
                                                        );
                                                        break;
                                                    case 'remove':
                                                        await markArticleNotRelevant(
                                                            params,
                                                            setArticles,
                                                            moveArticleToNotRelavant
                                                        );
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                            loading={
                                                taskState ===
                                                AsyncTaskStatesEnum.PROCESSING
                                            }
                                            pageData={articlesResponse}
                                            setPageData={({ page }) => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                });
                                                gotoPage(page);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TabNewsArticle;
