import React, { useState } from 'react';
import DashTileEntityInfo from '@bit/modus-moodys.mapulseui.dash-tile-entity-info';
import DashTileShortcuts from '@bit/modus-moodys.mapulseui.dash-tile-shortcuts';
import LabelWithButton from '@bit/modus-moodys.mapulseui.label-with-button';
import { useNotifications } from '@bit/modus-moodys.mapulseui.notifications';
import { useTranslation } from 'react-i18next';
import { requests } from '@requests';
import { useAppContext } from '@store';
import panelProps from '../../../flows';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_MAX_SHORTCUTS,
} from '@pages/PagePortfolio/defaults';

const Shortcuts = () => {
    const { stPanels, actSetPanels, history } = useAppContext();
    const [refresh, setRefresh] = useState(0);
    const { addPanel, removePanel } = panelProps(stPanels, actSetPanels);
    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const shortcutsTitle = t('lbl_my_shortcuts', {
        defaultValue: 'My Shortcuts',
    });
    const addEntityLabel = t('lbl_add_entity', {
        defaultValue: 'Add an Entity',
    });
    const addShortcutsLabel = t('lbl_add_shortcut_plural', {
        defaultValue: 'Add shortcuts',
    });
    const handleAddEntityClick = () => {
        removePanel('AddShortcuts');
        history.go('/entity');
    };
    const openAddShortcuts = () => {
        addPanel('AddShortcuts', {
            onRemove: refreshShortCuts,
            onAddEntity: handleAddEntityClick,
        });
    };
    const { shortcutEntity, deleteShortcut } = requests;
    const removeShortcut = async (item) => {
        try {
            if (!item?.entity_id) {
                addNotification['error'](
                    `Attempt to remove entitity failed. No id provided.`
                );
            }
            await deleteShortcut([item.entity_id]);
            refreshShortCuts();
        } catch (e) {
            addNotification[e.target.elements.type.value ?? 'error'](
                e.target.elements.message.value
            );
        }
    };
    const fetchShortCuts = async () => {
        try {
            const data = await shortcutEntity({
                page: 1,
                size: DEFAULT_PAGE_SIZE,
            });
            if (data?.entities)
                return data.entities.map((entity) => ({
                    ...entity,
                    onClick: (entityId) => {
                        history.go(`/details/${entityId}`);
                    },
                }));

            return [];
        } catch (error) {
            return null;
        }
    };

    const refreshShortCuts = () => {
        setRefresh(!refresh);
    };

    const emptyTile = (
        <LabelWithButton
            cls="empty-shortcuts"
            label={t('lbl_empty_shortcuts', {
                defaultValue: "You haven't added any shortcuts yet.",
            })}
            btnLabel={addShortcutsLabel}
            onClick={openAddShortcuts}
        />
    );

    const memoizedDashTileShortcuts = React.useMemo(() => {
        return (
            <DashTileShortcuts
                title={shortcutsTitle}
                button={{ label: addEntityLabel + ' +', id: 'addShortcut' }}
                minWidth={1130}
                sizePerPage={5}
                action={fetchShortCuts}
                cmpChild={DashTileEntityInfo}
                cmpEmpty={emptyTile}
                onButtonClick={openAddShortcuts}
                onRemove={(item, index) => removeShortcut(item, index)}
                onReload={refreshShortCuts}
                maxShortcuts={DEFAULT_MAX_SHORTCUTS}
            />
        );
    }, [refresh]);

    return (
        <div className="dashboard-shortcuts">{memoizedDashTileShortcuts}</div>
    );
};

export default Shortcuts;
