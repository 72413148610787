/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import MapView from '../MapView';
import ComponentSeparator from '@pages/PageEvent/Components/Separator';
import TabSwitcher from '@pages/PageEvent/Components/TabSwitcher';
import { SwitchButton } from '@pages/PageEvent';
import { ViewEnum } from './constants';
import EventsFilterContainer from '@pages/PageEvent/Components/EventsFilterContainer';
import ListView from '../ListView';
import { getFormattedText } from '@helpers/utils';
import { useEventsFilter } from '@hooks/useEventsFilter';

const EventsView = ({
    id,
    cls,
    tag,
    eventsData,
    paginationResults,
    doReload,
    gotoPage,
    onSubViewChange,
    initialView,
    activeTab,
    changeActiveTab,
}) => {
    const {
        setEventFilter,
        getEventFilterByKey,
        updateEventFilterByKey,
        updateEventFilterByKeyGenerator,
        setEventSort,
        maxDateRange,
        setMaxDateRange,
    } = useEventsFilter();

    const { t } = useTranslation();
    const onDateChange = (startDate, endDate) => {
        updateEventFilterByKey('filter.date_range', {
            startDate,
            endDate,
        });
    };

    useEffect(() => {
        const facets = eventsData?.facets || {};

        if (Object.keys(facets).length === 0) return;
        setEventFilter({
            filter: facets,
        });
        setMaxDateRange(facets.date_range);
    }, [JSON.stringify(eventsData?.facets)]);

    const availableRiskCategories =
        getEventFilterByKey('filter.category') || [];

    const availableRegions = getEventFilterByKey('filter.region') || [];

    const availableCountries = getEventFilterByKey('filter.country') || [];

    const [allRegions, setAllRegions] = useState();

    const filterKeywordSearch = getEventFilterByKey('filter.keyword') || [];

    useEffect(() => {
        setAllRegions([...availableRegions, ...availableCountries]);
    }, [JSON.stringify([availableRegions, availableCountries])]);

    const onPaginationSortUpdate = (newSort = {}) => {
        setEventSort(newSort);
    };

    const ActiveView = useMemo(() => {
        const mapView = (
            <MapView
                eventsData={eventsData.data}
                paginationResults={paginationResults}
                doReload={doReload}
                gotoPage={gotoPage}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
            />
        );
        const listView = (
            <ListView
                eventsData={eventsData}
                paginationResults={paginationResults}
                doReload={doReload}
                gotoPage={gotoPage}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
                onPaginationSortUpdate={onPaginationSortUpdate}
            />
        );
        switch (initialView) {
            case ViewEnum.MAP:
                return mapView;
            case ViewEnum.LIST:
                return listView;
            default:
                return mapView;
        }
    }, [initialView, paginationResults, eventsData?.data]);

    const TabHeaderLeft = useMemo(() => {
        if (initialView === ViewEnum.LIST) {
            return (
                <ComponentSeparator>
                    <div className="events-found">
                        <b>{eventsData?.total ?? 0}</b>{' '}
                        {t('lbl_events_found', {
                            defaultValue: 'events found',
                        })}
                    </div>

                    <>
                        <SvgLib type="pin-icon" />
                        <div>
                            {t('lbl_event_plural', { defaultValue: 'Events' })}
                        </div>
                    </>
                </ComponentSeparator>
            );
        } else {
            return (
                <ComponentSeparator>
                    <div className="events-found">
                        {t('lbl_events_showing', {
                            defaultValue: 'Showing',
                        })}{' '}
                        <b>{getFormattedText(eventsData?.data?.length ?? 0)}</b>{' '}
                        {t('lbl_events_total', {
                            defaultValue: 'of {{total}} events',
                            total: getFormattedText(eventsData?.total ?? 0),
                        })}
                    </div>

                    <>
                        <SvgLib type="pin-icon" />
                        <div>
                            {t('lbl_event_plural', { defaultValue: 'Events' })}
                        </div>
                    </>
                </ComponentSeparator>
            );
        }
    }, [eventsData?.total]);
    const TabHeaderRight = useMemo(() => {
        return (
            <>
                <SwitchButton
                    label="Map"
                    isActive={initialView === ViewEnum.MAP}
                    onClick={() => onSubViewChange(ViewEnum.MAP)}
                />
                <SwitchButton
                    label="List"
                    isActive={initialView === ViewEnum.LIST}
                    onClick={() => onSubViewChange(ViewEnum.LIST)}
                />
            </>
        );
    }, [initialView]);

    return (
        <div id={id} className={cls} tag={tag}>
            <TabSwitcher
                headerLeft={TabHeaderLeft}
                headerRight={TabHeaderRight}
            ></TabSwitcher>
            <div className="all-events-view__body">
                <EventsFilterContainer
                    maxDateRange={maxDateRange}
                    setMaxDateRange={setMaxDateRange}
                    selectedDates={maxDateRange}
                    onDateChange={onDateChange}
                    filterKeywordSearch={filterKeywordSearch}
                    setFilterKeywordSearch={updateEventFilterByKeyGenerator(
                        'filter.keyword'
                    )}
                    availableRiskCategories={availableRiskCategories}
                    setAvailableRiskCategories={updateEventFilterByKeyGenerator(
                        'filter.category'
                    )}
                    availableRegions={availableRegions}
                    setAvailableRegions={updateEventFilterByKeyGenerator(
                        'filter.region'
                    )}
                    availableCountries={availableCountries}
                    setAvailableCountries={updateEventFilterByKeyGenerator(
                        'filter.country'
                    )}
                    allRegions={allRegions}
                />
                {ActiveView}
            </div>
        </div>
    );
};

EventsView.defaultProps = {
    cls: 'all-events-view',
    id: 'all-events-view-id',
    tag: 'all-events-view-tag',
};

EventsView.propTypes = {
    id: PropTypes.string,
    cls: PropTypes.string,
    tag: PropTypes.string,
    eventsData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
    }),
    gotoPage: PropTypes.func,
    doReload: PropTypes.func,
    activeTab: PropTypes.string,
    changeActiveTab: PropTypes.func,
    paginationResults: PropTypes.shape({
        currentPage: PropTypes.number,
        taskState: PropTypes.string,
        totalPages: PropTypes.number,
    }),
    onSubViewChange: PropTypes.func,
    initialView: PropTypes.oneOf(Object.keys(ViewEnum)),
};

export default EventsView;
