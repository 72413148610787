import FiltersContainer from '@bit/modus-moodys.mapulseui.filters-container';
import Button from '@bit/modus-moodys.mapulseui.button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterTitleAndTooltip from '@bit/modus-moodys.mapulseui.filter-title-and-tooltip';
import classNames from 'classnames';
import { useAppContext } from '@store';
import panelProps from 'flows';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import PropTypes from 'prop-types';
import FilterKeywordSearch from '@bit/modus-moodys.mapulseui.filter-keyword-search';
import FilterRiskLevel from '@bit/modus-moodys.mapulseui.filter-risk-level';
import FilterRegionCountrySearch from '@bit/modus-moodys.mapulseui.filter-region-country-search';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import { useEventsFilter } from '@hooks/useEventsFilter';
import { sendFilterTagEvent } from '@helpers/ga-events';

const isResetStateChecked = {
    riskCategory: true,
};

const EventsFilterContainer = ({
    selectedDates,
    onDateChange,
    maxDateRange,
    filterKeywordSearch,
    setFilterKeywordSearch,
    availableRiskCategories,
    setAvailableRiskCategories,
    availableRegions,
    setAvailableRegions,
    availableCountries,
    setAvailableCountries,
    allRegions,
}) => {
    const { t } = useTranslation();
    const { stPanels, actSetPanels, DEFAULT_DATE_RANGE } = useAppContext();

    const { addPanel } = panelProps(stPanels, actSetPanels);

    const openDatePickerPanel = () => {
        // @TODO: Add initial date range to the panel
        addPanel('DatePickerPanel', {
            onDateChange,
            selectedDates,
            maxDateRange,
            initialVisibleMonth: () => {
                if (!selectedDates.endDate) {
                    return DateViewer.getDateMomentObj(new Date());
                }
                const endDate = DateViewer.getDateMomentObj(
                    selectedDates.endDate
                );
                if (!endDate) return DateViewer.getDateMomentObj(new Date());
                return endDate;
            },
        });
    };

    const filterStateMapping = {
        availableRiskCategories,
        availableRegions,
        availableCountries,
    };

    const getSelectedFilterCount = (filterType) => {
        const filterState = filterStateMapping[`available${filterType}`] || [];
        const selectedFilters = filterState.filter((filter) => filter.checked);
        return selectedFilters.length;
    };

    const { updateEventFilterByKey } = useEventsFilter();

    const selectedIds = [...availableRegions, ...availableCountries].filter(
        (item) => item.checked
    );

    const handleRegionsReset = () => {
        updateEventFilterByKey('filter.regionSelectedIds', []);
        updateRegionCountriesState([]);
    };

    const updateRegionCountriesState = (selected) => {
        const updatedRegions = availableRegions.map((item) => {
            return {
                ...item,
                checked: selected.includes(item.id),
            };
        });
        const updatedCountries = availableCountries.map((item) => {
            return {
                ...item,
                checked: selected.includes(item.id),
            };
        });

        setAvailableRegions(updatedRegions);
        setAvailableCountries(updatedCountries);
    };

    const transformSelectedLocations = (selected) => {
        const selectedIds = selected.map((loc) => loc.id);
        updateRegionCountriesState(selectedIds);
    };

    const clearAllFilters = () => {
        setAvailableRiskCategories(
            availableRiskCategories.map((riskCategory) => ({
                ...riskCategory,
                checked: isResetStateChecked.riskCategory,
            }))
        );
        handleRegionsReset();
        onDateChange(
            DateViewer.getDateText(DEFAULT_DATE_RANGE.date_start),
            DateViewer.getDateText(DEFAULT_DATE_RANGE.date_end)
        );
    };

    const FilterHeader = () => {
        return (
            <div className="filter-header">
                <div className="filter-header__title">
                    {t('lbl_filter_plural', { defaultValue: 'Filters' })}
                </div>
                <Button
                    isPrimary={false}
                    label={t('lbl_clear_all_filters', {
                        defaultValue: 'Clear all filters',
                    })}
                    onClick={clearAllFilters}
                />
            </div>
        );
    };

    return (
        <FiltersContainer>
            <FilterHeader />
            <div className="date-picker-filter">
                <FilterTitleAndTooltip
                    id="entity-details-date-range-filter"
                    cls={
                        !selectedDates.startDate && !selectedDates.endDate
                            ? 'disabled'
                            : ''
                    }
                    title={t('lbl_date_range')}
                    lblReset={t('lbl_clear', { defaultValue: 'Clear' })}
                    onClick={() =>
                        onDateChange(
                            DateViewer.getDateText(
                                DEFAULT_DATE_RANGE.date_start
                            ),
                            DateViewer.getDateText(DEFAULT_DATE_RANGE.date_end)
                        )
                    }
                />
                <div
                    className={classNames('dates-button', {
                        default: !(
                            selectedDates.startDate !== null &&
                            selectedDates.endDate !== null
                        ),
                    })}
                    role="button"
                    tabIndex="0"
                    onClick={openDatePickerPanel}
                    onKeyPress={openDatePickerPanel}
                >
                    <SvgLib type="calendar" />
                    <div>{selectedDates.startDate}</div>
                    <div>-</div>
                    <div>{selectedDates.endDate}</div>
                </div>
            </div>
            <div className="filter-keyword-search">
                <FilterKeywordSearch
                    placeholder={t('lbl_enter_keyword')}
                    keywords={filterKeywordSearch}
                    title={t('lbl_keyword_search')}
                    labelTexts={{
                        keywordsExceeded: t('lbl_max_len_error'),
                    }}
                    lblCount={`${filterKeywordSearch.length} ${t(
                        'lbl_selected'
                    )}`}
                    onChange={(newData) => {
                        sendFilterTagEvent();
                        setFilterKeywordSearch([...newData]);
                    }}
                    lblReset={t('lbl_reset')}
                />
            </div>
            <FilterRiskLevel
                isResetStateChecked={isResetStateChecked.riskCategory}
                alignment="vertical"
                title={t('lbl_risk_category', {
                    defaultValue: 'Risk Category',
                })}
                items={availableRiskCategories}
                onChange={setAvailableRiskCategories}
                lastOneStanding
                lblReset={t('lbl_reset')}
                lblCount={`${getSelectedFilterCount('RiskCategories')} ${t(
                    'lbl_selected',
                    { defaultValue: 'selected' }
                )}`}
                seeAllOptions={{
                    enabled: true,
                    minShown: 8,
                }}
                enableOnly={true}
            />
            <div className="filter-by-region-section">
                <FilterTitleAndTooltip
                    title={`${t('lbl_region', {
                        defaultValue: 'Region',
                    })} / ${t('lbl_country', {
                        defaultValue: 'Country',
                    })}`}
                    lblCount={`${
                        getSelectedFilterCount('Regions') +
                        getSelectedFilterCount('Countries')
                    } ${t('lbl_selected', { defaultValue: 'selected' })}`}
                    lblReset={t('lbl_clear', { defaultValue: 'Clear' })}
                    onClick={handleRegionsReset}
                    cls={'filter-title-section'}
                />
                <FilterRegionCountrySearch
                    items={allRegions}
                    onChange={transformSelectedLocations}
                    selectedItems={selectedIds}
                />
            </div>
        </FiltersContainer>
    );
};

EventsFilterContainer.propTypes = {
    selectedDates: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }).isRequired,
    maxDateRange: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }).isRequired,
    availableRiskCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableRegions: PropTypes.arrayOf(PropTypes.object).isRequired,
    filterKeywordSearch: PropTypes.arrayOf(PropTypes.string).isRequired,
    onDateChange: PropTypes.func.isRequired,
    setAvailableRiskCategories: PropTypes.func.isRequired,
    setAvailableRegions: PropTypes.func.isRequired,
    setFilterKeywordSearch: PropTypes.func.isRequired,
};

export default EventsFilterContainer;
