import React from 'react';
import DashErrorState from '@bit/modus-moodys.mapulseui.dash-error-state';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

export const EventsError = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="centered-container">
            <DashErrorState
                message={t(
                    'lbl_oops_something_went_wrong_please_try_again_later',
                    {
                        defaultValue:
                            'lbl_oops_something_went_wrong_please_try_again_later.',
                    }
                )}
                btnLabel={t('lbl_contact_support', {
                    defaultValue: 'Contact support',
                })}
                onClick={() => {
                    history.go('/support');
                }}
            />
        </div>
    );
};

export const NoSavedEvents = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <div className="centered-container">
            <DashErrorState
                message={t('lbl_no_saved_events_error', {
                    defaultValue:
                        'You have no saved events, please save some now.',
                })}
                btnLabel={t('lbl_save_events', 'Save Events')}
                onClick={onClick}
            />
        </div>
    );
};

NoSavedEvents.propTypes = {
    onClick: PropTypes.func,
};
