import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const ComponentSeparator = ({ cls, id, tag, children }) => {
    const classes = classNames('component-separator', {
        [cls]: cls,
    });

    const SeparatorLine = () => <div className="separator-line">|</div>;

    const childrenArray = Array.isArray(children) ? children : [children];

    return (
        <div className={classes} id={id} data-tag={tag}>
            {childrenArray.map((child, index) => {
                return (
                    <React.Fragment key={index}>
                        {child}
                        {index !== childrenArray.length - 1 ? (
                            <SeparatorLine />
                        ) : (
                            <></>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

ComponentSeparator.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

ComponentSeparator.defaultProps = {
    cls: 'component-separator',
    id: 'component-separator-id',
    tag: 'component-separator-tag',
};

export default ComponentSeparator;
