import FolderListRow from '@bit/modus-moodys.mapulseui.folder-list-row';
import InputTextWithIcon from '@bit/modus-moodys.mapulseui.input-text-with-icon';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import { Translation, useTranslation } from 'react-i18next';
import TabPanel from '@bit/modus-moodys.mapulseui.tab-panel';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { requests } from '../../requests';
import logger from '@bit/modus-moodys.mapulseui.logger';

// eslint-disable-next-line react/prop-types
const EditRemoveFolder = ({ item }) => {
    const [folderList, setFolderList] = useState([]);
    const [keyword, setKeyword] = useState(null);
    const { updateFolderItemList } = item.props || {};
    const [loading, setLoading] = useState();
    const [pageData, setPageData] = useState({
        page: 1,
        pages: 2,
        disabled: false,
    });
    const getFolderList = async (_keyword = keyword) => {
        setLoading(true);
        try {
            const response = await requests.searchFolderByName({
                keyword: _keyword && _keyword.length ? _keyword : undefined,
                page: pageData.page,
            });
            if (
                response?.data?.folders &&
                Array.isArray(response?.data?.folders)
            )
                setFolderList([...(response?.data?.folders || [])]);
            if (typeof response?.data?.total === typeof 0) {
                setPageData((e) => ({
                    ...e,
                    disabled: false,
                    pages: Math.max(Math.ceil(response?.data?.total / 20), 1),
                }));
            }
        } catch (err) {
            logger.log('Error while fetching folder list => ', err);
        }
        setLoading(false);
    };
    const handleKeywordChange = (searchTerm) => {
        if (searchTerm.length === 0) {
            setKeyword(searchTerm);
            if (pageData.page === 1) getFolderList(searchTerm);
            else setPageData((e) => ({ ...e, page: 1 }));
        }
    };

    const updateKeyword = (searchTerm) => {
        setKeyword(searchTerm);
        if (pageData.page === 1) getFolderList(searchTerm);
        else setPageData((e) => ({ ...e, page: 1 }));
    };

    const handleSave = async ({ id, label }) => {
        try {
            await requests.updateFolder({ label, id });
            setFolderList([
                ...folderList.map((i) => (i.id === id ? { id, label } : i)),
            ]);
            updateFolderItemList('update', { id, label });
        } catch (err) {
            logger.log({ err });
        }
    };

    const handleRemove = async ({ id, reason }) => {
        try {
            await requests.deleteFolder({
                id,
                deleteArticles: reason === 'permanent-delete',
            });
            setFolderList([...folderList.filter((i) => i.id !== id)]);
            updateFolderItemList('delete', { id });
        } catch (err) {
            logger.log({ err });
        }
    };

    useEffect(() => {
        getFolderList();
    }, [pageData.page]);
    const { t: tt } = useTranslation();
    const getRowData = () => {
        return folderList.map((folder) => ({
            onSave: handleSave,
            onRemove: handleRemove,
            folder,
            id: `${folder.id}`,
            labelTexts: {
                placeholder: tt('lbl_enter_item', { item: tt('lbl_folder') }),
                saveBtn: tt('lbl_save'),
                removeBtn: `${tt('lbl_remove', {
                    defaultValue: 'Remove',
                })}...`,
                cancel: tt('lbl_cancel'),
            },
            deletePopperProps: {
                labelTexts: {
                    submitLabel: tt('lbl_okay'),
                    skipLabel: tt('lbl_cancel'),
                    formTitle: tt('lbl_delete_folder_title'),
                },
                options: [
                    {
                        value: 'move-to-news-feed',
                        label: tt('lbl_move_to_newsfeed'),
                    },
                    {
                        value: 'permanent-delete',
                        label: tt('lbl_permanent_article_delete'),
                    },
                ],
            },
        }));
    };
    return (
        <Translation>
            {(t) => (
                <TabPanel cls="manage-folder-edit-remove">
                    <InputTextWithIcon
                        placeholder={t('lbl_search_item', {
                            item: t('lbl_folder_plural'),
                        })}
                        submit={updateKeyword}
                        valueChanged={handleKeywordChange}
                    />
                    <div className="folder-list">
                        <PaginatedList
                            cls=""
                            id="folder-row-table"
                            tag="folder-row-table"
                            Row={FolderListRow}
                            rowData={[...getRowData()]}
                            onAction={() => {}}
                            pageData={pageData}
                            setPageData={setPageData}
                            loading={loading}
                        />
                    </div>
                </TabPanel>
            )}
        </Translation>
    );
};

EditRemoveFolder.defaultProps = {
    item: {},
};

EditRemoveFolder.propTypes = {
    item: PropTypes.instanceOf(Object),
};

export default EditRemoveFolder;
