import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import DashTileSavedEvents from '@bit/modus-moodys.mapulseui.dash-tile-saved-events';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { useTileState } from './utils';
import dayjs from 'dayjs';
import {
    EVENT_SCOPES,
    LOCATION_TYPE,
    EVENTS_LABELS,
} from '../Helpers/constants';
import { requests } from '../../../../requests';
import GAeventsMap from '@pages/PageDashboard/ga-events';
import config from '../../../../config';

const SavedEventsTile = ({
    tileData = {},
    t,
    updateTileConfig,
    removeTile,
    editTile,
    ...props
}) => {
    const history = useHistory();
    const titleLabel = t('lbl_saved_events', {
        defaultValue: 'Saved Events',
    });

    const eventsFromLabel = t('lbl_events_from', {
        defaultValue: 'Events from:',
    });

    const calculateDateRange = (interval) => {
        if (!interval) {
            return {};
        }
        return {
            date_range: {
                date_start: dayjs().subtract(interval, 'day').format(),
                date_end: dayjs().format(),
            },
        };
    };

    const handleAsyncAction = async () => {
        const selectedInterval = tileState()?.selectedInterval;

        const currentScope = tileState().currentScope;
        const selectedLocationRegions = tileState().locations;
        const requestQuery = {};

        switch (currentScope) {
            case EVENT_SCOPES.ALL:
                requestQuery.filter = {
                    ...calculateDateRange(selectedInterval),
                };
                break;
            case EVENT_SCOPES.SPECIFIC:
                requestQuery.filter = {
                    region: selectedLocationRegions
                        .filter(
                            (selectedLocation) =>
                                selectedLocation.type === LOCATION_TYPE.REGION
                        )
                        .map((selectedRegion) => selectedRegion.id),
                    country: selectedLocationRegions
                        .filter(
                            (selectedLocation) =>
                                selectedLocation.type === LOCATION_TYPE.COUNTRY
                        )
                        .map((selectedCountry) => selectedCountry.id),
                    ...calculateDateRange(selectedInterval),
                };
                break;
            default:
                break;
        }

        const response = await requests.savedEvents('MAP', {
            ...(requestQuery ?? {}),
        });

        return {
            labelTexts: {
                noDataProvided: t('lbl_no_new_events', {
                    defaultValue: `There are no new events from the last ${selectedInterval} days`,
                    interval: `${selectedInterval} days`,
                }),
            },
            apiKey: config.GMAP_KEY,
            events: response.data,
            onEventClick: (eventId) => {
                GAeventsMap.eventClickThrough(eventId);
                history.go(`/events/${eventId}`);
            },
        };
    };

    const labelTexts = {
        title: titleLabel,
        configText: eventsFromLabel,
    };

    const onStateUpdate = async (state) => {
        try {
            await updateTileConfig(state);
        } catch (error) {
            logger.error(error);
        }
    };

    const { setTileState, tileState } = useTileState(tileData, onStateUpdate);

    const onUpdateMeta = (_, selectedInterval) => {
        GAeventsMap.editCard(tileData.tileId, {
            scope: tileState().currentScope,
            selectedInterval,
        });
        setTileState({
            selectedInterval,
        });
    };

    const handleEdit = async () => {
        logger.log('handle edit');
        const updatedMeta = await editTile({
            scope: tileState().currentScope,
            selectedLocations: tileState().locations,
        });
        const { scope, selectedLocations } = updatedMeta;
        if (scope) {
            setTileState({
                currentScope: scope,
                locations: selectedLocations,
            });
            return true;
        }
        return false;
    };

    function getSelectedLocationLabel() {
        if (tileState().currentScope === EVENT_SCOPES.ALL)
            return EVENTS_LABELS.ALL;
        const locations = tileState().locations;

        return (locations ?? []).map((loc) => loc.id).join(', ');
    }

    /*
        Added memo because appContext stPanels causes re-rendering of the component.
        During addPanel it causes unnecessary rerender
    */
    const memoizedEvents = useMemo(() => {
        return (
            <DashTileSavedEvents
                {...props}
                labelTexts={labelTexts}
                onUpdateMeta={onUpdateMeta}
                action={handleAsyncAction}
                meta={{
                    source: {
                        scope: tileState().currentScope,
                        data: {
                            label: getSelectedLocationLabel(),
                        },
                    },
                    intervalInDays: tileState().selectedInterval,
                }}
                index={tileData?.index}
                onRemove={removeTile}
                onEdit={handleEdit}
                key={tileData?.uniqueId}
            />
        );
    }, [tileState(), tileData]);

    return memoizedEvents;
};

export default SavedEventsTile;
