import getQueryParams from '@helpers/getQueryParams';
import { useEffect, useState } from 'react';
import { requests } from '@requests';
import Analytics from '@bit/modus-moodys.mapulseui.analytics';
import { useAppContext } from '../store';
import logger from '@bit/modus-moodys.mapulseui.logger';

export default () => {
    const [authError, setAuthError] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const { actSetUserInfo } = useAppContext();

    const query = getQueryParams(window.location);

    useEffect(() => {
        switch (query.code) {
            case 'auth_error':
                setAuthError(true);
                break;
            default:
                break;
        }
    }, [query]);

    const handleVerification = async () => {
        try {
            if (query.code === 'auth_error') return;
            const { data } = await requests.verify();
            actSetUserInfo(data);
            setAuthenticated(true);
            const AnalyticsInstance = new Analytics({
                user_id: data?.user_id ?? 'test-user',
                env: process.env.REACT_APP_NODE_ENV ?? 'local',
                app_name: process.env.APP_NAME ?? 'government',
                email: data?.email ?? 'test-user@test.com',
            });

            AnalyticsInstance.sendEvent('login_success');

            window.Analytics = AnalyticsInstance;
        } catch (error) {
            try {
                await requests.login();
            } catch (loginErr) {
                logger.log(loginErr);
            }
        }
    };

    useEffect(() => {
        handleVerification();
    }, []);

    return {
        authError,
        authenticated,
    };
};
