import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import './styles.scss';

const PageCopyright = ({ className }) => {
    const _cls = `page page-copyright ${className || ''}`;
    const year = dayjs().year();
    return (
        <>
            <div className={_cls}>
                <div className="copyright">
                    <p>
                        © {year} Moody&#39;s Corporation, Moody&#39;s Investors
                        Service, Inc., Moody&#39;s Analytics, Inc. and/or their
                        licensors and affiliates (collectively, “MOODY&#39;S”).
                        All rights reserved.
                    </p>

                    <p>
                        CREDIT RATINGS ISSUED BY MOODY&#39;S CREDIT RATINGS
                        AFFILIATES ARE THEIR CURRENT OPINIONS OF THE RELATIVE
                        FUTURE CREDIT RISK OF ENTITIES, CREDIT COMMITMENTS, OR
                        DEBT OR DEBT-LIKE SECURITIES, AND MATERIALS, PRODUCTS,
                        SERVICES AND INFORMATION PUBLISHED BY MOODY&#39;S
                        (COLLECTIVELY, “PUBLICATIONS”) MAY INCLUDE SUCH CURRENT
                        OPINIONS. MOODY&#39;S DEFINES CREDIT RISK AS THE RISK
                        THAT AN ENTITY MAY NOT MEET ITS CONTRACTUAL FINANCIAL
                        OBLIGATIONS AS THEY COME DUE AND ANY ESTIMATED FINANCIAL
                        LOSS IN THE EVENT OF DEFAULT OR IMPAIRMENT. SEE
                        APPLICABLE MOODY&#39;S RATING SYMBOLS AND DEFINITIONS
                        PUBLICATION FOR INFORMATION ON THE TYPES OF CONTRACTUAL
                        FINANCIAL OBLIGATIONS ADDRESSED BY MOODY&#39;S CREDIT
                        RATINGS. CREDIT RATINGS DO NOT ADDRESS ANY OTHER RISK,
                        INCLUDING BUT NOT LIMITED TO: LIQUIDITY RISK, MARKET
                        VALUE RISK, OR PRICE VOLATILITY. CREDIT RATINGS,
                        NON-CREDIT ASSESSMENTS (“ASSESSMENTS”), AND OTHER
                        OPINIONS INCLUDED IN MOODY&#39;S PUBLICATIONS ARE NOT
                        STATEMENTS OF CURRENT OR HISTORICAL FACT. MOODY&#39;S
                        PUBLICATIONS MAY ALSO INCLUDE QUANTITATIVE MODEL-BASED
                        ESTIMATES OF CREDIT RISK AND RELATED OPINIONS OR
                        COMMENTARY PUBLISHED BY MOODY&#39;S ANALYTICS, INC.
                        AND/OR ITS AFFILIATES. MOODY&#39;S CREDIT RATINGS,
                        ASSESSMENTS, OTHER OPINIONS AND PUBLICATIONS DO NOT
                        CONSTITUTE OR PROVIDE INVESTMENT OR FINANCIAL ADVICE,
                        AND MOODY&#39;S CREDIT RATINGS, ASSESSMENTS, OTHER
                        OPINIONS AND PUBLICATIONS ARE NOT AND DO NOT PROVIDE
                        RECOMMENDATIONS TO PURCHASE, SELL, OR HOLD PARTICULAR
                        SECURITIES. MOODY&#39;S CREDIT RATINGS, ASSESSMENTS,
                        OTHER OPINIONS AND PUBLICATIONS DO NOT COMMENT ON THE
                        SUITABILITY OF AN INVESTMENT FOR ANY PARTICULAR
                        INVESTOR. MOODY&#39;S ISSUES ITS CREDIT RATINGS,
                        ASSESSMENTS AND OTHER OPINIONS AND PUBLISHES ITS
                        PUBLICATIONS WITH THE EXPECTATION AND UNDERSTANDING THAT
                        EACH INVESTOR WILL, WITH DUE CARE, MAKE ITS OWN STUDY
                        AND EVALUATION OF EACH SECURITY THAT IS UNDER
                        CONSIDERATION FOR PURCHASE, HOLDING, OR SALE.
                    </p>

                    <p>
                        MOODY&#39;S CREDIT RATINGS, ASSESSMENTS, OTHER OPINIONS,
                        AND PUBLICATIONS ARE NOT INTENDED FOR USE BY RETAIL
                        INVESTORS AND IT WOULD BE RECKLESS AND INAPPROPRIATE FOR
                        RETAIL INVESTORS TO USE MOODY&#39;S CREDIT RATINGS,
                        ASSESSMENTS, OTHER OPINIONS OR PUBLICATIONS WHEN MAKING
                        AN INVESTMENT DECISION. IF IN DOUBT YOU SHOULD CONTACT
                        YOUR FINANCIAL OR OTHER PROFESSIONAL ADVISER.
                    </p>

                    <p>
                        ALL INFORMATION CONTAINED HEREIN IS PROTECTED BY LAW,
                        INCLUDING BUT NOT LIMITED TO, COPYRIGHT LAW, AND NONE OF
                        SUCH INFORMATION MAY BE COPIED OR OTHERWISE REPRODUCED,
                        REPACKAGED, FURTHER TRANSMITTED, TRANSFERRED,
                        DISSEMINATED, REDISTRIBUTED OR RESOLD, OR STORED FOR
                        SUBSEQUENT USE FOR ANY SUCH PURPOSE, IN WHOLE OR IN
                        PART, IN ANY FORM OR MANNER OR BY ANY MEANS WHATSOEVER,
                        BY ANY PERSON WITHOUT MOODY&#39;S PRIOR WRITTEN CONSENT.
                    </p>

                    <p>
                        MOODY&#39;S CREDIT RATINGS, ASSESSMENTS, OTHER OPINIONS
                        AND PUBLICATIONS ARE NOT INTENDED FOR USE BY ANY PERSON
                        AS A BENCHMARK AS THAT TERM IS DEFINED FOR REGULATORY
                        PURPOSES AND MUST NOT BE USED IN ANY WAY THAT COULD
                        RESULT IN THEM BEING CONSIDERED A BENCHMARK.
                    </p>

                    <p>
                        All information contained herein is obtained by
                        MOODY&#39;S from sources believed by it to be accurate
                        and reliable. Because of the possibility of human or
                        mechanical error as well as other factors, however, all
                        information contained herein is provided “AS IS” without
                        warranty of any kind. MOODY&#39;S adopts all necessary
                        measures so that the information it uses in assigning a
                        credit rating is of sufficient quality and from sources
                        MOODY&#39;S considers to be reliable including, when
                        appropriate, independent third-party sources. However,
                        MOODY&#39;S is not an auditor and cannot in every
                        instance independently verify or validate information
                        received in the credit rating process or in preparing
                        its Publications.
                    </p>

                    <p>
                        To the extent permitted by law, MOODY&#39;S and its
                        directors, officers, employees, agents, representatives,
                        licensors and suppliers disclaim liability to any person
                        or entity for any indirect, special, consequential, or
                        incidental losses or damages whatsoever arising from or
                        in connection with the information contained herein or
                        the use of or inability to use any such information,
                        even if MOODY&#39;S or any of its directors, officers,
                        employees, agents, representatives, licensors or
                        suppliers is advised in advance of the possibility of
                        such losses or damages, including but not limited to:
                        (a) any loss of present or prospective profits or (b)
                        any loss or damage arising where the relevant financial
                        instrument is not the subject of a particular credit
                        rating assigned by MOODY&#39;S.
                    </p>

                    <p>
                        To the extent permitted by law, MOODY&#39;S and its
                        directors, officers, employees, agents, representatives,
                        licensors and suppliers disclaim liability for any
                        direct or compensatory losses or damages caused to any
                        person or entity, including but not limited to by any
                        negligence (but excluding fraud, willful misconduct or
                        any other type of liability that, for the avoidance of
                        doubt, by law cannot be excluded) on the part of, or any
                        contingency within or beyond the control of, MOODY&#39;S
                        or any of its directors, officers, employees, agents,
                        representatives, licensors or suppliers, arising from or
                        in connection with the information contained herein or
                        the use of or inability to use any such information.
                    </p>

                    <p>
                        NO WARRANTY, EXPRESS OR IMPLIED, AS TO THE ACCURACY,
                        TIMELINESS, COMPLETENESS, MERCHANTABILITY OR FITNESS FOR
                        ANY PARTICULAR PURPOSE OF ANY CREDIT RATING, ASSESSMENT,
                        OTHER OPINION OR INFORMATION IS GIVEN OR MADE BY
                        MOODY&#39;S IN ANY FORM OR MANNER WHATSOEVER.
                    </p>

                    <p>
                        Moody&#39;s Investors Service, Inc., a wholly-owned
                        credit rating agency subsidiary of Moody&#39;s
                        Corporation (“MCO”), hereby discloses that most issuers
                        of debt securities (including corporate and municipal
                        bonds, debentures, notes and commercial paper) and
                        preferred stock rated by Moody&#39;s Investors Service,
                        Inc. have, prior to assignment of any credit rating,
                        agreed to pay to Moody&#39;s Investors Service, Inc. for
                        credit ratings opinions and services rendered by it fees
                        ranging from $1,000 to approximately $5,000,000. MCO and
                        Moody&#39;s Investors Service also maintain policies and
                        procedures to address the independence of Moody&#39;s
                        Investors Service credit ratings and credit rating
                        processes. Information regarding certain affiliations
                        that may exist between directors of MCO and rated
                        entities, and between entities who hold credit ratings
                        from Moody&#39;s Investors Service, Inc. and have also
                        publicly reported to the SEC an ownership interest in
                        MCO of more than 5%, is posted annually at
                        www.moodys.com under the heading “Investor Relations —
                        Corporate Governance — Charter Documents - Director and
                        Shareholder Affiliation Policy.”
                    </p>

                    <p>
                        Additional terms for Australia only: Any publication
                        into Australia of this document is pursuant to the
                        Australian Financial Services License of MOODY&#39;S
                        affiliate, Moody&#39;s Investors Service Pty Limited ABN
                        61 003 399 657AFSL 336969 and/or Moody&#39;s Analytics
                        Australia Pty Ltd ABN 94 105 136 972 AFSL 383569 (as
                        applicable). This document is intended to be provided
                        only to “wholesale clients” within the meaning of
                        section 761G of the Corporations Act 2001. By continuing
                        to access this document from within Australia, you
                        represent to MOODY&#39;S that you are, or are accessing
                        the document as a representative of, a “wholesale
                        client” and that neither you nor the entity you
                        represent will directly or indirectly disseminate this
                        document or its contents to “retail clients” within the
                        meaning of section 761G of the Corporations Act 2001.
                        MOODY&#39;S credit rating is an opinion as to the
                        creditworthiness of a debt obligation of the issuer, not
                        on the equity securities of the issuer or any form of
                        security that is available to retail investors.
                    </p>

                    <p>
                        Additional terms for Japan only: Moody&#39;s Japan K.K.
                        (“MJKK”) is a wholly-owned credit rating agency
                        subsidiary of Moody&#39;s Group Japan G.K., which is
                        wholly-owned by Moody&#39;s Overseas Holdings Inc., a
                        wholly-owned subsidiary of MCO. Moody&#39;s SF Japan
                        K.K. (“MSFJ”) is a wholly-owned credit rating agency
                        subsidiary of MJKK. MSFJ is not a Nationally Recognized
                        Statistical Rating Organization (“NRSRO”). Therefore,
                        credit ratings assigned by MSFJ are Non-NRSRO Credit
                        Ratings. Non-NRSRO Credit Ratings are assigned by an
                        entity that is not a NRSRO and, consequently, the rated
                        obligation will not qualify for certain types of
                        treatment under U.S. laws. MJKK and MSFJ are credit
                        rating agencies registered with the Japan Financial
                        Services Agency and their registration numbers are FSA
                        Commissioner (Ratings) No. 2 and 3 respectively.
                    </p>

                    <p>
                        MJKK or MSFJ (as applicable) hereby disclose that most
                        issuers of debt securities (including corporate and
                        municipal bonds, debentures, notes and commercial paper)
                        and preferred stock rated by MJKK or MSFJ (as
                        applicable) have, prior to assignment of any credit
                        rating, agreed to pay to MJKK or MSFJ (as applicable)
                        for credit ratings opinions and services rendered by it
                        fees ranging from JPY100,000 to approximately
                        JPY550,000,000.
                    </p>

                    <p>
                        MJKK and MSFJ also maintain policies and procedures to
                        address Japanese regulatory requirements.
                    </p>
                </div>
            </div>{' '}
        </>
    );
};

PageCopyright.propTypes = {
    className: PropTypes.string,
};

PageCopyright.defaultProps = {
    className: '',
};

export default PageCopyright;
