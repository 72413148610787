import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TileCalloutButton from '@bit/modus-moodys.mapulseui.tile-callout-button';
import DynamicTabHeaders from '@bit/modus-moodys.mapulseui.dynamic-tab-headers';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../store';
import './styles.scss';

import panelProps from '../../flows';
import InProgress from './InProgress/index';

const PageEntity = ({ cls, id, tag }) => {
    const _cls = `page page-entity ${cls || ''}`;
    const { stPanels, actSetPanels } = useAppContext();
    const { actSetEntities } = useAppContext();
    const [activeTab, setActiveTab] = useState(0);
    const { addPanel } = panelProps(stPanels, actSetPanels);
    const { t } = useTranslation();
    const openOnBoardByName = () => {
        window.Analytics?.sendEvent('onboard_entity_start');
        actSetEntities({});
        addPanel('OnBoardTypeahead');
    };
    const tabHeadersConfig = [
        {
            id: 'find-an-entity',
            label: t('lbl_find_entity', { defaultValue: 'Find an entity' }),
            active: activeTab === 0,
            onClick: () => {
                setActiveTab(0);
            },
        },
        {
            id: 'in--progress',
            label: t('lbl_in_progress', { defaultValue: 'In progress' }),
            active: activeTab === 1,
            onClick: () => {
                setActiveTab(1);
            },
            // disabled: true,
        },
    ];
    return (
        <div id={id} className={_cls} data-tag={tag}>
            <div className="title-ribbon">
                <span className="title-header">
                    {t('lbl_add_entity', { defaultValue: 'Add an Entity' })}
                </span>
                <DynamicTabHeaders items={tabHeadersConfig} />
            </div>
            <div className="page-content">
                {activeTab ? (
                    <InProgress />
                ) : (
                    // eslint-disable-next-line
                    <React.Fragment>
                        <div className="left-tile">
                            <TileCalloutButton
                                title={`${t('lbl_search_by', {
                                    defaultValue: 'Search by ',
                                })} <strong>${t('lbl_entity', {
                                    defaultValue: 'Entity',
                                })} ${t('row_header_name', {
                                    defaultValue: 'Name',
                                })}</strong>`}
                                subtitle='eg. "Microsoft","John Smith"'
                                clicked={() => openOnBoardByName()}
                            />
                        </div>
                        <div className="right-tile">
                            <TileCalloutButton
                                title={`${t('lbl_search_by', {
                                    defaultValue: 'Search by ',
                                })} <strong>Orbis ID</strong>`}
                                subtitle='eg. "TL65428195555"'
                                clicked={() => {
                                    window.Analytics?.sendEvent(
                                        'onboard_entity_start'
                                    );
                                    return addPanel('OnBoardSearchId', {
                                        handleCheckPogress: () =>
                                            setActiveTab(1),
                                    });
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

PageEntity.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
};

PageEntity.defaultProps = {
    cls: '',
    id: '',
    tag: '',
};
export default PageEntity;
