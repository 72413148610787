import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styles.scss';
import Button from '@bit/modus-moodys.mapulseui.button';
import Dropdown from '@bit/modus-moodys.mapulseui.dropdown-list';
import TextInput from '@bit/modus-moodys.mapulseui.text-input';
import TextArea from '@bit/modus-moodys.mapulseui.text-area';
import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import FormWrapper from '@bit/modus-moodys.mapulseui.form-wrapper';
import Uploader from '@bit/modus-moodys.mapulseui.uploader';
import { requests } from '@requests';
import { useTranslation } from 'react-i18next';
import { debounceFn, transformIdToMsg } from '../../helpers/utils';
const UPLOAD_ERR_TIMEOUT = 4000;

const { submitSupportForm } = requests;

// TODO: pass copytext from app > router > to here
const PageSupport = ({ cls, id, tag }) => {
    const _cls = `page page-support ${cls || ''}`;

    const [success, setSuccess] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [submitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploaderErrors, setUploaderErrors] = useState([]);
    const [topicsErrors, setTopicsErrors] = useState([]);
    const [subjectErrors, setSubjectErrors] = useState([]);
    const [descriptionErrors, setDescriptionErrors] = useState([]);
    const [s3Upload, setS3Upload] = useState({});
    const { t } = useTranslation();
    const hist = useHistory();
    const topic = useRef();
    const subject = useRef();
    const description = useRef();
    // for initial load
    const loaded = useRef(false);

    const topics = [
        {
            label: t('lbl_technical_issue', {
                defaultValue: 'I’m having a technical issue',
            }),
            id: 'technical_issue',
        },
        {
            label:
                t('lbl_question_how_to_use', {
                    defaultValue: 'I have a question about how to use',
                }) +
                ' ' +
                t('app_name_government', {
                    defaultValue: 'NewsEdge Events',
                }),
            id: 'question_how_to_use',
        },
        {
            label: t('lbl_question_billing', {
                defaultValue: 'I have a question about billing',
            }),
            id: 'question_billing',
        },
        {
            label: t('lbl_feedback', {
                defaultValue:
                    'I want to provide feedback/suggestions about features',
            }),
            id: 'feedback',
        },
        {
            label: t('lbl_other', {
                defaultValue: 'Other',
            }),
            id: 'other',
        },
    ];

    useEffect(() => {
        // mimic componentDidUpdate
        if (loaded?.current) {
            const shouldDisable =
                submitting ||
                isLoading ||
                topicsErrors.length > 0 ||
                subjectErrors.length > 0 ||
                descriptionErrors.length > 0 ||
                !topic?.current?.id ||
                !subject?.current?.value ||
                !description?.current?.value;

            setSubmitDisabled(shouldDisable);
        } else {
            loaded.current = true;
        }
    }, [submitting, isLoading, topicsErrors, subjectErrors, descriptionErrors]);

    const getInputValues = (refs) => {
        let values = {};
        for (const key in refs) {
            const { value, id } = refs[key].current;
            values[key] = value || id;
        }
        return values;
    };

    const validateEntry = (ref, validations, val) => {
        const value = val || ref.current.value;
        const errors = [];
        let rule = null;
        Object.keys(validations).map((key) => {
            rule = validations[key];
            switch (key) {
                case 'required':
                    if (rule && (!value || !value.length)) {
                        errors.push(t('lbl_required_error'));
                    }
                    break;
                case 'min':
                    rule = validations[key];
                    if (value.length < rule) {
                        errors.push(t('lbl_min_len_err', { count: rule }));
                    }
                    break;
                default:
                    break;
            }
        });
        return errors;
    };

    let [clearError] = useState(() =>
        debounceFn(() => {
            setUploaderErrors([]);
        }, UPLOAD_ERR_TIMEOUT)
    );

    const handleUpload = (uploads, error) => {
        setIsLoading(true);
        if (error) {
            clearError();
            setUploaderErrors([error.msg]);
        } else if (uploads) {
            if (uploaderErrors.length > 0) setUploaderErrors([]);
            setUploadedFiles([...uploads.files]);
        }
        setIsLoading(false);
    };

    const validateTopic = (id) => {
        const topicErr = validateEntry(topic, { required: true }, id);
        setTopicsErrors(topicErr);
        return topicErr;
    };

    const validateSubject = () => {
        const subjectErr = validateEntry(subject, { required: true, min: 10 });
        setSubjectErrors(subjectErr);
        return subjectErr;
    };

    const validateDescription = () => {
        const descriptionErr = validateEntry(description, {
            required: true,
            min: 20,
        });
        setDescriptionErrors(descriptionErr);
        return descriptionErr;
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setS3Upload({});
        const fieldValues = getInputValues({ topic, subject, description });
        const body = { ...fieldValues, files: uploadedFiles };
        const response = await submitSupportForm(body);
        return response;
    };

    const handleResult = ({ state, data = null, error = null }) => {
        setIsSubmitting(false);
        if (state) {
            setSuccess(true);
            window.Analytics?.sendEvent?.('success_support');
            setS3Upload(data);
        } else {
            const errMessage = error.id
                ? transformIdToMsg(error.id)
                : t('lbl_support_err');
            setS3Upload({ error: errMessage });
        }
    };

    useEffect(() => {
        window.Analytics?.sendEvent?.('open_support');
    }, []);

    const requiredText = t('lbl_required');
    return (
        <div className={_cls} id={id} data-tag={tag}>
            <div className="page-content">
                <div className="support-form">
                    <p className="page-header heading-1">
                        {t('lbl_contact_support')}
                    </p>
                    {success ? (
                        <>
                            <div className="thank-you-text body-text">
                                {t('lbl_support_success')}
                            </div>
                            <Button
                                isPrimary
                                label={t('lbl_back')}
                                cls="secondary-text"
                                onClick={hist.goBack}
                            />
                        </>
                    ) : (
                        <>
                            <FormWrapper
                                label={{
                                    text: t('lbl_topic'),
                                    font: 'heading-4',
                                    position: 'top',
                                }}
                                isRequired
                                errors={topicsErrors}
                                requiredText={requiredText}
                            >
                                <Dropdown
                                    items={topics}
                                    ref={topic}
                                    labelTexts={{
                                        selectOption: t('lbl_select_option'),
                                    }}
                                    onSelect={(id) => validateTopic(id)}
                                />
                            </FormWrapper>
                            <FormWrapper
                                label={{
                                    text: t('lbl_subject'),
                                    font: 'heading-4',
                                    position: 'top',
                                }}
                                isRequired
                                requiredText={requiredText}
                                errors={subjectErrors}
                            >
                                <TextInput
                                    cls="subject-field body-text"
                                    placeholder={t('lbl_sub_placeholder')}
                                    onValueChange={validateSubject}
                                    ref={subject}
                                />
                            </FormWrapper>
                            <FormWrapper
                                label={{
                                    text: t('lbl_description'),
                                    font: 'heading-4',
                                    position: 'top',
                                }}
                                description={t('lbl_description_sub')}
                                requiredText={requiredText}
                                isRequired
                                errors={descriptionErrors}
                            >
                                <TextArea
                                    placeholder={t('lbl_desc_plc')}
                                    ref={description}
                                    onValueChange={validateDescription}
                                />
                            </FormWrapper>
                            <FormWrapper
                                handleError={false}
                                label={{
                                    text: t('lbl_attachment', {
                                        count: 2,
                                        fallbackLng: 'en-US',
                                    }),
                                    font: 'heading-4',
                                    position: 'top',
                                }}
                                description={t('lbl_attachment_desc')}
                                errors={uploaderErrors}
                            >
                                <Uploader
                                    handleUpload={handleUpload}
                                    hasError={uploaderErrors.length > 0}
                                    fileMaxSize={10}
                                    labelTexts={{
                                        noFilesSelected:
                                            t('lbl_no_file_select'),
                                        browseFilesText: t('lbl_browse_file'),
                                        dragNDropText: t('lbl_drag_drop'),
                                        orText: t('lbl_or'),
                                    }}
                                    className={'support-uploader form-wrapper'}
                                    errorComponent={
                                        <div className="ma-pulse-ui-error-wrapper form-wrapper">
                                            <ul
                                                className={`form-wrapper ${
                                                    uploaderErrors &&
                                                    uploaderErrors.length > 0
                                                        ? 'visible'
                                                        : ''
                                                }`}
                                            >
                                                <li className="body-text rev-text-color form-wrapper">
                                                    {uploaderErrors?.[0]
                                                        ? uploaderErrors[0]
                                                        : null}
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                />
                            </FormWrapper>

                            {s3Upload.error && (
                                <p className="submit-error tertiary-text">
                                    {s3Upload.error}
                                </p>
                            )}

                            <SubmitButton
                                cls="support-form-submit-button"
                                label={t('lbl_submit')}
                                disabled={submitDisabled}
                                action={handleSubmit}
                                cbResult={handleResult}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

PageSupport.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
};

PageSupport.defaultProps = {
    cls: '',
    id: '',
    tag: '',
};

export default PageSupport;
