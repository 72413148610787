/* eslint-disable no-console */
const deepmerge = require('deepmerge');
const baseConfig = require('./config.js');
const localConfig = require('./config.local.js');
const devConfig = require('./config.development.js');
const uatConfig = require('./config.uat.js');
const prodConfig = require('./config.production.js');
const testConfig = require('./config.testing');
const schema = require('./schema.js');

const ConfigMap = {
    base: baseConfig,
    local: localConfig,
    development: devConfig,
    uat: uatConfig,
    production: prodConfig,
    test: testConfig,
};

module.exports = (env = process.env.REACT_APP_NODE_ENV || 'local') => {
    const baseEnvConfig = ConfigMap['baseConfig'];

    let envConfig = {};
    if (env) {
        // safe load envConfig
        try {
            if (!ConfigMap[env]) {
                throw new Error(`config for ${env} not found`);
            }
            envConfig = ConfigMap[env];
        } catch (error) {
            console.warn(error);
        }
    }
    const mergedConfig = deepmerge(baseEnvConfig, envConfig);

    const validation = schema.validate(mergedConfig);

    if (validation.error) {
        console.error(`[ERROR] ${validation.error}`);
        process.exit(1);
    }

    return mergedConfig;
};
