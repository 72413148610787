import React, { useEffect, useMemo } from 'react';
import { requests } from './requests';
import './App.scss';
import PageSplash from '@pages/PageSplash';
import useHandleVerification from '@hooks/useHandleVerification';
import PageAuthError from '@pages/PageAuthError';
import PageHome from '@pages/PageHome';
import TranslationWorker from '@bit/modus-moodys.mapulseui.translation-worker';
import TestingZone from '@bit/modus-moodys.mapulseui.testing-zone';
import { NotificationsProvider } from '@bit/modus-moodys.mapulseui.notifications';
import moment from 'moment';
import { isEnvDev } from '@helpers/utils';
import { useAppContext } from '@store';
import { useTestingPane } from './hooks/useTestingPane';
import { EventsFilterProvider } from './hooks/useEventsFilter';

function App() {
    const { authError, authenticated } = useHandleVerification();
    const { actSetUserInfo, setEnabledTestingMethods } = useAppContext();

    const handleTransitionPages = () => {
        if (authError) {
            return <PageAuthError />;
        }
        return <PageSplash />;
    };

    const onLangChange = (locale) => {
        localStorage.setItem(TranslationWorker.keyForLocale, locale);
        window.location.reload();
    };

    const handleUserPref = async () => {
        await requests.updateUserPreferences({
            is_satisfaction_utility: 0,
            is_satisfaction_experience: 0,
            date_added: moment(new Date())
                .subtract(2, 'months')
                .format('YYYY-MM-DD HH:mm:ss'),
        });
    };

    const testMethods = {
        pageHome: () => history.go('/'),
        // pageDetails: () => history.go('/details'),
        // pageEntity: () => history.go('/entity'),
        pageError: () => history.go('/error'),
        pagePortfolio: () => history.go('/portfolio'),
        pageSupport: () => history.go('/support'),
        'updateUserPrefs(...)': () => handleUserPref(),
        'Lang EN': () => onLangChange('en-US'),
        'Lang ES': () => onLangChange('es'),
        'Toggle News Volume Duration': () => {
            setEnabledTestingMethods((oldData) => {
                oldData.newsVolumeInterval = oldData?.newsVolumeInterval ?? 5;
                return {
                    ...oldData,
                    newsVolumeInterval:
                        oldData.newsVolumeInterval === 5 ? 50 : 5,
                };
            });
        },
    };

    ['experience', 'utility'].forEach((surveyType) => {
        testMethods[`Show ${surveyType} survey`] = () => {
            actSetUserInfo((old) => {
                return {
                    ...old,
                    surveyPreferences: {
                        showSurvey: true,
                        isTesting: true,
                        surveyType: 'utility',
                    },
                };
            });
        };
    });

    const { addMethod, availableMethods } = useTestingPane();

    const TestingZoneMemoized = useMemo(() => {
        return isEnvDev() ? <TestingZone methods={availableMethods} /> : null;
    }, [availableMethods]);

    useEffect(() => {
        addMethod(testMethods);
    }, []);

    return (
        <div className="app" id="government-app">
            {authError || !authenticated ? (
                handleTransitionPages()
            ) : (
                <>
                    <NotificationsProvider options={{ autoremove: false }}>
                        <EventsFilterProvider>
                            <PageHome />
                            {TestingZoneMemoized}
                        </EventsFilterProvider>
                    </NotificationsProvider>
                </>
            )}
        </div>
    );
}

export default App;
