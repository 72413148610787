export const DEFAULT_INCLUDES = [
    'cam_article.article_id',
    'article.url',
    'article.title',
    'article.date_published',
    'cam_sentiment.label',
    'article.publication',
    'article.source',
    'article.lang',
    'user_article.date_read',
    'user_article.snippets',
    'cam_article.json_category',
    'article.summary',
];
export const DEFAULT_SORT = { date_published: 'DESC' };
// NB: The first one is used as the initial value for the sort-by dropdown.
export const DEFAULT_SORT_OPTIONS = (t) => [
    {
        label: t('lbl_sort_new_old'),
        id: 'sort_new_old',
        type: 'DESC',
        sortCol: 'date_published',
    },
    {
        label: t('lbl_risk_high_low'),
        id: 'sort_high_low',
        type: 'DESC',
        sortCol: 'risk_level',
    },
    {
        label: t('lbl_risk_low_high'),
        id: 'sort_low_high',
        type: 'ASC',
        sortCol: 'risk_level',
    },
];
