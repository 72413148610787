import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import DatePicker, {
    CLOSE,
    DATES_SELECTED,
} from '@bit/modus-moodys.mapulseui.date-picker';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import logger from '@bit/modus-moodys.mapulseui.logger';

const convertToMomentDate = (dateString) => {
    dateString =
        typeof dateString === typeof ' ' && dateString.length > 0
            ? dateString.split('T')[0]
            : null;
    return dateString ? DateViewer.getDateMomentObj(dateString) : moment();
};

// const convertFromMomentDate = (mDate) => (mDate && mDate._isAMomentObject) ? mDate.format('MM-DD-YYYY'): mDate;
const convertFromMomentDate = (mDate) =>
    mDate && mDate._isAMomentObject ? DateViewer.getDateText(mDate) : mDate;

const panelsFilters = {
    DatePickerPanel: ({ item, removePanel }) => {
        const { t } = useTranslation();
        const {
            onDateChange,
            selectedDates,
            maxDateRange,
            initialVisibleMonth,
        } = item.props;
        const props = {};

        if (typeof initialVisibleMonth !== 'function') {
            logger.error(`typeof initialVisibleMonth should be a function.`);
        } else {
            props.initialVisibleMonth = initialVisibleMonth;
        }

        if (selectedDates.startDate && selectedDates.endDate) {
            props.initialStartDate = convertToMomentDate(
                selectedDates.startDate
            );
            props.initialEndDate = convertToMomentDate(selectedDates.endDate);
        }

        if (maxDateRange.startDate && maxDateRange.endDate) {
            props.isOutsideRange = (day) => day.isAfter(moment());

            // Validation for outside ranges
            props.maxDateRange = {
                startDate: maxDateRange.startDate,
                endDate: maxDateRange.endDate,
            };
        }

        logger.log(`DatePicker props`, props);

        return (
            <div className="panel-date-picker panel">
                <h1 className="title heading-2">
                    {t('lbl_filter_by_item', { item: t('lbl_date') })}
                </h1>
                <hr className="separator" />
                <DatePicker
                    {...props}
                    labelTexts={{
                        invalidStartDate: t('lbl_invalid_start_date'),
                        invalidEndDate: t('lbl_invalid_end_date'),
                        invalidStartEndDate: t('lbl_invalid_start_end_date'),
                        yearNotGt2k: t('lbl_year_greater_than_200'),
                        endDateNotFuture: t('lbl_end_date_not_in_future'),
                        endDateShouldBeInFuture: t(
                            'lbl_end_date_less_than_start_date'
                        ),
                        startingDate: t('lbl_starting_date'),
                        endingDate: t('lbl_ending_date'),
                        applyDates: t('lbl_app_dates'),
                        noDataForRange: t('lbl_no_data_date_range'),
                    }}
                    callback={(startDate, endDate, type) => {
                        const sDate = convertFromMomentDate(startDate);
                        const eDate = convertFromMomentDate(endDate);
                        onDateChange(sDate, eDate);

                        if (type === CLOSE || type === DATES_SELECTED) {
                            removePanel('DatePickerPanel');
                        }
                    }}
                />
            </div>
        );
    },
};

panelsFilters.DatePickerPanel.propTypes = {
    item: PropTypes.instanceOf(PropTypes.object).isRequired,
    removePanel: PropTypes.string.isRequired,
    onDateChange: PropTypes.string.isRequired,
};

export default panelsFilters;
