const GAeventsMap = {
    moveCard: () => {
        window.Analytics?.sendEvent?.('dashboard_action', {
            action: 'move_card',
        });
    },
    dashboardInitiate: (action, meta = {}) => {
        window.Analytics?.sendEvent?.('dashboard_initiate', {
            action,
            ...meta,
        });
    },
    editCard: (tileId, updatedInfo = {}) => {
        window.Analytics?.sendEvent?.('dashboard_action', {
            action: 'edit_card',
            tileId,
            ...updatedInfo,
        });
    },
    addCardStart: (tileId) => {
        window.Analytics?.sendEvent('dashboard_add_card_start', {
            tileId,
        });
    },
    addCardEnd: (meta = {}) => {
        window.Analytics?.sendEvent('dashboard_add_card_end', {
            ...meta,
        });
    },
    addCardBack: (meta = {}) => {
        window.Analytics?.sendEvent('dashboard_add_card_back', {
            ...meta,
        });
    },
    eventClickThrough: (eventId) => {
        window.Analytics?.sendEvent?.('dashboard_event_clickthrough', {
            eventId,
        });
    },
};

export default GAeventsMap;
