import ActionWithIcon from '@bit/modus-moodys.mapulseui.action-with-icon';
import {
    AsyncTaskStatesEnum,
    useAsyncTask,
} from '@bit/modus-moodys.mapulseui._hooks/dist/useAsyncTask';
import { requests } from '@requests';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { MuuriComponent } from 'muuri-react';
import { useUserTiles, useAddTile } from './Helpers/utils';
import logger from '@bit/modus-moodys.mapulseui.logger';
import GAeventsMap from '../ga-events';

const TileView = () => {
    const { t } = useTranslation();

    const { run, taskState, taskResponse } = useAsyncTask(
        requests.getDashboard
    );

    const [userAvailableTiles, setUserAvailableTiles] = useState([]);

    useEffect(() => {
        // Set user available tiles only when the task is success
        if (taskState !== AsyncTaskStatesEnum.SUCCESS) return;
        setUserAvailableTiles(taskResponse ?? []);
    }, [taskState, taskResponse]);

    useEffect(() => {
        // Run the API call on mount
        run();
    }, []);

    const reloadView = () => {
        run();
    };

    const { AddTileSelector, isAddTileHidden, setIsAddTileHidden } = useAddTile(
        userAvailableTiles ?? [],
        reloadView
    );

    const userHasTiles = userAvailableTiles.length > 0;

    const gridRef = useRef({});
    const tileViewRef = useRef({});

    const UserTiles = useUserTiles(
        {
            userAvailableTiles,
            setUserAvailableTiles,
            gridWidth: tileViewRef.current?.clientWidth + 20, // Consider padding of 10px on each side
        },
        [userAvailableTiles]
    );

    const onDragEnd = async (gridItem) => {
        const newOrder = gridItem
            .getGrid()
            .getItems()
            .map((i) => i?._component?.key);

        const currentOrder = userAvailableTiles.map((i) => i.uniqueId);

        // Make update API call only if the order has changed
        if (JSON.stringify(newOrder) === JSON.stringify(currentOrder)) {
            return;
        }

        const sortedTiles = userAvailableTiles.sort((a, b) => {
            const aIndex = newOrder.indexOf(a.uniqueId);
            const bIndex = newOrder.indexOf(b.uniqueId);
            return aIndex - bIndex;
        });

        try {
            await requests.updateDashboard(sortedTiles);
            GAeventsMap.moveCard();
        } catch (error) {
            logger.error(`Error while updating tiles order`, error);
        }
    };

    const DragDropView = useMemo(() => {
        if (!userHasTiles) {
            return null;
        }

        return (
            <MuuriComponent
                containerClass="drag-drop-area"
                dragEnabled
                dragStartPredicate={{
                    handle: '.draggable-area',
                }}
                dragPlaceholder={{
                    enabled: true,
                    createElement: function () {
                        const placeholder = document.createElement('div');
                        placeholder.innerHTML = '<div></div>';
                        return placeholder;
                    },
                }}
                propsToData={({ tileData }) => {
                    return tileData ?? {};
                }}
                sort={'index'}
                onDragEnd={onDragEnd}
                ref={gridRef}
            >
                {UserTiles}
            </MuuriComponent>
        );
    }, [userHasTiles, UserTiles]);

    return (
        <div className="tile-view" ref={tileViewRef}>
            {DragDropView}
            <div className="add-tiles">
                <ActionWithIcon
                    cls="add-cta"
                    icon="nav-add"
                    color="#095FC3"
                    label={t('lbl_add_new_card', {
                        defaultValue: 'Add new card',
                    })}
                    onClick={() => {
                        setIsAddTileHidden(false);
                    }}
                />
            </div>
            {taskState === AsyncTaskStatesEnum.PROCESSING && (
                <div className="loading-container" />
            )}
            {!isAddTileHidden && AddTileSelector}
        </div>
    );
};

TileView.propTypes = {};

export default TileView;
