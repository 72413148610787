import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import SelectWatchlistRow from '@bit/modus-moodys.mapulseui.select-watchlist-row';
import InputTextWithIcon from '@bit/modus-moodys.mapulseui.input-text-with-icon';
import { requests } from '@requests';
import './styles.scss';
import { RISK_SCOPES } from '@pages/PageDashboard/TileView/Helpers/constants';

const SelectWatchlist = ({ item, hideFromIndex }) => {
    const { userWatchlistId } = item.props || {};
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState(null);
    const [pageData, setPageData] = useState({
        page: 1,
        pages: 2,
        disabled: false,
    });

    const [selectedWatchlistId, setSelectedWatchlistId] =
        useState(userWatchlistId);
    const [watchlists, setWatchlists] = useState([]);

    const fetchUserWatchlist = async () => {
        setLoading(true);
        const response = await requests.userWatchlist({
            filterKeyword: keyword,
            page: pageData.page,
            size: 10,
        });
        updateWatchlist([...response.data], selectedWatchlistId);
        const { page, pages } = response;
        setPageData({ page, pages, disabled: false });
        setLoading(false);
    };

    useEffect(() => {
        fetchUserWatchlist();
    }, [pageData.page]);

    useEffect(() => {
        fetchUserWatchlist(pageData.page);
    }, [keyword]);

    const onAction = (id) => {
        setSelectedWatchlistId(id);
        updateWatchlist(watchlists, id);
        setTimeout(() => {
            hideFromIndex(-1, {
                scope: RISK_SCOPES.WATCHLIST,
                userWatchlistId: id,
            });
        }, 500);
    };

    const updateWatchlist = (data, selectedId) => {
        const selectWatchlists = data.map((i) => ({
            ...i,
            id: i.id.toString(),
            isSelected: selectedId == i.id,
        }));
        setWatchlists(selectWatchlists);
    };

    const updateKeyword = (searchTerm) => {
        setKeyword(searchTerm);
    };
    const handleKeywordChange = (searchTerm) => {
        if (searchTerm.length === 0) {
            setKeyword(searchTerm);
        }
    };

    return (
        <div className="manage-widget manage-paginated-watchlist">
            <h1 className="title heading-1 header">Select a watchlist</h1>
            <span className="sub-title heading-9">
                Make a selection from your available watchlist
            </span>
            <hr className="seperator" />
            <InputTextWithIcon
                placeholder="Search watchlist"
                submit={updateKeyword}
                valueChanged={handleKeywordChange}
            />
            <PaginatedList
                id="paginated-watchlist"
                cls=""
                Row={SelectWatchlistRow}
                rowData={watchlists}
                onAction={onAction}
                pageData={pageData}
                setPageData={setPageData}
                loading={loading}
            />
        </div>
    );
};

SelectWatchlist.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
};

SelectWatchlist.defaultProps = {
    addPanel: () => {},
    hideFromIndex: () => {},
    item: {},
};

export default SelectWatchlist;
