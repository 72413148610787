export const sendFilterTagEvent = () =>
    window.Analytics?.sendEvent('events_filter_engagement');

export const sendOpenArticleEvent = (entityId, articleId) =>
    window.Analytics?.sendEvent?.('open_article', {
        entityId,
        articleId: articleId,
    });

export const PoleAction = {
    onSeeMore: () => {
        window.Analytics?.sendEvent?.('pole_action', {
            action: 'see_more',
        });
    },
    rangeSliderChange: (min, max) => {
        window.Analytics?.sendEvent?.('pole_action', {
            action: 'range_slider_change',
            meta: {
                min,
                max,
            },
        });
    },
};

export const sendPdfProfileOpenEvent = (entityId) => {
    window.Analytics?.sendEvent?.('pdf_profile_open', { entityId });
};
