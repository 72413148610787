/* eslint-disable no-unused-vars */
import Requests from '@bit/modus-moodys.mapulseui.request-manager';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import {
    capitalize,
    safeParse,
    sumArray,
    parseRegionCountry,
} from './helpers/utils';
import logger from '@bit/modus-moodys.mapulseui.logger';
import faker from 'faker';
import { nanoid } from 'nanoid';
import {
    UNIQUE_KEY_LEN,
    LOCATION_TYPE,
} from './pages/PageDashboard/TileView/Helpers/constants';
import { filterUndefinedFromObject } from './helpers/utils';
import {
    PoleDataSort,
    PoleToNameMap,
    PoleTypeMap,
} from './pages/PageDetails/defaults';
import { ViewEnum } from '@pages/PageEvent/Views/EventsView/constants';
import { GaEventsMap } from '@pages/PageEvent/ga-events';
import config from './config';

const axiosConfig = config.axios;
import GAeventsMap from '@pages/PageDashboard/ga-events';

const {
    auth: authServiceUrl,
    typeahead,
    onboard,
    portfolio,
    entity,
    article,
    statistic,
    cam,
    rdc,
    dashboard,
    event,
} = axiosConfig.services;

export const requests = new Requests(axiosConfig);

const { get, post } = requests;
const getUniqueArrFromJson = (jsonArr) => {
    try {
        const arr = JSON.parse(jsonArr);
        return typeof arr !== typeof [] ? arr : Array.from(new Set(arr));
    } catch (error) {
        logger.error('Error in getUniqueArrFromJson in requests.js');
        return null;
    }
};

/**manage folder flow apis */

requests.createFolder = async ({ label }) =>
    post(`${entity}/user-folder/create`, { label });
requests.searchFolderByName = async ({ keyword, page }) => {
    const transformer = ({ data: response }) => {
        return { ...response.data };
    };

    return post(
        `${entity}/user-folder/list`,
        { filter_keyword: keyword, page, size: 20 },
        transformer
    );
};
requests.updateFolder = async ({ id, label }) => {
    const transformer = ({ data: response }) => {
        return { ...response.data };
    };

    return post(
        `${entity}/user-folder/update`,
        { label: label, user_folder_id: id },
        transformer
    );
};
requests.deleteFolder = async ({ id, deleteArticles = false }) => {
    const transformer = ({ data: response }) => {
        return { ...response.data };
    };

    return post(
        `${entity}/user-folder/delete`,
        { deleteArticles, user_folder_id: id },
        transformer
    );
};

requests.updateArticleToFolder = async ({ userFolderId, articleId }) => {
    return post(`${entity}/user-folder/update-article`, {
        article_id: articleId,
        user_folder_id: userFolderId,
    });
};

requests.moveArticleToNotRelavant = async ({ reasons, articleId }) => {
    return post(`${entity}/non-relevant/add`, {
        article_id: articleId,
        not_relevant: reasons,
    });
};
// -----

requests.getAuthServiceStatus = async () => get(`${authServiceUrl}/status`);

requests.searchByName = async ({ searchName, page, location }) => {
    const transformer = ({ data: response }) => {
        const newData = response.data.map((ent) => {
            return { key: ent.entityId, ...ent };
        });
        return { ...response.data, data: newData };
    };
    return get(
        `${typeahead}/search-by-name?name=${searchName}${
            page ? `&page=${page}` : ''
        }${location && location.length > 0 ? `&location=${location}` : ''}`,
        {},
        transformer
    );
};

requests.searchById = async ({ orbisId }) => {
    return get(
        `${typeahead}/search-by-id?orbis_id=${orbisId}`,
        {},
        (response) => response.data
    );
};
requests.onboardBulk = async ({ orbisIds }) => {
    return post(
        `${onboard}/onboard-bulk`,
        { orbis_ids: orbisIds },
        (response) => response.data
    );
};
requests.status = async ({ size = 25, page = 1 }) => {
    return post(
        `${onboard}/in-progress`,
        { size, page },
        (response) => response.data
    );
};
requests.retryEntity = async ({ entityId }) => {
    return post(
        `${cam}/update`,
        {
            entity_id: entityId, // eslint-disable-line camelcase
            is_reset_date: false, // eslint-disable-line camelcase
        },
        (response) => response.data
    );
    // return post (`${cam}`)
};
requests.addEntity = async ({ body }) => {
    const result = post(
        `${onboard}/add-entity`,
        body,
        (response) => response.data
    );
    // GTM
    window.Analytics?.sendEvent?.('add_entity_end');
    return result;
};

// -- Jon testing, to move to request handler
// why is this a post request
// why are we sending app name in the body and param and header
requests.getPortfolio = async ({
    page,
    size,
    include = [],
    watchlists = [],
    search,
    sort,
}) => {
    const transformer = ({ data: response }) => {
        const newData = response.data.map(
            ({
                entity_id: entityId,
                is_company: isCompany,
                name,
                location,
                last_viewed: lastViewed,
                date_added: dateAdded,
                last_update_attempted_count: updateCount,
                last_update_completed_date: lastUpdateDate,
                is_updating: isUpdating,
                total_low_count: lowRiskCount,
                total_medium_count: mediumRiskCount,
                total_high_count: highRiskCount,
                latest_css_trendline: trendLine,
                latest_css_score: cssScore,
                css_score_delta: cssDelta,
                latest_css_date: latestCSSDate,
                date_news_last_created: dateNewsLastCreated,
                date_rdc_updated: dateRdcUpdated,
            }) => ({
                key: `${entityId}-entity-row`,
                entityId,
                id: `${entityId}`,
                isCompany: !!+isCompany,
                name,
                hasNewData:
                    !lastViewed ||
                    (lastUpdateDate && lastUpdateDate > lastViewed),
                location,
                lastViewed,
                dateAdded,
                newsUpdated: lastUpdateDate,
                isUpdating: !!+isUpdating,
                lowRiskCount: lowRiskCount,
                mediumRiskCount: mediumRiskCount,
                highRiskCount: highRiskCount,
                trendLine: JSON.parse(trendLine),
                cssScore:
                    typeof cssScore === typeof 0
                        ? Math.round(cssScore)
                        : cssScore,
                cssDelta: cssDelta ? Math.round(cssDelta) : cssDelta, //round-off only if valid data.
                watchlists,
                latestCSSDate,
                dateNewsLastCreated,
                dateRdcUpdated,
            })
        );
        const transformedResponse = {
            ...response,
            data: newData,
        };
        return transformedResponse;
    };

    const body = {
        page,
        size,
        filter_watchlist: [...watchlists],
        order_by: sort,
        include,
        filter_keyword: search,
    };
    // filter_keyword cannot be null nor length of 0
    if (!search || (typeof search === typeof '' && search.length === 0))
        delete body.filter_keyword;
    return post(`${portfolio}/portfolio`, body, null, transformer);
};

requests.createWatchlist = async ({ label }) =>
    post(`${portfolio}/create-user-watchlist`, { label });

// any merit is using a put request here?
requests.updateWatchlist = async ({ watchlistId, label }) =>
    post(`${portfolio}/update-user-watchlist`, {
        user_watchlist_id: watchlistId,
        label,
    });
// any merit to using a put request here?
requests.deleteWatchlist = async ({ watchlistId }) =>
    post(`${portfolio}/delete-user-watchlist`, {
        user_watchlist_id: watchlistId,
    });
// any merit to using a put/delete request here?
// why are entities a map here and not an array?
// to update entities in a portfolio watchlist
requests.updateUserWatchlistEntity = async ({ watchlistId, entities }) =>
    post(`${portfolio}/update-user-watchlist-entity`, {
        user_watchlist_id: watchlistId,
        entities,
    });

requests.deleteUserEntity = async ({ entityId }) =>
    post(`${portfolio}/delete-user-entity`, {
        entity_id: entityId,
    });

requests.userWatchlist = async ({ filterKeyword, page, size }) => {
    const transformer = (response) => {
        const newData = response.data.data.map((entity) => {
            return { key: entity.id, ...entity };
        });
        return { ...response.data, data: newData };
    };

    return get(
        `${portfolio}/user-watchlist?${page ? `page=${page}` : ''}${
            filterKeyword ? `&filter_keyword=${filterKeyword}` : ''
        }${size ? `&size=${size}` : ''}`,
        {},
        transformer
    );
};

requests.watchlistPortfolio = async ({ id, page, filterKeyword, sort }) => {
    const transformer = (response) => {
        // There is probably a better way to define is_company, but this information doesn't come up in the API
        const newData = response.data.results.map((entity) => {
            return {
                key: entity.entity_id,
                id: entity.entity_id,
                ...entity,
            };
        });
        return { ...response.data, results: newData };
    };

    const body = {
        page,
        user_watchlist_id: id,
        order_by: sort,
    };

    if (filterKeyword) body.filter_keyword = filterKeyword;
    return post(`${portfolio}/watchlist-portfolio/`, body, null, transformer);
};

requests.deleteUserWatchlist = async ({ id }) => {
    const body = {
        user_watchlist_id: id,
    };

    return post(`${portfolio}/delete-user-watchlist`, body, null);
};

requests.updateUserWatchlist = async ({ userWatchlistId, data }) => {
    const body = {
        user_watchlist_id: userWatchlistId,
        ...data,
    };

    return post(`${portfolio}/update-user-watchlist`, body);
};

requests.getEntity = async ({
    entityId,
    page,
    size,
    sort,
    include = [],
    riskLevel,
    riskCategory,
    startDate,
    endDate,
    keyword = [],
    notRelevant = false,
    userFolderId,
}) => {
    const transformer = (response) => {
        const newData = response.data.data.map(
            ({
                article_id: articleId,
                title,
                label: riskSentiment,
                publication,
                source,
                date_published: publishDate,
                date_read: dateRead,
                json_category: jsonCats,
                url,
                lang,
                summary,
                folders,
            }) => ({
                id: articleId,
                entityId,
                articleId,
                title,
                riskSentiment: riskSentiment
                    ? riskSentiment.replace(
                          /([a-z0-9]|(?=[A-Z]))([A-Z])/g,
                          '$1 $2'
                      )
                    : '',
                riskCategories: getUniqueArrFromJson(jsonCats) || [],
                publication,
                source,
                publishDate:
                    publishDate &&
                    DateViewer.getDateText(publishDate.split('T')[0]),
                unread: !dateRead,
                url,
                lang,
                summary,
                folders,
            })
        );
        response.data.data = newData;

        // TODO: replace Faux counts transformer
        delete response.data.counts.date;
        delete response.data.counts.id;
        delete response.data.counts.entity_id;
        delete response.data.counts.num_neutral;
        delete response.data.counts.num_positive;
        response.data.counts.creditAdverse =
            response.data.counts.num_creditAdverse;
        response.data.counts.negative = response.data.counts.num_negative;
        delete response.data.counts.num_creditAdverse;
        delete response.data.counts.num_negative;

        const cssScore = response.data.entity.latest_css_score;

        // TODO: replace faux entity transformer
        response.data.entity = {
            name: response.data.entity.name,
            isCompany: response.data.entity.is_company,
            entityId: response.data.entity.entity_id,
            cssScore:
                typeof cssScore === typeof 0 ? Math.round(cssScore) : cssScore,
            cssDelta: response.data.entity.css_score_delta
                ? Math.round(response.data.entity.css_score_delta)
                : response.data.entity.css_score_delta, //round-off only if valid data.
            trendline: JSON.parse(response.data.entity.latest_css_trendline),
            latestCSSDate: response.data.entity.latest_css_date,
        };

        const sDate = response.data.facets?.date_range?.date_start;
        const eDate = response.data.facets?.date_range?.date_end;
        response.data.filters = response.data.facets ?? {};

        response.data.filters = {
            ...response.data.filters,
            dateRange: {
                // startDate: sDate ? moment.utc(sDate).format('MM-DD-YYYY') : sDate,
                // endDate: eDate ? moment.utc(eDate).format('MM-DD-YYYY') : eDate,
                startDate: sDate
                    ? DateViewer.getDateText(sDate.split('T')[0])
                    : sDate,
                endDate: eDate
                    ? DateViewer.getDateText(eDate.split('T')[0])
                    : eDate,
            },
        };
        return response;
    };
    const body = {
        entity_id: entityId,
        page,
        size,
        order_by: sort,
        include,
        filters: {
            sentiment:
                (typeof riskLevel === typeof [] &&
                    riskLevel.length > 0 &&
                    riskLevel) ||
                undefined,
            category:
                (typeof riskCategory === typeof [] &&
                    riskCategory.length > 0 &&
                    riskCategory) ||
                undefined,
            ...(keyword.length ? { keyword } : {}),
        },
        not_relevant: notRelevant,
        user_folder_id: userFolderId,
    };

    if (
        startDate &&
        typeof startDate === typeof ' ' &&
        startDate.trim().length > 0 &&
        endDate &&
        typeof endDate === typeof ' ' &&
        endDate.trim().length > 0
    ) {
        const mStartDate = DateViewer.getDateMomentObj(startDate);
        const mEndDate = DateViewer.getDateMomentObj(endDate);
        body.filters.date_range = {
            date_start: mStartDate.format('YYYY-MM-DD') + 'T00:00:00.000Z',
            date_end: mEndDate.format('YYYY-MM-DD') + 'T00:00:00.000Z',
        };
    }

    return post(`${entity}/detail`, body, null, transformer);
};

requests.getEventById = async ({ eventId, page, size, filters, sort }) => {
    const body = {
        event_id: eventId,
        size: 10,
        page,
        filter: filters,
        order_by: sort,
    };
    const transformer = (response) => {
        let articlesData = response?.data?.data ?? [];
        articlesData = articlesData.map((article) => {
            return {
                summary: article.summary,
                publishDate:
                    article.date_published &&
                    DateViewer.getDateText(
                        article.date_published.split('T')[0]
                    ),
                articleId: article.article_id,
                source: article.source,
                license: article.license,
                url: article.url,
                riskSentiment: article.level || '',
                riskCategories: getUniqueArrFromJson(
                    article.json_category || '[]'
                ),
                jsonTheme: article.json_theme,
                title: article.title,
            };
        });
        if (response?.data?.data) response.data.data = articlesData;
        return response;
    };
    const { data } = await post(`${event}/detail`, body, null, transformer);
    return data;
};

requests.getArticle = ({ articleId }) => {
    const transformer = (response) => {
        const {
            article_id: articleId,
            body,
            date_read: dateRead,
            entity_id: entityId,
            date_expires: expirationDate,
            lang,
            publication,
            date_published: publishDate,
            label: riskSentiment,
            json_category: jsonCats,
            saved_date: savedDate,
            snippets,
            source,
            title,
            url,
            license_fulltext_display_allowed: isAllowed,
        } = response.data;

        const newData = {
            id: `${articleId}`,
            title,
            articleId,
            body,
            dateRead,
            entityId,
            expirationDate,
            lang,
            publication,
            publishDate,
            riskSentiment: riskSentiment || '',
            riskCategories: getUniqueArrFromJson(jsonCats) || [],
            savedDate,
            snippets: snippets || '',
            source,
            url,
            isAllowed: isAllowed === 1 ? true : false,
        };

        return { ...response, data: newData };
    };

    const params = {
        article_id: articleId,
        include: [
            'article.body',
            'cam_article.article_id',
            'user_article.date_read',
            'cam_article.entity_id',
            'article.date_expires',
            'article.lang',
            'article.publication',
            'article.date_published',
            'cam_sentiment.label',
            'article.date_created',
            'user_article.snippets',
            'article.source',
            'article.title',
            'article.url',
            'cam_article.json_category',
            'article.license_fulltext_display_allowed',
        ],
    };
    return get(`${article}/read`, { params }, transformer);
};

requests.getEntityFilters = ({ entityId, filter = [] }) => {
    const body = {
        entity_id: entityId,
        filter,
    };
    return post(`${entity}/filter`, body, null, null);
};

requests.updateArticle = ({ articleId, snippets }) => {
    const body = {
        article_id: articleId,
        snippets,
    };
    return post(`${article}/update`, body, null, null);
};

// TODO: move this to the bit request manager.
requests.updateUserPreferences = ({
    isDisclaimer,
    isSnippetHelper,
    isSatisfactionUtility,
    isSatisfactionExperience,
    dateAdded,
    ...rest
}) => {
    const body = {
        preferences: {
            ...rest,
            is_disclaimer:
                typeof isDisclaimer !== 'undefined' ? +isDisclaimer : undefined,
            is_snippet_helper:
                typeof isSnippetHelper !== 'undefined'
                    ? +isSnippetHelper
                    : undefined,
            ...rest,
        },
    };
    return post(`${authServiceUrl}/update-user-preference`, body, null, null);
};

requests.getHeatmap = ({ entities, startDate, endDate, interval }) => {
    const transformer = (response) => {
        let dateRange = null;

        const arrayResp = entities.map((entityId) => {
            const series = (response[entityId] || []).reverse();
            dateRange =
                !dateRange && series.length > 0
                    ? series.map(({ date }) => new Date(`${date}T00:00`))
                    : dateRange;
            return { entityId, series };
        });

        return { range: dateRange, data: arrayResp };
    };

    startDate =
        startDate instanceof Date
            ? startDate.toISOString().split('T')[0]
            : startDate;
    endDate =
        endDate instanceof Date ? endDate.toISOString().split('T')[0] : endDate;

    const body = {
        entity_ids: entities,
        date_start: startDate,
        date_end: endDate,
        interval,
    };

    return post(`${portfolio}/heatmap`, body, null, transformer);
};

requests.getSentimentStats = ({
    entityId,
    interval,
    startDate,
    endDate,
    // sentimentLevels = '*',
}) => {
    const transformer = (response) => {
        let { stats: arrData, oldest_date: oldestDate } = response.data;
        let scores = [];
        let countLow = [];
        let countMedium = [];
        let countHigh = [];
        let dates = [];

        for (let i in arrData) {
            const sample = arrData[i];
            dates.push(sample.date ? sample.date.split('T')[0] : null);
            scores.push(
                typeof sample.score === typeof 0
                    ? Math.round(sample.score)
                    : null
            );
            countLow.push(
                typeof sample.num_level_low === typeof 0
                    ? sample.num_level_low
                    : 0
            );
            countMedium.push(
                typeof sample.num_level_medium === typeof 0
                    ? sample.num_level_medium
                    : 0
            );
            countHigh.push(
                typeof sample.num_level_high === typeof 0
                    ? sample.num_level_high
                    : 0
            );
        }

        const t = {
            oldestDate,
            dates,
            scores: [
                {
                    name: 'Credit Sentiment Score',
                    data: scores,
                    showInLegend: false,
                    color: '#5c068c',
                },
            ],
            series: [
                {
                    name: `High-risk <b>${sumArray(countHigh)}</b>`,
                    data: countHigh,
                    color: '#ed2a79',
                    isShown: true,
                    showInLegend: false,
                    id: 'high',
                },
                {
                    name: `Med-risk <b>${sumArray(countMedium)}</b>`,
                    data: countMedium,
                    color: '#b869de',
                    isShown: true,
                    showInLegend: false,
                    id: 'medium',
                },
                {
                    name: `Low-risk <b>${sumArray(countLow)}</b>`,
                    data: countLow,
                    color: '#46b3de',
                    isShown: true,
                    showInLegend: false,
                    id: 'low',
                },
            ],
            sample: arrData,
        };

        return t;
    };

    const params = {
        entity_id: entityId,
        interval,
        date_start: startDate,
        date_end: endDate,
        // sentimentLevels,
    };

    return get(`${statistic}/all`, { params }, transformer);
};

requests.addAdHocEntity = async (payload) => {
    const result = await post(
        `${onboard}/add-adhoc-entity`,
        {
            ...payload,
            is_company: true,
        },
        (response) => response.data
    );
    // TODO: Any GTM event?
    return {
        entity: result.data?.data,
        response: result.data,
    };
};

requests.getRelGraph = async (query) => {
    const { data } = await get(`${entity}/relgraph-mock`, { params: query });
    return data;
};

requests.trendingEntity = async (query = {}) => {
    query = filterUndefinedFromObject(query);
    query = { interval_value: 5, interval_type: 'day', ...query };
    const { data } = await get(`${statistic}/trending/entity`, {
        params: query,
    });
    data.stats = (data.stats ?? []).map((stat) => ({
        id: `${stat.entity_id || ''}`,
        name: stat.name,
        location: stat.location,
        isCompany: !!stat.is_company,
        counts: {
            high: stat.num_high,
            medium: stat.num_medium,
            low: stat.num_low,
        },
        aggregateCount: stat.num_total,
        aggregateDuration: `${query.interval_value} ${capitalize(
            query.interval_type
        )}s`,
    }));

    return data.stats;
};

requests.trendingCategories = async (query = {}) => {
    query = { interval: 15, ...query };
    const { data } = await get(`${statistic}/trending/category`, {
        params: query,
    });

    return data.stats;
};

requests.shortcutEntity = async (query = {}) => {
    query = filterUndefinedFromObject(query);
    query = { interval_value: 5, interval_type: 'day', ...query };
    const { data } = await get(
        `${statistic}/shortcut-entities`,
        {
            params: query,
        },
        (response) => {
            for (const entity of response.data.entities) {
                entity.id = `${entity.entity_id || ''}`;
                entity.counts = {
                    high: entity.num_high,
                    medium: entity.num_medium,
                    low: entity.num_low,
                };
                entity.aggregateCount = entity.num_total;
                entity.aggregateDuration = `${
                    query.interval_value
                } ${capitalize(query.interval_type)}s`;
                entity.isCompany = entity.is_company;
            }
            return response;
        }
    );

    return data;
};
requests.createShortcut = async (arrayEntityIds) => {
    GAeventsMap.dashboardInitiate('add_shortcut');
    return post(`${statistic}/create-shortcut`, {
        entity_ids: arrayEntityIds,
    });
};

requests.deleteShortcut = async (arrayEntityIds) => {
    return post(`${statistic}/delete-shortcut`, {
        entity_ids: arrayEntityIds,
    });
};

/* 
    We are making 2 seperate API calls for MAP view & LIST view
    Taking type as input parameter to differentiate between them
*/
requests.allEvents = async (view, query = {}) => {
    let events;
    if (view === ViewEnum.LIST) {
        events = await requests.eventList(query);
        if (events?.filters?.region) {
            [events.filters.region = [], events.filters.country = []] =
                parseRegionCountry(events?.filters?.region, LOCATION_TYPE);
            //  = regions || [];
            //  = countries || [];
        }

        return events;
    }

    if (view === ViewEnum.MAP) {
        return await requests.eventsMap(query);
    }
    // Replace this with actual implementation
    return {
        total: 0,
        data: [],
    };
};

requests.savedEvents = async (view, query = {}) => {
    if (view === ViewEnum.LIST) {
        return await requests.eventList({
            ...query,
            filter: {
                ...(query?.filter ?? {}),
                saved: true,
            },
        });
    }

    if (view === ViewEnum.MAP) {
        return await requests.eventsMap({
            ...query,
            filter: {
                ...(query?.filter || {}),
                saved: true,
            },
        });
    }
};

const parseEventsfilter = (data) => {
    const transformDateRange = (dateRange) => {
        const startDate = DateViewer.getDateText(dateRange?.date_start);
        const endDate = DateViewer.getDateText(dateRange?.date_end);
        return {
            startDate,
            endDate,
        };
    };

    const isFacetChecked = (item, key) => {
        if (data.filters[key] && data.filters[key].length !== 0) {
            return data.filters[key].includes(item.label);
        }

        return true;
    };

    for (const [key, value] of Object.entries(data.facets)) {
        switch (key) {
            case 'category':
            case 'theme':
                data.facets[key] = data.facets[key].map((item, index) => {
                    return {
                        id: `${key}_${index}`,
                        label: `${item.label} (${item.count})`,
                        checked: isFacetChecked(item, key),
                        queryKey: `${item.label}`,
                    };
                });
                break;
            case 'date_range':
                data.facets[key] = transformDateRange(data.facets[key]);
                break;
            case 'region':
            case 'country':
                data.facets[key] = data.facets[key].map((item, index) => {
                    return {
                        id: `${key}_${index}`,
                        label: `${item.label} (${item.count})`,
                        checked: Boolean(
                            data.filters[key]?.includes(item.label)
                        ),
                        queryKey: `${item.label}`,
                        type: key,
                    };
                });
                break;
        }
    }
    data.facets['keyword'] = data.filters['keyword'];

    return data;
};

requests.eventList = async (query = {}) => {
    const size = query.size || 10;
    const { data } = await post(`${event}/list`, {
        ...query,
        page: query?.page ?? 1,
        size,
    });
    data.data = data?.data ?? [];
    data.data = data?.data?.map((event) => {
        [event.categories] = safeParse(event.json_category, []);
        [event.themes] = safeParse(event.json_theme, []);
        return {
            ...event,
            isSaved: !!event.is_saved_event,
            title: event.description,
            country: event?.location?.country,
            date: event.date,
        };
    });

    return parseEventsfilter(data);
};

requests.eventsMap = async (query) => {
    const { data } = await post(`${event}/list`, {
        ...query,
        page: query.page ?? 1,
        size: query.size ?? 1000,
    });

    data.data = data.data.map((event) => {
        [event.categories] = safeParse(event.json_category, []);
        const [lng, lat] = event.location.geolocation.coordinates;
        const { country, city } = event.location;

        return {
            lat,
            lng,
            data: {
                country,
                city,
                title: event.description,
                date: event.date,
                categories: event.categories,
                eventId: event.id,
                isSaved: event.is_saved_event,
            },
        };
    });

    return parseEventsfilter(data);
};

requests.getPDF = async (entityId) => {
    return get(
        `${rdc}/get-pdf?entity_id=${entityId}`,
        {},
        (response) => response
    );
};

requests.getDashboard = async (query = {}) => {
    const params = {
        ...query,
    };
    const transformer = (response = {}) => {
        const {
            data: { config },
        } = response;
        try {
            let parsedConfig = JSON.parse(config || []);
            if (!Array.isArray(parsedConfig)) {
                parsedConfig = []; // Prevent bad config from loading
            }
            return parsedConfig;
        } catch (error) {
            logger.error(error);
            return [];
        }
    };
    return get(`${dashboard}/get-dashboard`, { params }, transformer);
};

requests.updateDashboard = async (dashboardConfig = []) => {
    for (const _dashboardConfig of dashboardConfig) {
        _dashboardConfig.uniqueId =
            _dashboardConfig.uniqueId || nanoid(UNIQUE_KEY_LEN);
    }
    const body = {
        config: JSON.stringify(dashboardConfig),
    };
    return post(`${dashboard}/update-dashboard`, body);
};

requests.removeDashboardTile = async (tileUniqueId, dashboardConfig) => {
    const filteredDashboardConfig = dashboardConfig.filter(
        (dashboardConfigItem) => dashboardConfigItem.uniqueId !== tileUniqueId
    );
    return await requests.updateDashboard(filteredDashboardConfig);
};

requests.trendingThemes = async (query = {}) => {
    query = { interval: 30, ...query };
    const { data } = await get(`${statistic}/trending/theme`, {
        params: query,
    });

    return data.stats;
};

requests.trendingEvents = async (query = {}) => {
    query = { interval: 30, ...query };
    const { data } = await get(`${event}/trending`, {
        params: query,
    });

    const location = ({ state, country }) =>
        [state, country].filter(Boolean).join(', ');

    return (data ?? []).map((stat) => ({
        id: stat.id,
        title: stat.description,
        location: location(stat.location),
    }));
};

requests.regionsAndCountries = async (query = {}) => {
    const { data } = await post(`${event}/facet`, { ...query });
    const [regions, countries] = parseRegionCountry(data || [], LOCATION_TYPE);
    return [...regions, ...countries];
};

requests.entityPole = async (queryArg = {}, isEvent) => {
    const { entityId, page, size, filters } = queryArg;
    const { score_max: relevance_max, score_min: relevance_min } =
        filters || {};
    const { data } = await get(
        `${isEvent ? event : entity}/pole`,
        {
            params: {
                ...(isEvent ? { event_id: entityId } : { entity_id: entityId }),
                page,
                size: size || 15,
                relevance_max,
                relevance_min,
            },
        },
        (response) => {
            const data = response?.data ?? {};
            // Component recieves data in pole key
            const poleKey = isEvent ? 'pol' : 'poles';
            data.data = data.data ?? {};

            // Sort poles
            data.data = PoleDataSort.reduce((acc, pole) => {
                if (data.data[pole]) {
                    acc[pole] = data.data[pole];
                }
                return acc;
            }, {});
            for (const _pole in data.data) {
                data.data[_pole] = data.data[_pole].data.map((poleData) => ({
                    ...poleData,
                    type:
                        PoleTypeMap[poleData?.type ?? _pole] ?? poleData?.type,
                    score: poleData?.weight ?? poleData.relevance,
                }));
                if (PoleToNameMap[_pole]) {
                    data.data[PoleToNameMap[_pole]] = data.data?.[_pole];
                    delete data.data[_pole];
                }
            }
            // @TODO: Remove this when API is fixed
            // Track it here https://acquiremedia.atlassian.net/browse/NXGNAPI-78?focusedCommentId=88025
            data.summary = {
                scoreRange: [
                    { min: 0, max: 0.1, count: 12 },
                    { min: 0.1, max: 0.2, count: 9 },
                    { min: 0.2, max: 0.3, count: 7 },
                    { min: 0.3, max: 0.4, count: 12 },
                    { min: 0.4, max: 0.5, count: 9 },
                    { min: 0.5, max: 0.6, count: 9 },
                    { min: 0.6, max: 0.7, count: 13 },
                    { min: 0.7, max: 0.8, count: 14 },
                    { min: 0.8, max: 0.9, count: 11 },
                    { min: 0.9, max: 1, count: 10 },
                ],
                total: data?.total ?? null,
            };
            data[poleKey] = data.data;
            return response;
        }
    );
    return data;
};

requests.updateUserEvent = (eventId, save = true) => {
    const body = {
        events: { [eventId]: save },
    };
    if (save) {
        GaEventsMap.eventSave(eventId);
    }
    return post(`${event}/update-user-event`, body);
};

requests.getEventArticleById = async ({ articleId }) => {
    const eventArticleTransformer = (response) => {
        /* 
            API returning data in [], should be in object
            adding a bypass to fix this
        */
        const data = Array.isArray(response.data)
            ? response.data[0]
            : response.data;

        const {
            article_id: articleId,
            body,
            publication,
            date_published: publishDate,
            level: riskSentiment,
            json_category: jsonCats,
            source,
            title,
            url,
            license,
        } = data;

        const isAllowed = license?.value === 'true' ? true : false;

        const newData = {
            id: `${articleId}`,
            title,
            articleId,
            body,
            publication,
            publishDate,
            riskSentiment: riskSentiment || '',
            riskCategories: getUniqueArrFromJson(jsonCats) || [],
            source,
            url,
            isAllowed: isAllowed === 1 ? true : false,
            snippets: '',
        };

        return { ...response, data: newData };
    };
    const response = await get(
        `${event}/article/read/${articleId}`,
        {},
        eventArticleTransformer
    );
    return response;
};

// export default requests;
