import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from './store';
import AppWrapper from './AppWrapper';
import TranslationJson from './translations.json';
import TranslationWorker from '@bit/modus-moodys.mapulseui.translation-worker';
TranslationWorker.initTranslations(TranslationJson || {});

// Render DOM
// <React.StrictMode>

ReactDOM.render(
    <Provider>
        <AppWrapper />
    </Provider>,
    document.getElementById('root')
);

/* 
    No. of times this comment has been modified just to trigger CMS build ;')
    -
    3
*/
