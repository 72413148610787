import Analytics from '@bit/modus-moodys.mapulseui.analytics';
import Button from '@bit/modus-moodys.mapulseui.button';
import SearchById from '@bit/modus-moodys.mapulseui.search-by-id';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { requests } from '../../requests';
import './styles.scss';

let AnalyticsInstance;
const panelsById = {
    OnBoardSearchId: ({ addPanel, item }) => {
        const handleCheckPogress = item.props?.handleCheckPogress;
        const [errState, setErrState] = useState(null);

        const onSubmit = async (orbisIds) => {
            orbisIds = orbisIds.map((i) => i.trim()).filter((i) => i.length);
            if (!orbisIds.length) {
                setErrState({ id: 'lbl_empty_orbis_id' });
                return;
            } else if (orbisIds.length > 1000) {
                setErrState({ id: 'lbl_max_orbis_error' });
                return;
            } else {
                setErrState(null);
            }
            await requests
                .onboardBulk({ orbisIds })
                .then((response) => {
                    if (response && response.data) {
                        addPanel('OnBoardConfirmId', {
                            ...response.data,
                            handleCheckPogress,
                        });
                        AnalyticsInstance =
                            AnalyticsInstance || new Analytics();
                        AnalyticsInstance.sendEvent('add_entity_end');
                    }
                })
                .catch(() => {
                    setErrState({ id: 'rsp_default_error' });
                });
        };

        return (
            <div className="panel1">
                <Translation>
                    {(t) => (
                        <React.Fragment>
                            <h1
                                className="title heading-2"
                                style={{ paddingBottom: '30px' }}
                            >
                                {`${t('lbl_search_by', {
                                    defaultValue: 'Search by ',
                                })} Orbis ID`}
                            </h1>
                            <hr />
                            <SearchById
                                placeholder="Enter ID"
                                onSubmit={onSubmit}
                                labelTexts={{
                                    emptyState: t('lbl_empty_state_orbis'),
                                    uploadCsvFile: t('lbl_upload_csv'),
                                    or: 'Or',
                                    submit: t('lbl_submit'),
                                    textInputLabel: `${t(
                                        'lbl_enter'
                                    )} Orbis ID(s)`,
                                    orbisInfoText: t('lbl_orbis_id_info_text'),
                                }}
                                error={
                                    errState?.id
                                        ? { message: t(errState.id) }
                                        : null
                                }
                            />
                        </React.Fragment>
                    )}
                </Translation>
            </div>
        );
    },

    OnBoardConfirmId: ({
        item,
        hideFromIndex,
        // removePanel
    }) => {
        const {
            not_found_ids = [],
            added_to_portfolio_count = 0,
            queued_count = 0,
            invalid_ids = [],
            duplicate_ids = [],
            handleCheckPogress,
        } = item.props || {};
        // add the transformer instead of reaching for deep properties.
        const onCheckProgressClick = () => {
            if (typeof handleCheckPogress === 'function') handleCheckPogress();
            hideFromIndex(-1);
        };
        return (
            <Translation>
                {(t) => (
                    <div className="panel3">
                        <h1
                            className="title heading-2"
                            style={{ paddingBottom: '30px' }}
                        >
                            {`${t('lbl_search_by', {
                                defaultValue: 'Search by ',
                            })} Orbis ID`}
                        </h1>

                        {added_to_portfolio_count && queued_count ? (
                            <hr />
                        ) : null}
                        <div className="entity-onboard-stat">
                            {added_to_portfolio_count ? (
                                <span className="onboard-stat-item">
                                    <span className="item-count">
                                        {`${added_to_portfolio_count} `}
                                    </span>
                                    {t('lbl_added_portfolio', {
                                        count: added_to_portfolio_count,
                                    })}
                                </span>
                            ) : null}
                            {queued_count ? (
                                <span className="onboard-stat-item">
                                    <span className="item-count">{`${queued_count} `}</span>
                                    {t('msg_entity_inprogress', {
                                        count: added_to_portfolio_count,
                                    })}

                                    <Button
                                        cls="progress-check-btn"
                                        isPrimary={false}
                                        label={t(
                                            'lbl_check_download_progress',
                                            {
                                                defaultValue:
                                                    'Check the download progress',
                                            }
                                        )}
                                        onClick={onCheckProgressClick}
                                    ></Button>
                                </span>
                            ) : null}
                        </div>
                        <hr />
                        {not_found_ids.length ? (
                            <div className="response-stats">
                                <span className="heading-6">
                                    {t('msg_entity_not_found', {
                                        count: not_found_ids.length,
                                    })}
                                </span>
                                <span>
                                    {not_found_ids
                                        .map((i) => `"${i}"`)
                                        .join(' , ')}
                                </span>
                            </div>
                        ) : null}
                        {duplicate_ids.length ? (
                            <div className="response-stats">
                                <span className="heading-6">
                                    {t('msg_already_in_portfolio', {
                                        count: duplicate_ids.length,
                                    })}
                                    {/* {`${duplicate_ids.length} `}
                                    {`${
                                        duplicate_ids.length === 1 ? 'entity was' : 'entities were'
                                    } already in your portfolio:`} */}
                                </span>
                                <span>
                                    {duplicate_ids
                                        .map((i) => `"${i}"`)
                                        .join(' , ')}
                                </span>
                            </div>
                        ) : null}
                        {invalid_ids.length ? (
                            <div className="response-stats">
                                <span className="heading-6">
                                    {t('msg_invalid_entity', {
                                        count: invalid_ids.length,
                                    })}
                                    {/* {`${invalid_ids.length} `}
                                    {`${
                                        invalid_ids.length === 1 ? 'entity is' : 'entities are'
                                    } invalid: `} */}
                                </span>
                                <span>
                                    {invalid_ids
                                        .map((i) => `"${i}"`)
                                        .join(' , ')}
                                </span>
                            </div>
                        ) : null}
                        {/* <EntitySearchConfirmationRow entity={item.props.data[0]} /> */}
                        <div className="buttons-wrapper">
                            <Button
                                label={t('lbl_close', {
                                    defaultValue: 'Close',
                                })}
                                isPrimary={false}
                                onClick={() => hideFromIndex(-1)}
                            />
                        </div>
                    </div>
                )}
            </Translation>
        );
    },
};

panelsById.OnBoardSearchId.propTypes = {
    addPanel: PropTypes.func,
};
panelsById.OnBoardConfirmId.propTypes = {
    addPanel: PropTypes.func,
    removePanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};

panelsById.OnBoardSearchId.defaultProps = {
    addPanel: PropTypes.func,
};
panelsById.OnBoardConfirmId.defaultProps = {
    addPanel: () => {},
    removePanel: () => {},
    item: {},
};

export default panelsById;
