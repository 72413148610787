import React, { useEffect, useState } from 'react';
import BrowserSupportError from '@bit/modus-moodys.mapulseui.browser-support-error';
import App from './App';
import { TestingPaneProvider } from '@hooks/useTestingPane';

/**
 *
 * @author ADNAN HUSAIN
 * This component check which browser is being used. If internet explorer then returns error state else loads the app.
 */
const AppWrapper = () => {
    // IE error is handled in public/index.html
    const [isIEBrowser, setIsIEBrowser] = useState(false);
    const checkIsIEBrowser = () => {
        return (
            navigator.userAgent.indexOf('MSIE') > 0 ||
            navigator.userAgent.indexOf('Trident') > 0
        );
    };
    useEffect(() => {
        setIsIEBrowser(checkIsIEBrowser());
    }, []);
    return isIEBrowser ? (
        <BrowserSupportError
            supportedBrowsers={[
                {
                    label: 'Chrome',
                    imgUrl: '%PUBLIC_URL%/images/chrome_logo.png',
                    id: 'chrome-logo',
                },
                {
                    label: 'Firefox',
                    imgUrl: '%PUBLIC_URL%/images/ff_logo.png',
                    id: 'firefox-logo',
                },
                {
                    label: 'Safari',
                    imgUrl: '%PUBLIC_URL%/images/safari_logo.png',
                    id: 'safari-logo',
                },
                {
                    label: 'Edge',
                    imgUrl: '%PUBLIC_URL%/images/edge_logo.png',
                    id: 'edge-logo',
                },
            ]}
        />
    ) : (
        <>
            <TestingPaneProvider>
                <App />
            </TestingPaneProvider>
        </>
    );
};
export default AppWrapper;
