const Joi = require('joi');
const schemas = require('@bit/modus-moodys.mapulseui.validation-schema');

module.exports = schemas.requestSchema.keys({
    SRC_PATH: Joi.string().required(),
    PUBLIC_PATH: Joi.string().required(),
    APP_NAME: Joi.string().required(),
    REACT_APP_NODE_ENV: Joi.string()
        .pattern(/(local|development|prod|uat)/)
        .required(),
    PUBLIC_APP_URL: Joi.string().required(),
    POST_BUILD_MUTATION: Joi.array().items(
        Joi.object({
            head: Joi.string().required(),
            body: Joi.string().required(),
        })
    ),
    GMAP_KEY: Joi.string().required(),
});
