export const DEFAULT_INCLUDES = [
    'cam_aggregate.entity_id',
    'is_company',
    'name',
    'location',
    'last_viewed',
    'date_added',
    'last_update_attempted_count',
    'last_update_completed_date',
    'date_news_last_created',
    'is_updating',
    'latest_css_trendline',
    'latest_css_score',
    'css_score_delta',
    'latest_css_date',
    'cam_aggregate.total_high_count',
    'cam_aggregate.total_medium_count',
    'cam_aggregate.total_low_count',
];

// Time to debounce the fetch request in Milliseconds.
export const DEFAULT_DEBOUNCE_TIMER = 50;

export const getDefaultSortOptions = (t) => {
    return [
        {
            cls: 'is-company',
            id: 'name',
            label: t('row_header_name', { defaultValue: 'Name' }),
            type: 'ASC',
            selected: true,
        },
        {
            cls: 'location',
            id: 'location',
            label: null,
            type: 'DESC',
            selected: null,
        },
        {
            cls: 'credit-sentiment-score',
            id: 'num_high',
            label: t('lbl_high_risk_hyphen_sepr', {
                defaultValue: 'High-risk',
            }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'credit-sentiment-score',
            id: 'num_medium',
            label: t('lbl_med_risk_hyphen_sepr', { defaultValue: 'Med-risk' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'credit-sentiment-score',
            id: 'num_low',
            label: t('lbl_low_risk_hyphen_sepr', { defaultValue: 'Low-risk' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'last-viewed',
            id: 'last_viewed',
            label: t('lbl_last_viewed', { defaultValue: 'Last viewed' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'last-news-found',
            id: 'date_news_last_created',
            label: t('label_news_updated', {
                defaultValue: 'Last News Found',
            }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'date-added',
            id: 'date_added',
            label: t('row_header_date_added', { defaultValue: 'Date added' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'rdc-risk-profile',
            id: null,
            label: t('row_header_rdc_risk_profile', {
                defaultValue: 'Risk Profile',
            }),
            type: null,
            selected: null,
        },
    ];
};

export const DEFAULT_SORT = { name: 'ASC' };

// Max number of rows returned from api
export const DEFAULT_PAGE_SIZE = 30;

export const DEFAULT_PAGE_FILTER = { page: 1, pages: 1, disabled: false };

export const DEFAULT_MAX_SHORTCUTS = 20;
