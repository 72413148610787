import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { useTranslation } from 'react-i18next';
import SettingTileGroup from '@bit/modus-moodys.mapulseui.setting-tile-group';
import SelectWatchlist from './SelectWatchlist';
import SelectLocation from './SelectLocation';
import {
    RISK_SCOPES,
    EVENT_SCOPES,
} from 'pages/PageDashboard/TileView/Helpers/constants';

const panelsWidget = {
    EditWidget: ({ item, addPanel, hideFromIndex }) => {
        const { scope, userWatchlistId } = item.props || {};
        const { t } = useTranslation();

        const SETTING_LABELS = {
            ALL: t('lbl_everything_in_system', {
                defaultValue: '<b>Everything</b> in the system',
            }),
            PORTFOLIO: t('lbl_everything_in_portfolio', {
                defaultValue: 'Everything in my <b>portfolio</b>',
            }),
            WATCHLIST: t('lbl_choose_watchlist', {
                defaultValue: 'Choose <b>watchlist</b>',
            }),
        };

        const items = Object.keys(RISK_SCOPES).map((type) => {
            return {
                label: SETTING_LABELS[type],
                id: type,
                isChecked: scope === RISK_SCOPES[type],
            };
        });

        const handleTileSelection = (id) => {
            if (RISK_SCOPES[id] === RISK_SCOPES.WATCHLIST) {
                addPanel('SelectWatchlist', {
                    userWatchlistId,
                });
            } else {
                hideFromIndex(-1, {
                    scope: RISK_SCOPES[id],
                });
            }
        };
        return (
            <div className="manage-widget">
                <h1 className="title heading-1 header">Edit Widget</h1>
                <span className="sub-title heading-9">
                    Configure your widget
                </span>
                <hr className="seperator" />
                <SettingTileGroup tiles={items} onClick={handleTileSelection} />
            </div>
        );
    },

    SelectWatchlist: ({ item, hideFromIndex }) => {
        return (
            <div className="manage-widget">
                <SelectWatchlist item={item} hideFromIndex={hideFromIndex} />
            </div>
        );
    },
    EditEvent: ({ item, addPanel, hideFromIndex }) => {
        const { scope, selectedLocations } = item.props || {};
        const { t } = useTranslation();

        const SETTING_LABELS = {
            ALL: t('lbl_across_all_region', {
                defaultValue: 'Across <b>all regions</b>',
            }),
            SPECIFIC: t('lbl_specific_regions', {
                defaultValue: 'From <b>Specific regions</b>',
            }),
        };

        const items = Object.keys(EVENT_SCOPES).map((type) => {
            return {
                label: SETTING_LABELS[type],
                id: EVENT_SCOPES[type],
                isChecked: scope === EVENT_SCOPES[type],
            };
        });

        const handleTileSelection = (id) => {
            if (EVENT_SCOPES.ALL === id) {
                hideFromIndex(-1, {
                    scope: EVENT_SCOPES.ALL,
                });
                return;
            }
            addPanel('SelectLocation', {
                scope: EVENT_SCOPES.SPECIFIC,
                selectedLocations,
                hideFromIndex,
            });
        };

        return (
            <div className="manage-widget">
                <h1 className="title heading-1 header">Edit Widget</h1>
                <span className="sub-title heading-9">
                    Configure your widget
                </span>
                <hr className="seperator" />
                <SettingTileGroup tiles={items} onClick={handleTileSelection} />
            </div>
        );
    },
    SelectLocation: ({ item, hideFromIndex }) => {
        return <SelectLocation item={item} hideFromIndex={hideFromIndex} />;
    },
};

panelsWidget.EditWidget.defaultProps = {
    addPanel: () => {
        logger.log('add panel not declared in props');
    },
    hideFromIndex: () => {
        logger.log('hide from index not declared in props');
    },
};

panelsWidget.EditEvent.defaultProps = {
    addPanel: () => {},
    hideFromIndex: () => {},
};

panelsWidget.EditWidget.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
};

panelsWidget.EditEvent.propTypes = {
    addPanel: PropTypes.func,
    hideFromIndex: PropTypes.func,
};

export default panelsWidget;
