import { getNotRelevantOptions, RiskSentimentLabelMapping } from './defaults';
import logger from '@bit/modus-moodys.mapulseui.logger';

export const getArticlesWithProps = ({
    t,
    articles,
    activeFolder,
    handleManageFolder,
    folders,
}) => {
    return (articles || []).map((article) => ({
        ...article,
        riskSentiment:
            RiskSentimentLabelMapping[article.riskSentiment?.toLowerCase()],
        labelTexts: {
            riskCategories: t('lbl_risk_category', { count: 12 }),
        },
        isOpenNewWindow: Boolean(article.url),
        articleActionProps: {
            disableNotRelevant: activeFolder.id === 'not_relevant',
            labelTexts: {
                labelSave: t(`lbl_save`),
                labelNotRelevant: t('lbl_not_relevant'),
            },
            articleNotRelevantFormProps: {
                checkboxItems: [...getNotRelevantOptions(t)],
                labelTexts: {
                    submitLabel: t('lbl_done'),
                    skipLabel: t('lbl_skip'),
                    formTitle: t('lbl_not_relevant_title'),
                    placeholder: `${t('lbl_enter')} ${t('lbl_reason')}`,
                },
            },
            folderSelectProps: {
                placeholder: t('lbl_search_item', {
                    item: t('lbl_folder'),
                }),
                onCreateFolderClick: handleManageFolder,
                folders: [
                    {
                        label: t('lbl_news_item', { item: t('lbl_feed') }),
                        id: 'news-feed',
                        icon: 'list',
                    },
                    ...folders,
                ].filter((folder) => folder.id !== activeFolder.id),
                labelTexts: {
                    title: t('title_folder_popper'),
                    noFolderTitle: t('lbl_no_folder_title'),
                    createFolderButtonText: `${t('lbl_create')} ${t(
                        'lbl_folder'
                    )}`,
                    lblDone: t('lbl_done'),
                    noFoldersFound: t('lbl_no_folder'),
                },
            },
        },
    }));
};

/*
          action - 'add' | 'update | 'remove'
    */
export const updateFolderItemList =
    (setFolders, handleActiveFolderChange) => (action, props) => {
        switch (action) {
            case 'add':
                setFolders((folder) => (folder = [...folder, props]));
                break;
            case 'update':
                setFolders(
                    (folder) =>
                        (folder = folder.map((_folder) =>
                            _folder.id === props.id ? { ...props } : _folder
                        ))
                );
                break;
            case 'delete':
                setFolders(
                    (folder) =>
                        (folder = folder.filter((i) => i.id !== props.id))
                );
                handleActiveFolderChange({ id: 'news-feed' });
                break;
            default:
                break;
        }
    };
export const handleManageFolder = (
    addPanel,
    setFolders,
    handleActiveFolderChange
) => {
    addPanel('CreateOrEditFolder', {
        updateFolderItemList: updateFolderItemList(
            setFolders,
            handleActiveFolderChange
        ),
    });
};

export const moveArticleToFolder = async (
    params,
    setArticles,
    updateArticleToFolder
) => {
    try {
        await updateArticleToFolder({
            articleId: params.articleId,
            userFolderId:
                params.data.folderId === 'news-feed'
                    ? null
                    : params.data.folderId,
        });
        setArticles((e) => e.filter((i) => i.articleId !== params.articleId));
    } catch (err) {
        logger.log(err);
    }
};
export const markArticleNotRelevant = async (
    params,
    setArticles,
    moveArticleToNotRelavant
) => {
    const notRelevantReasons = (params.data.reasons || [])
        .filter((i) => i.checked)
        .map((i) => i.name);
    try {
        await moveArticleToNotRelavant({
            articleId: params.articleId,
            reasons: notRelevantReasons?.length
                ? notRelevantReasons
                : ['not-relevant'],
        });
        setArticles((e) => e.filter((i) => i.articleId !== params.articleId));
    } catch (err) {
        logger.log(err);
    }
};
