import React from 'react';
import RibbonMenuAction from '@bit/modus-moodys.mapulseui.ribbon-menu-action';
import Header from '@bit/modus-moodys.mapulseui.header';
import { useAppContext } from '@store';
import { useTranslation } from 'react-i18next';
import UserMenu from '@bit/modus-moodys.mapulseui.user-menu';
import { requests } from '@requests';
import { Router } from 'react-router-dom';
import PageRouter from '@pages/PageRouter';
import PanelWrapper from '@bit/modus-moodys.mapulseui.panel-wrapper';
import panelProps from '../../flows';
import Footer from '@bit/modus-moodys.mapulseui.footer';
import { footerMarkup, legalMarkup } from '../../copy-text';
import DisclaimerModal from '@bit/modus-moodys.mapulseui.disclaimer-modal';
import dayjs from 'dayjs';
import logger from '@bit/modus-moodys.mapulseui.logger';
import logo from '../../logo.png';

const { updateUserPreferences } = requests;

const PageHome = () => {
    const {
        stRibbonMenu,
        stUserInfo,
        history,
        stPanels,
        actSetPanels,
        stDisclaimerConfirmed,
        actSetDisclaimerConfirmed,
    } = useAppContext();
    const { t } = useTranslation();
    const { panelsModel, addPanel, removePanel, hideFromIndex } = panelProps(
        stPanels,
        actSetPanels
    );

    const handleDisclaimerClose = async (confirmed) => {
        const resp = await updateUserPreferences({ isDisclaimer: confirmed });
        logger.log(resp);
        actSetDisclaimerConfirmed(confirmed);
    };
    const handleMenuAction = (e, id) => {
        switch (id) {
            case 'val_contact':
                history.go('/support');
                break;
            case 'val_signout':
                requests.logout();
                break;
            case 'val_settings':
                break;
            default:
                break;
        }
    };
    const items = [
        [
            {
                label: t('user_menu_contact_support', {
                    defaultValue: 'Contact Support',
                }),
                id: 'val_contact',
            },
        ],
        [
            {
                label: t('user_menu_logout', { defaultValue: 'Log out' }),
                id: 'val_signout',
            },
        ],
    ];
    return (
        <React.Fragment>
            <div className="side-nav">
                {stRibbonMenu.map((e) => {
                    const { label, icon, clicked, isActive } = e;
                    return (
                        <RibbonMenuAction
                            label={label}
                            icon={icon}
                            clicked={clicked}
                            key={`ribbon-menu-option-${label}`}
                            isActive={isActive}
                        />
                    );
                })}
            </div>
            <Header
                cls="ma-pulse-header"
                label={t('app_name_government', {
                    defaultValue: 'NewsEdge Events',
                })}
                rightComponent={
                    <div className="header-menu-wrapper">
                        {stUserInfo && (
                            <UserMenu
                                userInfo={stUserInfo}
                                items={items}
                                clicked={handleMenuAction}
                            />
                        )}
                        {/* <LanguageSelector /> */}
                    </div>
                }
                onLogoClick={() => {
                    history.push('/');
                }}
                logoUrl={logo}
            />
            <Router history={history}>
                <PageRouter />
            </Router>
            <PanelWrapper
                cls="credit articlePopupOverride"
                tag={null}
                panelsModel={panelsModel}
                addPanel={addPanel}
                removePanel={removePanel}
                currentPanels={stPanels}
                hideFromIndex={hideFromIndex}
                containerProps={{
                    labelTexts: {
                        back: t('lbl_back'),
                    },
                }}
            />
            <Footer
                label={t('footer_powered_by', { defaultValue: 'Powered by:' })}
                markup={`${t('block_footer_disclaimer_government', {
                    defaultValue: footerMarkup,
                    currentYear: dayjs().year(),
                    link: `/copyright`,
                }).replace(/\n/gim, '<br/>')}`}
                logoUrl={logo}
            />
            {!stDisclaimerConfirmed ? (
                <DisclaimerModal
                    title="ACCESS AGREEMENT"
                    instructions="Scroll down and click 'I agree'."
                    content={{
                        __html: `${t('lbl_legal_markup_government', {
                            defaultValue: legalMarkup,
                        })}`,
                    }}
                    checkLabel="I understand"
                    btnLabel="I agree"
                    onClose={handleDisclaimerClose}
                />
            ) : null}
        </React.Fragment>
    );
};

export default PageHome;
