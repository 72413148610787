import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import { TabsController } from '@bit/modus-moodys.mapulseui.tabs-manager';
import TwoColTitle from '@bit/modus-moodys.mapulseui.two-col-title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tabsControllers } from '../../constants';

const Header = ({
    twoColPaths,
    eventDescription,
    defaultTabId,
    onTabChange,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div className="title-ribbon">
            <TwoColTitle
                breadcrumb={{
                    paths: twoColPaths ?? [],
                    onClick: (pathId, pathRoute) => history.go(pathRoute),
                }}
                leftComponent={
                    eventDescription && (
                        <SvgLib type="pin-icon" cls="pin-icon" />
                    )
                }
                title={eventDescription || '...'}
                rightComponent={<></>}
            />
            <TabsController
                items={tabsControllers(t)}
                onClick={onTabChange}
                defaultTab={defaultTabId}
            />
        </div>
    );
};

export default Header;
