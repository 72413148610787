import TrendingRiskCategoriesTile from './trending-risk-categories';
import TrendingEventsTile from './trending-events';
import SavedEventsTile from './saved-events';

export const AvailableTiles = {
    trending_risk_categories: TrendingRiskCategoriesTile,
    trending_events: TrendingEventsTile,
    saved_events: SavedEventsTile,
};

export const AllTileslabel = {
    trending_risk_categories: 'lbl_trending_risk_cat',
    trending_events: 'lbl_trending_events',
    saved_events: 'lbl_saved_events',
};

export const AllTilesDefaultlabel = {
    trending_risk_categories: 'Trending Risk Categories',
    trending_events: 'Trending Events',
    saved_events: 'Saved Events',
};

export const AvailableTilesEnum = {
    trending_risk_categories: 'trending_risk_categories',
    trending_events: 'trending_events',
    saved_events: 'saved_events',
};

export const TilesEditPanelMapping = {
    trending_risk_categories: 'EditWidget',
    trending_events: 'EditEvent',
    saved_events: 'EditEvent',
};
