/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import ArticleFolderRibbon from '@bit/modus-moodys.mapulseui.article-folder-ribbon';
import ArticleRow from '@bit/modus-moodys.mapulseui.article-row';
import Button from '@bit/modus-moodys.mapulseui.button';
import ConfirmationPopup from '@bit/modus-moodys.mapulseui.confirmation-popup';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import DropdownList from '@bit/modus-moodys.mapulseui.dropdown-list';
import EntityRowMinimal from '@bit/modus-moodys.mapulseui.entity-row-credit-pulse-minimal';
import FilterKeywordSearch from '@bit/modus-moodys.mapulseui.filter-keyword-search';
import FilterRiskLevel from '@bit/modus-moodys.mapulseui.filter-risk-level';
import FilterTitleAndTooltip from '@bit/modus-moodys.mapulseui.filter-title-and-tooltip';
import FiltersContainer from '@bit/modus-moodys.mapulseui.filters-container';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import { Tabs, TabsController } from '@bit/modus-moodys.mapulseui.tabs-manager';
import TwoColTitle from '@bit/modus-moodys.mapulseui.two-col-title';
import {
    DEFAULT_DATE_RANGE,
    DEFAULT_DEBOUNCE_TIMER,
    DEFAULT_INCLUDES,
    DEFAULT_PAGE_DATA,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT,
    DEFAULT_SORT_OPTIONS,
    RelGraphLabelTexts,
    RelgraphPoleLabelGenerator,
    RiskLevelOrder,
    RiskSentimentLabelMapping,
} from './defaults';
import panelProps from '../../flows';
import useDidMount from '../../hooks/useDidMount';
import { requests } from '../../requests';
import { useAppContext } from '../../store';
import './styles.scss';
import TabSentimentAnalysis from './Tabs/TabSentimentAnalysis';
import RelGraphLayout from '@bit/modus-moodys.mapulseui.rel-graph-layout';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { useLocation } from 'react-router-dom';
import { PoleAction, sendFilterTagEvent } from '@helpers/ga-events';
import {
    getArticlesWithProps,
    handleManageFolder,
    markArticleNotRelevant,
    moveArticleToFolder,
} from './utils';

const tabNameToIdMap = {
    news: 0,
    related: 1,
    analysis: 2,
};
const getDefaultTabId = (query) => {
    const tabName = new URLSearchParams(query).get('tab');
    return tabName && tabNameToIdMap[tabName] ? tabNameToIdMap[tabName] : 0;
};

const {
    getEntity,
    updateArticleToFolder,
    moveArticleToNotRelavant,
    getArticle,
} = requests;
const PageDetails = ({ cls, id, tag, twoColPaths, isEventPage }) => {
    const timeoutId = useRef(null);
    const _cls = `page page-details ${cls || ''}`;
    const hasRendered = useRef(null);
    const { search } = useLocation();
    const defaultTabId = getDefaultTabId(search);
    const [selectedTab, setSelectedTab] = useState(defaultTabId);
    const [selectedDates, setSelectedDates] = useState(DEFAULT_DATE_RANGE);
    const [maxDateRange, setMaxDateRange] = useState(DEFAULT_DATE_RANGE);
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(DEFAULT_PAGE_DATA);
    const [refresh, setRefresh] = useState(false);
    const [sort, setSort] = useState(DEFAULT_SORT);
    const [articles, setArticles] = useState([]);
    const [filterRiskCategory, setFilterRiskCategory] = useState([]);
    const [filterRiskLevel, setFilterRiskLevel] = useState([]);
    const [filterKeywordSearch, setFilterKeywordSearch] = useState([]);
    const [notFoundEntity, setNotFoundEntity] = useState(null);
    const {
        stPanels,
        actSetPanels,
        entityDetails,
        actSetEntityDetails,
        history,
        stUserInfo,
        stfolders: folders,
        actSetFolders: setFolders,
        stActiveFolder: activeFolder,
        actSetActiveFolder: setActiveFolder,
    } = useAppContext();
    const { t } = useTranslation();

    const onTabChange = (i) => {
        setSelectedTab(i);
        const [tab] = Object.entries(tabNameToIdMap).find(([, id]) => id === i);
        history.go({
            search: `?tab=${tab}`,
        });
    };

    const onDateChange = (startDate, endDate) => {
        sendFilterTagEvent();
        setSelectedDates({ startDate, endDate });
    };

    const { addPanel } = panelProps(stPanels, actSetPanels);
    const openDatePickerPanel = () => {
        addPanel('DatePickerPanel', {
            onDateChange,
            selectedDates,
            maxDateRange,
            initialVisibleMonth: () => {
                if (!selectedDates.endDate) {
                    return moment();
                }

                const endDate = DateViewer.getDateMomentObj(
                    selectedDates.endDate
                );
                if (!endDate) return moment();

                return endDate;
            },
        });
    };

    const resetAllFilters = () => {
        sendFilterTagEvent();
        const newRiskLevels = filterRiskLevel.map((lvl) => ({
            ...lvl,
            checked: true,
        }));
        const newCategoriesFilterArray = filterRiskCategory.map((cat) => ({
            ...cat,
            checked: true,
        }));

        setSelectedDates({
            startDate: maxDateRange?.startDate,
            endDate: maxDateRange?.endDate,
        });
        setFilterRiskLevel(newRiskLevels);
        setFilterRiskCategory(newCategoriesFilterArray);
        setFilterKeywordSearch([]);
    };

    const tabsControllers = [
        {
            label: t('lbl_news_item', {
                defaultValue: `News Article`,
                item: t('lbl_article'),
            }),
            tabId: tabNameToIdMap.news,
            enabled: true,
        },
        {
            label: t('lbl_related_entity_plural', {
                defaultValue: 'Related Entities',
            }),
            tabId: tabNameToIdMap.related,
            enabled: true,
        },
        {
            label: t('lbl_sentiment_analysis', {
                defaultValue: 'Sentiment Analysis',
            }),
            tabId: tabNameToIdMap.analysis,
            enabled: true,
        },
    ];
    const getEntityIdFromPageUrl = () =>
        Number(window.location.pathname.split('/')[2]);
    const fetch = async (pageIndex = null) => {
        // NB: This is called inside a useEffect, therefore the setters for state are not batched.
        // To combat this we call this function debounced to allow all state changes to occur before it is recalled.
        const isNotRelevantActive = activeFolder.id === 'not_relevant';
        try {
            const { data } = await getEntity({
                entityId: getEntityIdFromPageUrl(),
                page: pageIndex || pageData.page,
                riskLevel:
                    hasRendered.current && filterRiskLevel.length > 0
                        ? filterRiskLevel
                              .filter(
                                  (level) =>
                                      !!(level?.checked && !level?.disabled)
                              )
                              .map((level) => level.id)
                        : undefined,
                riskCategory: hasRendered.current
                    ? filterRiskCategory
                          .filter((cat) => !!(cat?.checked && !cat?.disabled))
                          .map((cat) => cat.id.toLowerCase())
                    : undefined,
                size: DEFAULT_PAGE_SIZE,
                sort,
                include: DEFAULT_INCLUDES,
                startDate: selectedDates?.startDate,
                endDate: selectedDates?.endDate,
                keyword: filterKeywordSearch,
                notRelevant: isNotRelevantActive,
                userFolderId:
                    isNotRelevantActive || activeFolder.id === 'news-feed'
                        ? undefined
                        : activeFolder.id,
            });

            const {
                page,
                pages,
                data: results,
                filters,
                total,
                entity,
                counts,
                folders: userFolders,
            } = data;

            if (!hasRendered.current) {
                const { searchParams } = new URL(window.location);
                const level = searchParams.get('level');
                const startDate = searchParams.get('startDate');
                const endDate = searchParams.get('endDate');
                const {
                    dateRange,
                    category: riskCategory,
                    sentiment: riskSentiment,
                } = filters;

                unstable_batchedUpdates(() => {
                    setSelectedDates({
                        startDate: startDate ?? dateRange?.startDate,
                        endDate: endDate ?? dateRange?.endDate,
                    });

                    setMaxDateRange({
                        startDate: dateRange?.startDate,
                        endDate: dateRange?.endDate,
                    });

                    setFilterRiskLevel(
                        (riskSentiment ?? [])
                            .filter((i) => i.label)
                            .map(({ label: rowLabel }) => ({
                                label: rowLabel
                                    ? RiskSentimentLabelMapping[
                                          rowLabel.replace(
                                              /([a-z](?=[A-Z]))/g,
                                              '$1 '
                                          )
                                      ]
                                    : '',
                                id: rowLabel,
                                checked: level ? rowLabel === level : true,
                                cls: rowLabel,
                            }))
                            .sort((riskLevel1, riskLevel2) => {
                                return (
                                    RiskLevelOrder.indexOf(
                                        riskLevel1?.label?.toLowerCase()
                                    ) -
                                    RiskLevelOrder.indexOf(
                                        riskLevel2?.label?.toLowerCase()
                                    )
                                );
                            })
                    );

                    setFilterRiskCategory(
                        (riskCategory ?? [])
                            .filter((i) => i.label)
                            .map(({ label, count }) => {
                                return {
                                    label: `${label} (${count})`,
                                    checked: true,
                                    disabled: false,
                                    id: label,
                                };
                            })
                    );
                    setFolders(userFolders || []);
                    actSetEntityDetails({ ...entity, counts });
                });

                hasRendered.current = true;
            } else {
                unstable_batchedUpdates(() => {
                    setPageData({ page, pages, disabled: false, total });
                    setArticles(
                        results.map((i) => ({
                            ...i,
                            riskSentiment: i.riskSentiment || '',
                        }))
                    );
                    setRefresh(false);
                    setLoading(false);
                    setFolders(userFolders || []);
                    actSetEntityDetails({ ...entity, counts });
                });
            }
        } catch (error) {
            if (error.id === 'user_entity_not_found') {
                if (error?.data?.[0]) {
                    setNotFoundEntity(error?.data?.[0]);
                }
            }

            if (error.id === 'shared_entity_not_found') {
                return history.go('/portfolio');
            }

            unstable_batchedUpdates(() => {
                setPageData({ ...pageData, disabled: false });
                setLoading(false);
            });
        }
    };
    const debouncedFetch = (args) => {
        if (timeoutId.current) clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(
            () => fetch(args),
            DEFAULT_DEBOUNCE_TIMER
        );
    };

    useEffect(() => {
        setLoading(true);
        debouncedFetch();
        // On dismount remove current entity details from store.
        return () => actSetEntityDetails(null);
    }, []);
    useEffect(() => {
        return () => {
            setActiveFolder({ id: 'news-feed' });
        };
    }, []);
    useDidMount(() => {
        setLoading(true);
        logger.log(`useDidMount`);
        debouncedFetch(1);
    }, [
        sort,
        filterRiskLevel,
        filterRiskCategory,
        filterKeywordSearch.length,
        selectedDates,
    ]);

    useDidMount(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        debouncedFetch();
    }, [pageData.page]);

    useDidMount(() => {
        if (notFoundEntity !== null) return;
        setLoading(true);
        debouncedFetch();
    }, [notFoundEntity]);

    useDidMount(() => {
        if (refresh === true) {
            setLoading(true);
            debouncedFetch();
        }
    }, [refresh]);

    useDidMount(() => {
        if (stPanels.length === 0) setRefresh(true);
    }, [stPanels]);

    const dispDates = {
        start: (selectedDates.startDate || '').split('T')[0],
        end: (selectedDates.endDate || '').split('T')[0],
    };

    const openArticleView = (articleId) => {
        const article = articles.find((i) => i.articleId === articleId);
        if (article.url) {
            window.open(article.url, '_blank');
        } else {
            addPanel('ArticleViewPanel', {
                articleId,
                stUserInfo,
                handleManageFolder: () =>
                    handleManageFolder(
                        addPanel,
                        setFolders,
                        handleActiveFolderChange
                    ),
            });
        }
        getArticle({ articleId }).then(() => {
            setArticles(
                articles.map((i) =>
                    i.articleId === articleId ? { ...i, unread: false } : i
                )
            );
        });
    };

    const getFolders = () => {
        return [
            ...folders,
            { id: 'not_relevant', label: t('lbl_not_relevant'), icon: 'x' },
        ];
    };
    const handleActiveFolderChange = (folder) => {
        setActiveFolder(folder);
        resetAllFilters();
    };
    const relGrphApiSnapshot = useRef({});
    if (notFoundEntity) {
        return (
            <ConfirmationPopup
                title={t('lbl_add_entity_to_view')}
                buttonActionConfig={{
                    Confirm: {
                        label: t(`lbl_add_to_my_portfolio`),
                        action: () =>
                            requests.addEntity({
                                body: {
                                    entity_id: notFoundEntity.entity_id,
                                    app: 'credit',
                                },
                            }),
                        cbResult: ({ state }) => {
                            if (!state) return;
                            setNotFoundEntity(null);
                        },
                    },
                    Decline: {
                        label: t(`lbl_cancel`),
                        action: () => history.go('/portfolio'),
                    },
                }}
            >
                <EntityRowMinimal {...notFoundEntity} />
            </ConfirmationPopup>
        );
    }
    const sortOption = DEFAULT_SORT_OPTIONS(t);
    const relgraphKeyLableMap = RelgraphPoleLabelGenerator(t);
    const relgraphAPI = async (args = {}) => {
        const data = await requests.entityPole({
            entityId: getEntityIdFromPageUrl(),
            ...args,
        });
        const oldPole = { ...data.poles };
        delete data.poles;
        const newPole = {};
        for (const key in oldPole) {
            newPole[relgraphKeyLableMap[key]] = oldPole[key];
        }
        data.pole = newPole;
        if (
            !Object.keys(relGrphApiSnapshot.current).includes('hasOverallData')
        ) {
            relGrphApiSnapshot.current.hasOverallData =
                data?.summary?.total > 0;
        }
        data.summary.hasOverallData = relGrphApiSnapshot.current.hasOverallData;
        return data;
    };
    return (
        <div id={id} className={_cls} data-tag={tag}>
            <div className="title-ribbon">
                <TwoColTitle
                    breadcrumb={{
                        paths: twoColPaths ?? [],
                        onClick: (pathId, pathRoute) => {
                            logger.log(
                                'breadcrumb: ',
                                id,
                                ' clicked. Navigating...'
                            );
                            history.go(pathRoute);
                        },
                    }}
                    leftComponent={
                        entityDetails?.name && isEventPage ? (
                            <SvgLib type="pin-icon" cls="pin-icon" />
                        ) : (
                            <></>
                        )
                    }
                    title={entityDetails?.name || '...'}
                    rightComponent={<></>}
                />
                <TabsController
                    items={tabsControllers}
                    onClick={onTabChange}
                    defaultTab={defaultTabId}
                />
            </div>
            {selectedTab === 0 && (
                <ArticleFolderRibbon
                    folders={[...getFolders()]}
                    activeFolder={activeFolder}
                    onFolderSelect={handleActiveFolderChange}
                    onManageFolderClick={() =>
                        handleManageFolder(
                            addPanel,
                            setFolders,
                            handleActiveFolderChange
                        )
                    }
                    labelTexts={{
                        newsFeed: t('lbl_news_item', { item: t('lbl_feed') }),
                        selectFolder: t('lbl_select_item', {
                            item: t('lbl_folder'),
                        }),
                        manageFolder: t('lbl_manage_item', {
                            item: t('lbl_folder'),
                        }),
                        noFoldersFound: t('lbl_no_folder'),
                        folderSearchPlaceholder: t('lbl_search'),
                    }}
                />
            )}

            <Tabs selectedTabIndex={selectedTab}>
                {/* News Feed Tab - article list, filters etc. */}
                <div className="page-content">
                    <div className="tab-news-feed">
                        <div className="top-container w-100">
                            <div className="header-row">
                                <div className="articles-info">
                                    {articles && pageData.total > 0 && (
                                        <React.Fragment>
                                            <span className="heading-14">
                                                {t('lbl_show_article', {
                                                    count: pageData.total,
                                                })}
                                            </span>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="sort-by">
                                    <span className="article-sort-list-title secondary-text">
                                        {t('lbl_sort_by')}:
                                    </span>
                                    <span>
                                        <DropdownList
                                            cls="article-sort-list"
                                            items={sortOption}
                                            onSelect={(sortId) => {
                                                const {
                                                    type,
                                                    sortCol,
                                                    id: optionId,
                                                } = sortOption.find(
                                                    (item) => item.id === sortId
                                                );
                                                // TODO: find more elegant way to append this type
                                                if (
                                                    optionId !== 'sort_new_old'
                                                ) {
                                                    setSort({
                                                        [sortCol]: type,
                                                        'article.date_published':
                                                            'DESC',
                                                    });
                                                } else {
                                                    setSort({
                                                        [sortCol]: type,
                                                    });
                                                }
                                            }}
                                            initialOption={sortOption.find(
                                                (item) =>
                                                    item.id === sortOption[0].id
                                            )}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="page-body">
                            <div className="page-table-row">
                                <FiltersContainer>
                                    <div className="filters-header">
                                        <h4>{t('lbl_filter', { count: 2 })}</h4>
                                        <Button
                                            cls="reset"
                                            isPrimary={false}
                                            label={t('lbl_clear_all_filters', {
                                                defaultValue:
                                                    'Clear all filters',
                                            })}
                                            onClick={() => resetAllFilters()}
                                        />
                                    </div>
                                    <FilterRiskLevel
                                        isResetStateChecked
                                        alignment="horizontal"
                                        title={t('lbl_risk_level')}
                                        items={filterRiskLevel}
                                        onChange={(filters) => {
                                            sendFilterTagEvent();
                                            setFilterRiskLevel(filters);
                                        }}
                                        onClick={(filters) => {
                                            sendFilterTagEvent();
                                            setFilterRiskLevel(filters);
                                        }}
                                        lastOneStanding
                                        lblReset={t('lbl_reset')}
                                        cls="risk-level-filter"
                                    />

                                    <div className="date-picker-filter">
                                        <FilterTitleAndTooltip
                                            id="entity-details-date-range-filter"
                                            cls={
                                                !dispDates.start &&
                                                !dispDates.end
                                                    ? 'disabled'
                                                    : ''
                                            }
                                            title={t('lbl_date_range')}
                                            lblReset={t('lbl_reset')}
                                            onClick={() =>
                                                onDateChange(
                                                    maxDateRange?.startDate,
                                                    maxDateRange?.endDate
                                                )
                                            }
                                        />
                                        <div
                                            className={classNames(
                                                'dates-button',
                                                {
                                                    default: !(
                                                        dispDates.start !==
                                                            null &&
                                                        dispDates.end !== null
                                                    ),
                                                }
                                            )}
                                            role="button"
                                            tabIndex="0"
                                            onClick={openDatePickerPanel}
                                            onKeyPress={openDatePickerPanel}
                                        >
                                            <SvgLib type="calendar" />
                                            <div>{dispDates.start}</div>
                                            <div>-</div>
                                            <div>{dispDates.end}</div>
                                        </div>
                                    </div>
                                    <div className="filter-keyword-search">
                                        <FilterKeywordSearch
                                            placeholder={t('lbl_enter_keyword')}
                                            keywords={filterKeywordSearch}
                                            title={t('lbl_keyword_search')}
                                            labelTexts={{
                                                keywordsExceeded:
                                                    t('lbl_max_len_error'),
                                            }}
                                            lblCount={`${
                                                filterKeywordSearch.length
                                            } ${t('lbl_selected')}`}
                                            onChange={(newData) => {
                                                sendFilterTagEvent();
                                                setFilterKeywordSearch(() => [
                                                    ...newData,
                                                ]);
                                            }}
                                            lblReset={t('lbl_reset')}
                                        />
                                    </div>
                                    <FilterRiskLevel
                                        isResetStateChecked
                                        alignment="vertical"
                                        title={t('lbl_risk_category')}
                                        items={filterRiskCategory}
                                        onChange={(cat) => {
                                            sendFilterTagEvent();
                                            setFilterRiskCategory(cat);
                                        }}
                                        lastOneStanding
                                        lblReset={t('lbl_reset')}
                                        cls="risk-category-filter"
                                        seeAllOptions={{
                                            enabled: true,
                                            minShown: 8,
                                        }}
                                        enableOnly={true}
                                    />
                                </FiltersContainer>

                                <div className="articles-container">
                                    {hasRendered.current &&
                                    articles.length === 0 &&
                                    !loading ? (
                                        <div className="details-skeleton-screen">
                                            <div className="cont">
                                                <p className="bold heading-4">
                                                    {t('lbl_no_item_found', {
                                                        item: t(
                                                            'lbl_article_plural'
                                                        ),
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <PaginatedList
                                                cls="article-row-table"
                                                Row={ArticleRow}
                                                rowData={[
                                                    ...getArticlesWithProps({
                                                        activeFolder,
                                                        articles,
                                                        folders,
                                                        handleManageFolder:
                                                            () =>
                                                                handleManageFolder(
                                                                    addPanel,
                                                                    setFolders,
                                                                    handleActiveFolderChange
                                                                ),
                                                        t,
                                                    }),
                                                ]}
                                                onAction={async (
                                                    action,
                                                    params
                                                ) => {
                                                    switch (action) {
                                                        case 'select':
                                                            const entityId =
                                                                getEntityIdFromPageUrl();
                                                            // GTM
                                                            window.Analytics?.sendEvent?.(
                                                                'open_article',
                                                                {
                                                                    entityId,
                                                                    articleId:
                                                                        params.articleId,
                                                                }
                                                            );
                                                            openArticleView(
                                                                params.articleId
                                                            );
                                                            break;
                                                        case 'save':
                                                            await moveArticleToFolder(
                                                                params,
                                                                setArticles,
                                                                updateArticleToFolder
                                                            );
                                                            break;
                                                        case 'remove':
                                                            await markArticleNotRelevant(
                                                                params,
                                                                setArticles,
                                                                moveArticleToNotRelavant
                                                            );
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                loading={loading}
                                                pageData={pageData}
                                                setPageData={setPageData}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <RelGraphLayout
                        onSeeMore={PoleAction.onSeeMore}
                        onSliderRangeChangeCb={(min, max) =>
                            PoleAction.rangeSliderChange(min, max)
                        }
                        action={relgraphAPI}
                        poleEntityData={{
                            ...entityDetails,
                            type: entityDetails?.isCompany
                                ? 'company'
                                : 'person',
                        }}
                        labelTexts={RelGraphLabelTexts(t, entityDetails?.name)}
                    />
                </div>
                {/* Sentiment analysis tab - contains the graphs */}
                <div className="tab-charts">
                    <TabSentimentAnalysis />
                </div>
            </Tabs>
        </div>
    );
};

export default PageDetails;
