import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import TextInput from '@bit/modus-moodys.mapulseui.text-input';
import Button from '@bit/modus-moodys.mapulseui.button';
import EntitySearchResultRow from '@bit/modus-moodys.mapulseui.entity-search-result-row';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../store';
import { requests } from '../../requests';

const SearchByName = ({ item, addPanel }) => {
    const { page, pages } = item?.props?.entitiesResults || {};
    const { t } = useTranslation();
    const { stByNameEntities, actSetByNameEntities } = useAppContext();
    const [stFilterApplied, actSetFilterApplied] = useState('');
    const { stSearchByNameError, actSetSearchByNameError } = useAppContext();
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({
        page,
        pages,
        disabled: false,
    });

    const { searchName } = item.props;
    const ref = useRef();

    const fetch = async (newPage) => {
        setLoading(true);
        const location = stFilterApplied;
        const newEntities = await requests.searchByName({
            searchName: encodeURIComponent(searchName),
            page: newPage,
            location: encodeURIComponent(location),
        });
        actSetByNameEntities([...newEntities.data]);
        const currentPage = newEntities.page;
        const totalPages = newEntities.pages;
        setPageData({ page: currentPage, pages: totalPages, disabled: false });
        setLoading(false);
    };

    const openConfirmation = (e, entity) => {
        addPanel('OnBoardConfirm', entity);
    };

    const filterAction = async (keyword) => {
        setLoading(true);
        actSetFilterApplied(`${keyword}`);
        const filteredEntities = await requests
            .searchByName({
                searchName: encodeURIComponent(searchName),
                location: encodeURIComponent(keyword),
            })
            .catch((err) => {
                actSetSearchByNameError(err);
                actSetByNameEntities({});
            });
        if (filteredEntities && filteredEntities.data) {
            actSetSearchByNameError(null);
            actSetByNameEntities([...filteredEntities.data]);
            const currentPage = filteredEntities.page;
            const totalPages = filteredEntities.pages;
            setPageData({
                page: currentPage,
                pages: totalPages,
                disabled: false,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        const rowData = item.props.entitiesResults.data;
        actSetByNameEntities(rowData);
    }, []);

    useEffect(() => {
        fetch();
    }, [pageData.page]);

    return (
        <div className="OnBoardSearchByName">
            <div className="onbaord-actions">
                <TextInput
                    placeholder="Keywords (City or Country)"
                    isLabelOnTop={false}
                    submit={() => filterAction(ref?.current?.value)}
                    ref={ref}
                />
                <Button
                    label={t('lbl_apply', { defaultValue: 'Apply' })}
                    onClick={() => filterAction(ref?.current?.value)}
                />
            </div>

            <hr style={{ marginBottom: '10px' }} />
            {stSearchByNameError && (
                <p className="error-message">{stSearchByNameError.message}</p>
            )}
            <PaginatedList
                cls="no-lr-border"
                tag=""
                rowData={stByNameEntities}
                Row={EntitySearchResultRow}
                onAction={openConfirmation}
                setPageData={setPageData}
                loading={loading}
                pageData={pageData}
            />
        </div>
    );
};

SearchByName.defaultProps = {
    addPanel: PropTypes.func,
    item: {},
};

SearchByName.propTypes = {
    addPanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};

export default SearchByName;
