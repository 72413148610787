import moment from 'moment';

export const DEFAULT_INCLUDES = [
    'cam_article.article_id',
    'article.url',
    'article.title',
    'article.date_published',
    'cam_sentiment.label',
    'article.publication',
    'article.source',
    'article.lang',
    'user_article.date_read',
    'user_article.snippets',
    'cam_article.json_category',
    'article.summary',
];
export const DEFAULT_SORT = { 'article.date_published': 'DESC' };
// NB: The first one is used as the initial value for the sort-by dropdown.
export const DEFAULT_SORT_OPTIONS = (t) => [
    {
        label: t('lbl_sort_new_old'),
        id: 'sort_new_old',
        type: 'DESC',
        sortCol: 'article.date_published',
    },
    {
        label: t('lbl_risk_high_low'),
        id: 'sort_high_low',
        type: 'DESC',
        sortCol: 'cam_article.sentiment_id',
    },
    {
        label: t('lbl_risk_low_high'),
        id: 'sort_low_high',
        type: 'ASC',
        sortCol: 'cam_article.sentiment_id',
    },
];
export const getNotRelevantOptions = (t) => [
    {
        label: t('lbl_article_refer_incorrect_entity'),
        name: 'incorrect-entity',
        id: 'incorrect-entity',
    },
    {
        label: t('lbl_topic_not_relevant'),
        name: 'irrelavant-article-topic',
        id: 'irrelavant-article-topic',
    },
    {
        label: t('lbl_inaccurate_info'),
        name: 'inaccurate-information',
        id: 'inaccurate-information',
    },
    {
        label: t('lbl_incorrect_risk_category'),
        name: 'incorrect-risk-category',
        id: 'incorrect-risk-category',
    },
    {
        label: t('lbl_sentiment_incorrect'),
        name: 'incorrect-sentiment',
        id: 'incorrect-sentiment',
    },
    {
        label: t('lbl_other'),
        name: 'other',
        id: 'other',
    },
];
export const DEFAULT_DATE_RANGE = {
    startDate: '',
    endDate: '',
};

export const RiskSentimentLabelMapping = {
    high: 'high',
    medium: 'med',
    low: 'low',
};

export const RiskLevelOrder = ['high', 'medium', 'med', 'low'];

/**
 *
 * @param {string} endDate
 * @param {number} diffAmount
 * @param {string} diffUnit
 * @param {string} format
 * @returns {{startDate:string, endDate: string}} spannedDates
 */
export const getMonthDifferenceDates = (
    endDate,
    diffAmount = 0,
    diffUnit = 'day',
    format
) => {
    const mEndDate = moment.utc(endDate, format);
    const mStartDate = mEndDate.clone().subtract(diffAmount, diffUnit);
    return {
        startDate: mStartDate.format(format),
        endDate: mEndDate.format(format),
    };
};

export const DEFAULT_PAGE_DATA = { page: 1, pages: 1, disabled: false };
export const DEFAULT_DEBOUNCE_TIMER = 50;
export const DEFAULT_PAGE_SIZE = 30;

export const RelgraphPoleLabelGenerator = (t) => ({
    Person: t('lbl_person', {
        defaultValue: 'Person',
    }),
    Organization: t('lbl_organization', {
        defaultValue: 'Organization',
    }),
    Location: t('lbl_location', {
        defaultValue: 'Location',
    }),
    Event: t('lbl_event', {
        defaultValue: 'Event',
    }),
});

export const PoleToNameMap = {
    PER: 'Person',
    ORG: 'Organization',
    LOC: 'Location',
    EVE: 'Event',
};
export const PoleTypeMap = {
    PER: 'person',
    ORG: 'company',
    LOC: 'location',
    EVE: 'event',
};
export const PoleDataSort = ['PER', 'ORG', 'LOC', 'EVE'];

export const RelGraphLabelTexts = (t, name, isEvent) => ({
    seeMore: t('lbl_see_more'),
    somethingWentWrong: t(
        'lbl_oops_something_went_wrong_please_try_again_later',
        {
            defaultValue: 'Oops! Something went wrong. Please try again later',
        }
    ),
    reload: t('reload', { defaultValue: 'Reload' }),
    fetchingRelatedEntitiesFor: name
        ? t('lbl_fetching_related_entities_for', {
              defaultValue: `Fetching related entities for <b>${name}</b>`,
              entityName: `<b>${name}</b>`,
              interpolation: { escapeValue: false },
          })
        : '',
    weDoNotHaveAnyDataFor: !isEvent
        ? t('lbl_we_do_not_have_any_for', {
              defaultValue: `We do not have any data for <b>${name}</b> at this time.`,
              entityName: `<b>${name}</b>`,
              interpolation: { escapeValue: false },
          })
        : t('lbl_no_related_entities_event', {
              defaultValue: `There are no related entities for this event.`,
          }),
    filterEntityByRelevancy: t('filter_entity_by_relevancy', {
        defaultValue: 'Filter Entities by Relevancy',
    }),
});
