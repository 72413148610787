import React, { useEffect, useState } from 'react';
import ProgressStatusRow from '@bit/modus-moodys.mapulseui.progress-status-row';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import classNames from 'classnames';
import { requests } from '../../../requests';
import { defaultStages } from './defaults';
import './styles.scss';
import logger from '@bit/modus-moodys.mapulseui.logger';

const POOLING_INTERVAL = 5000;
const pageSize = 25;
let intervalId = null;
// const DEFAULT_ITEMS = [
//     {
//         name: 'DIRECTORS AND EMPLOYEES of WEST COAST NOVELTY CORP',
//         uuid: 'c5a38a49b4b7eeff',
//         entityId: 239115514,
//         error: { message: 'sas' },
//         orbisId: 'ZZBVDM*18929',
//         fetchStatus: 0,
//         phase: 'queued',
//         isFinished: false,
//     },
// ];
const InProgress = () => {
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({
        page: 1,
        pages: 1,
        disabled: false,
    });
    const getEntities = async (withLoader = false) => {
        if (withLoader) {
            setLoading(true);
            // const _rr = await new Promise((r) => {
            //     setTimeout(r, [1000]);
            // });
        }
        try {
            const resp = await requests.status({
                size: pageSize,
                page: pageData.page,
            });
            const { entities: tEntities = [], total = 0 } = resp.data || {};
            if (tEntities.length) {
                setPageData({
                    ...pageData,
                    pages: Math.ceil(total / pageSize) || 1,
                    disabled: false,
                });
                setEntities([...tEntities]);
            } else {
                if (pageData.page === 1 && intervalId) {
                    clearInterval(intervalId);
                } else {
                    setPageData({
                        ...pageData,
                        pages: Math.ceil(total / pageSize) || 1,
                        disabled: false,
                        page: 1,
                    });
                }
                setEntities([]);
            }
        } catch (err) {
            logger.log(err);
        } finally {
            if (withLoader) setLoading(false);
        }
    };
    const startPooling = () => {
        // getEntities();
        intervalId = setInterval(getEntities, POOLING_INTERVAL);
    };
    useEffect(() => {
        getEntities(true);
        if (intervalId) clearInterval(intervalId);
        startPooling();
    }, [pageData.page]);
    useEffect(() => {
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, []);
    const onAction = async (action, payload) => {
        switch (action) {
            case 'retry': {
                try {
                    await requests.retryEntity({ entityId: payload.entityId });
                    setEntities((e) =>
                        e.filter((i) => i.entityId === payload.entityId)
                    );
                    getEntities();
                } catch (err) {
                    logger.log(err);
                }
                logger.log('retried', payload);
                break;
            }
            default:
                break;
        }
    };
    return (
        <div
            className={classNames('tab-in-progress', {
                noData: entities.length === 0,
            })}
        >
            {entities.length ? (
                <div className="in-porgress-headers">
                    <div style={{ flexBasis: '35%' }} />
                    <div className="in-porgress-headers-wrapper">
                        {defaultStages.map((stage) => {
                            return (
                                <span key={stage} className="font-input">
                                    {stage}
                                </span>
                            );
                        })}
                    </div>
                    <div style={{ flexBasis: '10%' }} />
                </div>
            ) : null}
            <PaginatedList
                cls=""
                id="folder-row-table"
                tag="folder-row-table"
                Row={ProgressStatusRow}
                rowData={entities.map((ent) => ({
                    ...ent,
                    id: `${ent.entityId}`,
                    isCompany: !!ent.isCompany,
                    // error : {message : 'some values'}
                }))}
                onAction={onAction}
                pageData={pageData}
                setPageData={setPageData}
                loading={loading}
            />
        </div>
    );
};

export default InProgress;
