module.exports = {
    SRC_PATH: './src',
    PUBLIC_PATH: './public',
    APP_NAME: 'government',
    REACT_APP_NODE_ENV: 'local',
    PUBLIC_APP_URL: 'http://localhost:8000',
    GMAP_KEY: 'AIzaSyBRJzOZCxRVZ6YCKOR1SRYYXOeGu8f6lpE',
    axios: {
        CLIENT_APP_NAME: 'government',
        errorPage: '/error',
        loginPage: '/login',
        services: {
            article: '/service-article',
            auth: '/service-auth',
            cam: '/service-cam',
            dashboard: '/service-dashboard',
            diagnostic: '/service-diagnostic',
            entity: '/service-entity',
            event: '/service-event',
            onboard: '/service-onboard',
            portfolio: '/service-portfolio',
            rdc: '/service-rdc',
            statistic: '/service-statistic',
            support: '/service-support',
            typeahead: '/service-typeahead',
        },
    },
};
