import DynamicTabHeaders from '@bit/modus-moodys.mapulseui.dynamic-tab-headers';
import TwoColTitle from '@bit/modus-moodys.mapulseui.two-col-title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageEvent from '..';
import './styles.scss';

export const ActiveTabIndexEnum = {
    ALL_EVENTS: 'ALL_EVENTS',
    MY_SAVED_EVENTS: 'MY_SAVED_EVENTS',
};
const PageEventWrapper = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(
        ActiveTabIndexEnum.ALL_EVENTS
    );
    const { t } = useTranslation();
    const tabHeadersConfig = [
        {
            id: 'all-events',
            label: `${t('lbl_all_events', { defaultValue: 'All Events' })}`,
            active: activeTabIndex === ActiveTabIndexEnum.ALL_EVENTS,
            onClick: () => {
                setActiveTabIndex(ActiveTabIndexEnum.ALL_EVENTS);
            },
        },
        {
            id: 'my-saved-events',
            label: `${t('lbl_my_saved_events', {
                defaultValue: 'My Saved Events',
            })}`,
            active: activeTabIndex === ActiveTabIndexEnum.MY_SAVED_EVENTS,
            onClick: () => {
                setActiveTabIndex(ActiveTabIndexEnum.MY_SAVED_EVENTS);
            },
        },
    ];

    // eslint-disable-next-line no-unused-vars
    const [isPageLoading, setIsPageLoading] = useState(true);

    return (
        <div className="page-event-wrapper">
            <TwoColTitle
                leftComponent={
                    <div className="title-ribbon">
                        <span className="title-header">
                            {t('lbl_event_plural', {
                                defaultValue: 'Events',
                            })}
                        </span>
                        <DynamicTabHeaders items={tabHeadersConfig} />
                    </div>
                }
            />
            {activeTabIndex === ActiveTabIndexEnum.ALL_EVENTS && (
                <PageEvent
                    activeTab={activeTabIndex}
                    changeActiveTab={setActiveTabIndex}
                />
            )}
            {activeTabIndex === ActiveTabIndexEnum.MY_SAVED_EVENTS && (
                <PageEvent
                    activeTab={activeTabIndex}
                    changeActiveTab={setActiveTabIndex}
                />
            )}
        </div>
    );
};

export default PageEventWrapper;
