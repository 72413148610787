/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import TrendingEventsList from '@bit/modus-moodys.mapulseui.trending-events-list';
// import TrendingLabelsBarChart from '@bit/modus-moodys.mapulseui.trending-labels-bar-chart';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@store';
import Shortcuts from './Shortcuts';
import TileView from './TileView';
import Trending from './Trending';

const PageDashboard = () => {
    const { t } = useTranslation();
    const { enabledTestingMethods } = useAppContext();

    return (
        <div className="page page-dashboard">
            <div className="page-content">
                <Trending></Trending>
                <Shortcuts></Shortcuts>
                <TileView />
            </div>
        </div>
    );
};

export default PageDashboard;
