import { get, set } from 'lodash';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '@store';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import { GaEventsMap } from '@pages/PageEvent/ga-events';

export const Context = React.createContext();
export const useEventsFilter = () => useContext(Context);

const riskLevels = ['category', 'theme', 'region', 'country'];
const REMOVE_FILTER = 'regionSelectedIds';

const transformRiskLevels = (eventResponse = {}) => {
    for (const filterKey in eventResponse) {
        if (!riskLevels.includes(filterKey)) continue;
        const isRegion = filterKey === 'region' || filterKey === 'country';
        const filters = eventResponse[filterKey];
        eventResponse[filterKey] = filters?.map((filter, id) => ({
            ...(isRegion && {
                type: filterKey,
            }),
            id: `${filterKey}_${id}`,
            label: `${filter?.label} (${filter?.count})`,
            checked: true,
            queryKey: filter?.label,
        }));
    }
};

const transformDateRange = (eventResponse) => {
    const { date_range } = eventResponse;
    const startDate = DateViewer.getDateText(date_range?.date_start);
    const endDate = DateViewer.getDateText(date_range?.date_end);
    eventResponse.date_range = {
        startDate,
        endDate,
    };
};

export const eventFilterTransformer = (eventResponse = {}) => {
    transformRiskLevels(eventResponse);
    transformDateRange(eventResponse);
    return { ...eventResponse };
};

export const dateRangeTransformer = (dateRange = {}) => {
    if (!Object.keys(dateRange)) return;
    const startDate = DateViewer.getDateText(dateRange?.date_start);
    const endDate = DateViewer.getDateText(dateRange?.date_end);
    return {
        startDate,
        endDate,
    };
};

const parseFilterForRequest = (eventStateFilters = {}) => {
    const filter = { ...eventStateFilters?.filter };
    for (const filterKey in filter) {
        if (filterKey === REMOVE_FILTER) {
            delete filter[filterKey];
        }
        if (!riskLevels.includes(filterKey)) continue;
        if (!Array.isArray(filter[filterKey])) continue;
        const isRegion = filterKey === 'region' || filterKey === 'country';

        const toQueryFilters = filter[filterKey]
            ?.filter((i) => i.checked)
            ?.map((i) => i.queryKey);
        const isAllSelected =
            toQueryFilters?.length === filter[filterKey].length && !isRegion;

        filter[filterKey] = isAllSelected ? [] : toQueryFilters;
    }
    if (filter['date_range']?.startDate && filter['date_range']?.endDate) {
        const { startDate, endDate } = filter['date_range'];
        filter['date_range'] = {
            date_start: DateViewer.getDateMomentObj(startDate)
                .startOf('day')
                .toISOString(),
            date_end: DateViewer.getDateMomentObj(endDate)
                .endOf('day')
                .toISOString(),
        };
    }
    return filter;
};

export const EventsFilterProvider = ({ children }) => {
    const { stDefaultEventFilters, actSetDefaultEventFilters } =
        useAppContext();

    const eventParsedFilter = React.useRef(stDefaultEventFilters);

    const filterUpdateListeners = React.useRef([]);
    const addFilterUpdateListener = (listener) => {
        if (listener.name) {
            // Check existing listener
            const existingListenerIdx = filterUpdateListeners.current.findIndex(
                (i) => i.name === listener.name
            );
            if (existingListenerIdx !== -1) {
                filterUpdateListeners.current[existingListenerIdx] = listener;
                return;
            }
        }
        filterUpdateListeners.current.push(listener);
    };

    useEffect(() => {
        const eventFilter = parseFilterForRequest({
            ...stDefaultEventFilters,
        });
        eventParsedFilter.current = eventFilter;
        GaEventsMap.filterEngagement({
            filters: eventFilter,
        });
    }, [stDefaultEventFilters]);

    const updateEventFilterByKey = (filterType, value) => {
        actSetDefaultEventFilters((eventFilter) => {
            const newFilters = set({ ...eventFilter }, filterType, value);
            for (const listener of filterUpdateListeners.current) {
                listener(parseFilterForRequest({ ...newFilters }));
            }
            return newFilters;
        });
    };

    const updateEventFilterByKeyGenerator = (filterKey) => (value) => {
        updateEventFilterByKey(filterKey, value);
    };

    const getEventFilterByKey = (key) => {
        return get(stDefaultEventFilters, key);
    };

    const [eventSort, setEventSort] = React.useState({ date: 'DESC' });
    const [maxDateRange, setMaxDateRange] = React.useState({});

    const contextValues = {
        get eventFilterParsed() {
            if (Object.keys(eventParsedFilter.current).length === 0) {
                return stDefaultEventFilters;
            }
            return eventParsedFilter.current;
        },
        eventFilter: stDefaultEventFilters,
        setEventFilter: actSetDefaultEventFilters,
        getEventFilterByKey,
        updateEventFilterByKey,
        updateEventFilterByKeyGenerator,
        addFilterUpdateListener,
        eventSort,
        setEventSort,
        maxDateRange,
        setMaxDateRange,
    };

    return (
        <Context.Provider value={contextValues}>{children}</Context.Provider>
    );
};

EventsFilterProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
