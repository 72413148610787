import React from 'react';
import { Translation } from 'react-i18next';
import CheckIcon from './Check_icn.svg';
import './style.scss';

export default () => {
    return (
        <Translation>
            {(t) => (
                <div className="thankyoupane">
                    <img src={CheckIcon} />
                    <span className="title">{t('lbl_thanks_feedback')}</span>
                    <span className="subtitle">{t('lbl_thanks_sub')}</span>
                </div>
            )}
        </Translation>
    );
};
