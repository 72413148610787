import { useRef } from 'react';
import { RISK_SCOPES } from '../Helpers/constants';

export const DEFAULT_RANGE = 15;

export const useTileState = (tileData = {}, onStateUpdateCb) => {
    const componentState = useRef({
        selectedInterval: tileData?.selectedInterval ?? DEFAULT_RANGE,
        currentScope: tileData?.currentScope ?? RISK_SCOPES.ALL,
        userWatchlistId: tileData?.userWatchlistId,
        locations: tileData?.locations,
        // Hold back label and id to handle backward compatibility
        label: tileData?.label,
        id: tileData?.id,
    });

    /*
        Reasons for doing custom getter setter: 
        1. Due to how component is desinged, we need to keep track 
           of the state of the component using a ref.

        2. Set state works, but component does not wait for the state
           to be updated, it calls the function handleAsyncAction() 
           immediately after onUpdateMeta() is called, causing the
           component to render with the old state.
    */
    const tileState = () => componentState.current;

    const setTileState = (newState = {}) => {
        componentState.current = {
            ...tileState(),
            ...newState,
        };
        if (onStateUpdateCb && typeof onStateUpdateCb === 'function') {
            onStateUpdateCb({ ...tileState() });
        }
    };

    return {
        tileState,
        setTileState,
    };
};
