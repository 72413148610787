const getQueryParams = (loc) => {
    const cache = {};
    const { search } = loc;
    if (search.length > 0) {
        const params = search.split('?')[1].split('&');
        if (params.length > 0) {
            params.forEach((param) => {
                const [key, value] = param.split('=');
                cache[key] = value;
            });
        }
    }
    return cache;
};

export default getQueryParams;
